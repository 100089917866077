<div class="table-wrapper">
    <div class="main-header" *ngIf="originalTableData.length">
        <div class="delivery-logs-header-values">
            <div class="logs-header-item">
                <div class="label">Sequence Name</div>
                <div class="value">{{originalTableData[0].seqName}}</div>
            </div>

            <div class="logs-header-item">
                <div class="label">Time</div>
                <div class="value">{{currentDate | date:'MMMM d, y | h:mm:ss a'}}</div>
            </div>

            <div class="logs-header-item">
                <div class="label">Delivering Status</div>
                <div class="value">{{'Delivering (' + deliveryCount + '/' + originalTableData.length + ')'}}</div>
            </div>
        </div>

        <div class="header-icons-container">
            <div class="close-button-container" *ngIf="showCloseButton">
                <button mat-icon-button mat-dialog-close class="close-button">
                    <i class="fas fa-times"></i>
                </button>
            </div>
        </div>

    </div>

    <div class="table-container">
        <table mat-table [dataSource]="dataSource" [fixedLayout]="true" matSort style="width: 100%; min-height: 100%">
            <ng-container *ngFor="let column of headerColumns" [matColumnDef]="column" sticky>
                <th mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="column === 'status' ? 'width-10' : (column === 'ccuName' ? 'width-40' : 'width-25')">{{ getFormattedColumnName(column) }}</th>
                <td mat-cell *matCellDef="let row" [ngClass]="column === 'status' ? 'width-10' : (column === 'ccuName' ? 'width-40' : 'width-25')">
                    <ng-container [ngSwitch]="column">
                        <ng-container *ngSwitchCase="'status'">
                            <p class="data-value icon-cell">
                                <i class="fas fa-exclamation log-level-indicator error" *ngIf="row.status === 'published' || (row.status === 'delivered' && !row.ackedDateTime)"
                                    [matTooltip]="row.status" matTooltipPosition='above' showTooltipOnOverflow></i>

                                <i class="fas fa-check log-level-indicator success" *ngIf="row.status === 'acked'"
                                    [matTooltip]="row.status" matTooltipPosition='above' showTooltipOnOverflow></i>
                            </p>
                        </ng-container>

                        <ng-container *ngSwitchCase="'ccuName'">
                            <p class="data-value" matTooltip="" matTooltipPosition='above' showTooltipOnOverflow>
                                {{ row.ccuName }}
                            </p>
                        </ng-container>

                        <ng-container *ngSwitchCase="'deliveredDateTime'">
                            <p class="data-value" matTooltip="" matTooltipPosition='above' showTooltipOnOverflow>
                                {{ row.deliveredDateTime ? (row.deliveredDateTime | date:'MMMM d, y | hh:mm a') : '-' }}
                            </p>
                        </ng-container>

                        <ng-container *ngSwitchCase="'ackedDateTime'">
                            <p class="data-value" matTooltip="" matTooltipPosition='above' showTooltipOnOverflow>
                                {{ row.ackedDateTime ? (row.ackedDateTime | date:'MMMM d, y | hh:mm a') : '-' }}
                            </p>
                        </ng-container>

                        <ng-container *ngSwitchDefault>
                            {{ row[column] }}
                        </ng-container>

                    </ng-container>

                    <!-- <p class="data-value icon-cell" *ngIf="column === 'status'">
                        <i class="fas fa-info log-level-indicator" [ngClass]="{
                              'error' : row.status === 'published', 
                              'success': (row.status === 'delivered' || row.status === 'acked')
                            }" matTooltip="" matTooltipPosition='above' showTooltipOnOverflow></i>
                    </p>

                    <p class="data-value" [ngClass]="{'message': column === 'status'}"
                        *ngIf="(column !== 'status' && column !== 'deliveredDateTime' && column !== 'ackedDateTime')"
                        matTooltip="" matTooltipPosition='above' showTooltipOnOverflow>
                        {{ row[column] }}
                    </p>

                    <p class="data-value" *ngIf="column === 'deliveredDateTime' || column === 'ackedDateTime'"
                        matTooltip="" matTooltipPosition='above' showTooltipOnOverflow>
                        {{ row[column] | date: 'MMMM d, y, h:mm a, zzzz' }}
                    </p> -->

                </td>
            </ng-container>

            <!-- Header and Row definitions -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <div class="placeholder-text" *ngIf="originalTableData.length <= 0">
            <div class="message-text">No logs found for the sequence</div>
        </div>
    </div>
</div>