
import { BlockLogo } from 'src/app/shared/enums/SequenceKeys.enum';
import { BlockColors } from '../blockly-theme';

export const SEQUENCE_TEMPLATES = [
  {
    "type": "template_num_to_num",
    "message0": "%1 mapping: numeric to numeric %2 numeric input %3 min input %4 max input %5 min output %6 max output %7",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "input_dummy"
      },
      {
        "type": "input_value",
        "name": "NUMERIC_INPUT",
        "check": "Number"
      },
      {
        "type": "input_value",
        "name": "MIN_INPUT",
        "check": "Number"
      },
      {
        "type": "input_value",
        "name": "MAX_INPUT",
        "check": "Number"
      },
      {
        "type": "input_value",
        "name": "MIN_OUTPUT",
        "check": "Number"
      },
      {
        "type": "input_value",
        "name": "MAX_OUTPUT",
        "check": "Number"
      }
    ],
    "output": null,
    "colour": BlockColors.TEMPLATE_BLOCKS,
    "tooltip": "Scale an numeric value to another numeric value within a range",
    "helpUrl": "Documenation URL",
  },
  {
    "type": "template_num_to_bool",
    "message0": "%1 mapping: numeric to boolean %2 numeric input %3 threshold value %4 boolean value 1 %5 boolean value 2 %6",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "input_dummy"
      },
      {
        "type": "input_value",
        "name": "NUMERIC_INPUT",
        "check": "Number"
      },
      {
        "type": "input_value",
        "name": "THRESHOLD_VALUE",
        "check": "Number"
      },
      {
        "type": "input_value",
        "name": "BOOL_VALUE1",
        "check": "Boolean"
      },
      {
        "type": "input_value",
        "name": "BOOL_VALUE2",
        "check": "Boolean"
      }
    ],
    "output": null,
    "colour": BlockColors.TEMPLATE_BLOCKS,
    "tooltip": "Scale an numeric value to boolean value",
    "helpUrl": "Documenation URL",
  },
  {
    "type": "template_75f_10kThermistor_type2_to_temp",
    "message0": "%1 mapping: 75F 10K thermistor type 2 temperature (°F) output %2 numeric resistance input: %3",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "input_dummy"
      },
      {
        "type": "input_value",
        "name": "NUMERIC_INPUT",
        "check": "Number"
      }
    ],
    "output": null,
    "colour": BlockColors.TEMPLATE_BLOCKS,
    "tooltip": "Mapping 10K Thermistor Type-2 Resistance Value to Temperature",
    "helpUrl": "Documenation URL",
  },
  {
    "type": "template_10kThermistor_type2_to_temp",
    "message0": "%1 mapping: 10K thermistor type 2 temperature (°F) output %2 numeric resistance input: %3",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "input_dummy"
      },
      {
        "type": "input_value",
        "name": "NUMERIC_INPUT",
        "check": "Number"
      }
    ],
    "output": null,
    "colour": BlockColors.TEMPLATE_BLOCKS,
    "tooltip": "Mapping 10K Thermistor Type-2 Resistance Value to Temperature",
    "helpUrl": "Documenation URL",
  },
  {
    "type": "template_10kThermistor_type3_to_temp",
    "message0": "%1 mapping: 10K thermistor type 3 temperature (°F) output %2 numeric resistance input: %3",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "input_dummy"
      },
      {
        "type": "input_value",
        "name": "NUMERIC_INPUT",
        "check": "Number"
      }
    ],
    "output": null,
    "colour": BlockColors.TEMPLATE_BLOCKS,
    "tooltip": "Mapping 10K Thermistor Type-3 Resistance Value to Temperature",
    "helpUrl": "Documenation URL",
  },
  {
    "type": "template_1kThermistor_to_temp",
    "message0": "%1 mapping: 1K thermistor type 3 temperature (°F) output %2 numeric resistance input: %3",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "input_dummy"
      },
      {
        "type": "input_value",
        "name": "NUMERIC_INPUT",
        "check": "Number"
      }
    ],
    "output": null,
    "colour": BlockColors.TEMPLATE_BLOCKS,
    "tooltip": "Mapping 1K Thermistor Type2 Resistance Value to Temperature",
    "helpUrl": "Documenation URL",
  }
];
