import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmModalComponent>
  ) { }
  htmlContent: any;
  title: any;
  subTitle: any;
  type: 'sequence_logs' | 'delivery_logs' | 'json_viewer' = 'sequence_logs';
  isFixedHeight!: boolean;
  filteredJsonContent: any;
  showHeader!: boolean;
  isSearchFieldRequired!: boolean;
  currentDate: Date = new Date();

  ngOnInit() {
 
    if(this.type == 'json_viewer' && this.htmlContent) {
      const stringJsonData = JSON.parse(this.htmlContent);
      let displayData =  stringJsonData;
     
      try {
        this.htmlContent = JSON.parse(displayData);
      }
      catch(e) {
        this.htmlContent = displayData;
      }
     
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onBtnAction(event :any) {
    this.dialogRef.close(event);
  }

  applyFilter(searchedText: any) {
    if (this.htmlContent && this.type === 'json_viewer') {
      const jsonData = this.htmlContent;
      this.filteredJsonContent = this.filterJsonData(jsonData, searchedText);
    }
  }

  filterJsonData(jsonData: any, query: string): any {
    if (!query.trim()) {
      return jsonData;
    }
    const filteredData = jsonData.filter((item: any) => {
      for (const key of Object.keys(item)) {
        if (item[key].toLowerCase().includes(query.toLowerCase())) {
          return true; 
        }
      }
      return false;
    });
    return filteredData;
  }

}
