import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-site-sequencer-table',
  templateUrl: './site-sequencer-table.component.html',
  styleUrls: ['./site-sequencer-table.component.scss']
})
export class SiteSequencerTableComponent implements OnChanges {
  @Input() selectedSequence: any;
  @Input() table: any;
  @Input() headerColumns: any;
  @Input() isLoading: any;
  @Input() showCloseButton!: boolean;
  @Input() searchedSequenceLogsValue!: any;
  @Input() hideTableHeader: boolean = true;
  searchText: any;
  subscriptions: any = {};
  searchTextChanged = new Subject<string>();
  originalTableData: any[] = [];
  dataSource!: MatTableDataSource<any>;
  displayedColumns!: string[];

  @ViewChild(MatSort, { static: true }) sort!: MatSort;

  constructor(private dom: DomSanitizer, private dialog: MatDialog) {
    this.dataSource = new MatTableDataSource();
    this.displayedColumns = [];
  }

  ngOnInit() {
    this.subscriptions['searchTextChanged'] = this.searchTextChanged.pipe(
      debounceTime(300),
    ).subscribe((search) => {
      this.searchText = search;
      this.filterTableData();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    const table = changes['table']?.currentValue;
    if (table && Object.keys(table)?.length) {
      this.table = this.formatTableData(table);
      this.originalTableData = this.table ? [...this.table] : [];
      this.dataSource.data = this.table;
      this.dataSource.sort = this.sort;
    }

    if (changes['headerColumns']?.currentValue) {
      this.headerColumns = changes['headerColumns'].currentValue;
      this.displayedColumns = this.headerColumns;
    }

    if(changes['searchedSequenceLogsValue']) {
      if ((!changes['searchedSequenceLogsValue'].firstChange) && (changes['searchedSequenceLogsValue']?.currentValue === '' || changes['searchedSequenceLogsValue']?.currentValue)) {
        this.searchText = changes['searchedSequenceLogsValue'].currentValue;
        this.search(this.searchText);
      }
    }
  }

  formatTableData(tableData: any) {
    if (tableData) {
      tableData.forEach((dataObj: any) => {
        if (dataObj.type === 'DEBUG') {
          const resultObject = dataObj.result ? JSON.parse(dataObj.result) : '';
          if (resultObject && resultObject.hasOwnProperty('value')) {
            dataObj.result = resultObject.value !== 'string' ? JSON.stringify(resultObject.value) : resultObject.value;
            dataObj['isResultJson'] = this.checkForJsonType(dataObj.result);
          }
        } else {
          dataObj['isResultJson'] = this.checkForJsonType(dataObj.result);
        }
      });
      return tableData;
    }
  }

  checkForJsonType(data: any): boolean {
    let isResultJson = false;
    try {
      let formattedData = JSON.parse(data);

      try {
        let validateResponse = JSON.parse(formattedData);
        if (typeof validateResponse === 'object' && validateResponse !== null) {
          formattedData = validateResponse;
        }
      } catch (error) {
        formattedData = JSON.parse(data);
      }

      if (formattedData && typeof formattedData === 'object') {
        if (Array.isArray(formattedData)) {
          const staticDataTypes = ['string', 'number', 'boolean', 'undefined', 'null'];
          const isStaticDataType = staticDataTypes.indexOf(typeof formattedData[0]) >= 0;
          isResultJson = formattedData.length > 1 || !isStaticDataType;
        } else {
          isResultJson = true;
        }
      }
    } catch (error) {
      isResultJson = false;
    }
    return isResultJson;
  }

  search(searchText: any) {
    this.searchTextChanged.next(searchText);
  }

  filterTableData() {
    if (this.searchText.trim() !== '') {
      const searchTerm = this.searchText.toLowerCase();
      const filteredTable = this.originalTableData.filter((rowData: any) => {
        for (const value of Object.values(rowData)) {
          if (typeof value === 'string' || typeof value === 'number') {
            const formattedValue = String(value).toLowerCase();
            if (formattedValue.includes(searchTerm)) {
              return true;
            }
          }
        }
        return false;
      });
      this.dataSource.data = filteredTable;
    } else {
      this.dataSource.data = [...this.originalTableData];
    }
  }

  showResult(resultArray: any) {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      panelClass: ['fs-mat-dialog-container', 'full-height', 'logs-result-modal'],
      width: '772px',
      height: 'calc(100vh - 50px)',
      disableClose: true
    });
    dialogRef.componentInstance.htmlContent = resultArray;
    dialogRef.componentInstance.type = 'json_viewer';
    dialogRef.componentInstance.showHeader = true;
    dialogRef.componentInstance.title = 'Result';
    dialogRef.updatePosition({ top: '60px' });
    dialogRef.afterClosed().subscribe(result => { });
  }

}
