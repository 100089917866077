import { Component } from '@angular/core';

@Component({
  selector: 'app-error-type-legends',
  templateUrl: './error-type-legends.component.html',
  styleUrls: ['./error-type-legends.component.scss']
})
export class ErrorTypeLegendsComponent {

  constructor(){}

}
