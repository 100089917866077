import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ConfigurationService } from 'src/app/services/configuration.service';

@Injectable({
  providedIn: 'root'
})
export class SiteSequencerService {
  sequencersUrl!: string;
  notesUrl!: string;
  edgeRunnerApi!: string;
  constructor(private http: HttpClient,public configService: ConfigurationService) { 
    this.sequencersUrl = this.configService.getConfig('siteSequencerUrl');
    this.notesUrl = this.configService.getConfig('notesUrl');
    this.edgeRunnerApi =this.configService.getConfig('edgeRunnerApi')
  }

  getSequencesBySite(siteRef: string) {
    return this.http.get(this.sequencersUrl + `/seq-mgmt/findAll?siteRef=${siteRef}`).pipe(catchError(this.handleError));
  }

  saveOrUpdateSequence(sequence: any) {
    return this.http.post(this.sequencersUrl + `/seq-mgmt/save`,sequence).pipe(catchError(this.handleError));
  }

  deleteSequence(sequenceId: any) {
    return this.http.delete(this.sequencersUrl + `/seq-mgmt/remove?seqId=${sequenceId}`).pipe(catchError(this.handleError));
  }

  runSequence(sequenceId: any) {
    return this.http.post(this.sequencersUrl + `/seq/submit?seqId=${sequenceId}`,null).pipe(catchError(this.handleError));
  }

  getWorkspaceBySite(siteRef: string, workspaceId: string | undefined) {
    return this.http.get(this.sequencersUrl + `/seq-mgmt/workspace?siteRef=${siteRef}&workspaceId=${workspaceId}`).pipe(catchError(this.handleError));
  }

  getWorkspacesListForCategory(siteRef: string, category: string) {
    return this.http.get(this.sequencersUrl + `/seq-mgmt/workspaceList?siteRef=${siteRef}&category=${category}`).pipe(catchError(this.handleError));
  }

  saveWorkspace(workspaceXml: any) {
    return this.http.put(this.sequencersUrl + `/seq-mgmt/saveworkspace`, workspaceXml).pipe(catchError(this.handleError));
  }

  deleteWorkspace(siteRef: string, workspaceId: string) {
    return this.http.delete(this.sequencersUrl + `/seq-mgmt/removeWorkspace?siteRef=${siteRef}&workspaceId=${workspaceId}`).pipe(catchError(this.handleError));
  }

  getAllSequencesPerWorkspace(siteRef: string, workspaceId: string) {
    return this.http.get(this.sequencersUrl + `/seq-mgmt/findAllPerWorkspace?siteRef=${siteRef}&workspaceId=${workspaceId}`).pipe(catchError(this.handleError));
  }

  updateAllSequences(siteRef: string, workspaceId: string, sequenceArray: any[]) {
    return this.http.put(this.sequencersUrl + `/seq-mgmt/updateMany?siteRef=${siteRef}&workspaceId=${workspaceId}`, sequenceArray).pipe(catchError(this.handleError));
  }

  getSequenceLogs(siteRef: string, sequenceId: any, sequenceRunMode: any) {
    return this.http.get(this.sequencersUrl + `/seq-logs/latest?siteRef=${siteRef}&seqId=${sequenceId}&sequenceRunMode=${sequenceRunMode}`).pipe(catchError(this.handleError));
  }

  dryRunSequence(sequenceId: string) {
    return this.http.post(this.sequencersUrl + `/seq/dryRun?seqId=${sequenceId}`, '').pipe(catchError(this.handleError));
  }

  getSequenceFunctionsList(component: string) {
    return this.http.get(this.sequencersUrl + `/seq-functions/list?component=${component}`).pipe(catchError(this.handleError));
  }

  saveSequenceFunction(sequenceFunction: any) {
    return this.http.post(this.sequencersUrl + `/seq-functions/save`, sequenceFunction).pipe(catchError(this.handleError));
  }

  updateSequenceFunction(functionId: any, sequenceFunction: any) {
    return this.http.put(this.sequencersUrl + `/seq-functions/update/${functionId}`, sequenceFunction).pipe(catchError(this.handleError));
  }

  deleteSequenceFunction(functionId: any, component: string) {
    return this.http.delete(this.sequencersUrl + `/seq-functions/remove?functionId=${functionId}&component=${component}`).pipe(catchError(this.handleError));
  }

  updateLikeOrBookmark(id: any, request: any){
    return this.http.put(this.sequencersUrl + `/seq-functions/likeOrBookmark/${id}`, request,  { responseType: 'text'}).pipe(catchError(this.handleError));                     
  }

  getSequenceFunctionsUsage(functionId: any, component: string) {
    return this.http.get(this.sequencersUrl + `/seq-functions/usage/${functionId}?component=${component}`).pipe(catchError(this.handleError));
  }

  getCCUAndEquipDetailsForSite(siteRef: string) {
    return this.http.get(this.notesUrl + `notes/ccu-equip-details/${siteRef}`).pipe(catchError(this.handleError));
  }

  getMicroPythonConversion(pySnippet:string,filename:string) {
    return this.http.post(this.edgeRunnerApi + `/micro-python/py-to-mpy`,{
      "snippet":pySnippet,
      "outputFileName": filename,
    },{ responseType: 'blob' }).pipe(catchError(this.handleError));
  }

  getSequenceDeliveryLogs(sequenceId: any) {
    return this.http.get(this.sequencersUrl + `/seq-msg/tracing/${sequenceId}`).pipe(catchError(this.handleError));
  }
 
  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error);
  }

}
