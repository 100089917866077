const DEVICE_TOOLBOX = 
{
    'kind': 'categoryToolbox',
    'contents': [
        {
            'kind': 'search',
            'name': 'Search',
            'contents': [],
            "cssConfig": {
                "container": "toolBoxSearch"
            }
        },
      {
        'kind': 'category',
        'name': 'Logic',
        'categorystyle': 'logic_category',
        'contents': [
          {
            'kind': 'block',
            'type': 'controls_if',
          },
          {
            'kind': 'block',
            'type': 'logic_compare',
          },
          {
            'kind': 'block',
            'type': 'logic_operation',
          },
          {
            'kind': 'block',
            'type': 'logic_negate',
          },
          {
            'kind': 'block',
            'type': 'logic_boolean',
          },
          {
            'kind': 'block',
            'type': 'logic_null',
          },
          {
            'kind': 'block',
            'type': 'logic_ternary',
          },
        ],
      },
      {
        'kind': 'category',
        'name': 'Loops',
        'categorystyle': 'loop_category',
        'contents': [
          {
            'kind': 'block',
            'type': 'controls_repeat_ext',
            'inputs': {
              'TIMES': {
                'shadow': {
                  'type': 'math_number',
                  'fields': {
                    'NUM': 10,
                  },
                },
              },
            },
          },
          {
            'kind': 'block',
            'type': 'controls_whileUntil',
          },
          {
            'kind': 'block',
            'type': 'controls_for',
            'inputs': {
              'FROM': {
                'shadow': {
                  'type': 'math_number',
                  'fields': {
                    'NUM': 1,
                  },
                },
              },
              'TO': {
                'shadow': {
                  'type': 'math_number',
                  'fields': {
                    'NUM': 10,
                  },
                },
              },
              'BY': {
                'shadow': {
                  'type': 'math_number',
                  'fields': {
                    'NUM': 1,
                  },
                },
              },
            },
          },
          {
            'kind': 'block',
            'type': 'controls_forEach',
          },
          {
            'kind': 'block',
            'type': 'controls_flow_statements',
          },
        ],
      },
      {
        'kind': 'category',
        'name': 'Math',
        'categorystyle': 'math_category',
        'contents': [
          {
            'kind': 'block',
            'type': 'math_number',
            'fields': {
              'NUM': 123,
            },
          },
          {
            'kind': 'block',
            'type': 'math_arithmetic',
            'inputs': {
              'A': {
                'shadow': {
                  'type': 'math_number',
                  'fields': {
                    'NUM': 1,
                  },
                },
              },
              'B': {
                'shadow': {
                  'type': 'math_number',
                  'fields': {
                    'NUM': 1,
                  },
                },
              },
            },
          },
          {
            'kind': 'block',
            'type': 'math_single',
            'inputs': {
              'NUM': {
                'shadow': {
                  'type': 'math_number',
                  'fields': {
                    'NUM': 9,
                  },
                },
              },
            },
          },
          {
            'kind': 'block',
            'type': 'math_trig',
            'inputs': {
              'NUM': {
                'shadow': {
                  'type': 'math_number',
                  'fields': {
                    'NUM': 45,
                  },
                },
              },
            },
          },
          {
            'kind': 'block',
            'type': 'math_constant',
          },
          {
            'kind': 'block',
            'type': 'math_number_property',
            'inputs': {
              'NUMBER_TO_CHECK': {
                'shadow': {
                  'type': 'math_number',
                  'fields': {
                    'NUM': 0,
                  },
                },
              },
            },
          },
          {
            'kind': 'block',
            'type': 'math_round',
            'fields': {
              'OP': 'ROUND',
            },
            'inputs': {
              'NUM': {
                'shadow': {
                  'type': 'math_number',
                  'fields': {
                    'NUM': 3.1,
                  },
                },
              },
            },
          },
          {
            'kind': 'block',
            'type': 'math_on_list',
            'fields': {
              'OP': 'SUM',
            },
          },
          {
            'kind': 'block',
            'type': 'math_modulo',
            'inputs': {
              'DIVIDEND': {
                'shadow': {
                  'type': 'math_number',
                  'fields': {
                    'NUM': 64,
                  },
                },
              },
              'DIVISOR': {
                'shadow': {
                  'type': 'math_number',
                  'fields': {
                    'NUM': 10,
                  },
                },
              },
            },
          },
          {
            'kind': 'block',
            'type': 'math_constrain',
            'inputs': {
              'VALUE': {
                'shadow': {
                  'type': 'math_number',
                  'fields': {
                    'NUM': 50,
                  },
                },
              },
              'LOW': {
                'shadow': {
                  'type': 'math_number',
                  'fields': {
                    'NUM': 1,
                  },
                },
              },
              'HIGH': {
                'shadow': {
                  'type': 'math_number',
                  'fields': {
                    'NUM': 100,
                  },
                },
              },
            },
          },
          {
            'kind': 'block',
            'type': 'math_random_int',
            'inputs': {
              'FROM': {
                'shadow': {
                  'type': 'math_number',
                  'fields': {
                    'NUM': 1,
                  },
                },
              },
              'TO': {
                'shadow': {
                  'type': 'math_number',
                  'fields': {
                    'NUM': 100,
                  },
                },
              },
            },
          },
          {
            'kind': 'block',
            'type': 'math_random_float',
          },
          {
            'kind': 'block',
            'type': 'math_atan2',
            'inputs': {
              'X': {
                'shadow': {
                  'type': 'math_number',
                  'fields': {
                    'NUM': 1,
                  },
                },
              },
              'Y': {
                'shadow': {
                  'type': 'math_number',
                  'fields': {
                    'NUM': 1,
                  },
                },
              },
            },
          },
        ],
      },
      {
        'kind': 'category',
        'name': 'Text',
        'categorystyle': 'text_category',
        'contents': [
          {
            'kind': 'block',
            'type': 'text',
          },
          {
            'kind': 'block',
            'type': 'text_multiline',
          },
          {
            'kind': 'block',
            'type': 'text_join',
          },
          {
            'kind': 'block',
            'type': 'text_append',
            'inputs': {
              'TEXT': {
                'shadow': {
                  'type': 'text',
                  'fields': {
                    'TEXT': '',
                  },
                },
              },
            },
          },
          {
            'kind': 'block',
            'type': 'text_length',
            'inputs': {
              'VALUE': {
                'shadow': {
                  'type': 'text',
                  'fields': {
                    'TEXT': 'abc',
                  },
                },
              },
            },
          },
          {
            'kind': 'block',
            'type': 'text_isEmpty',
            'inputs': {
              'VALUE': {
                'shadow': {
                  'type': 'text',
                  'fields': {
                    'TEXT': '',
                  },
                },
              },
            },
          },
          {
            'kind': 'block',
            'type': 'text_indexOf',
            'inputs': {
              'VALUE': {
                'block': {
                  'type': 'variables_get',
                },
              },
              'FIND': {
                'shadow': {
                  'type': 'text',
                  'fields': {
                    'TEXT': 'abc',
                  },
                },
              },
            },
          },
          {
            'kind': 'block',
            'type': 'text_charAt',
            'inputs': {
              'VALUE': {
                'block': {
                  'type': 'variables_get',
                },
              },
            },
          },
          {
            'kind': 'block',
            'type': 'text_getSubstring',
            'inputs': {
              'STRING': {
                'block': {
                  'type': 'variables_get',
                },
              },
            },
          },
          {
            'kind': 'block',
            'type': 'text_changeCase',
            'inputs': {
              'TEXT': {
                'shadow': {
                  'type': 'text',
                  'fields': {
                    'TEXT': 'abc',
                  },
                },
              },
            },
          },
          {
            'kind': 'block',
            'type': 'text_trim',
            'inputs': {
              'TEXT': {
                'shadow': {
                  'type': 'text',
                  'fields': {
                    'TEXT': 'abc',
                  },
                },
              },
            },
          },
          {
            'kind': 'block',
            'type': 'text_count',
            'inputs': {
              'SUB': {
                'shadow': {
                  'type': 'text',
                },
              },
              'TEXT': {
                'shadow': {
                  'type': 'text',
                },
              },
            },
          },
          {
            'kind': 'block',
            'type': 'text_replace',
            'inputs': {
              'FROM': {
                'shadow': {
                  'type': 'text',
                },
              },
              'TO': {
                'shadow': {
                  'type': 'text',
                },
              },
              'TEXT': {
                'shadow': {
                  'type': 'text',
                },
              },
            },
          },
          {
            'kind': 'block',
            'type': 'text_reverse',
            'inputs': {
              'TEXT': {
                'shadow': {
                  'type': 'text',
                },
              },
            },
          },
        ],
      },
      {
        'kind': 'category',
        'name': 'Lists',
        'categorystyle': 'list_category',
        'contents': [
          {
            'kind': 'block',
            'type': 'lists_create_with',
          },
          {
            'kind': 'block',
            'type': 'lists_create_with',
          },
          {
            'kind': 'block',
            'type': 'lists_repeat',
            'inputs': {
              'NUM': {
                'shadow': {
                  'type': 'math_number',
                  'fields': {
                    'NUM': 5,
                  },
                },
              },
            },
          },
          {
            'kind': 'block',
            'type': 'lists_length',
          },
          {
            'kind': 'block',
            'type': 'lists_isEmpty',
          },
          {
            'kind': 'block',
            'type': 'lists_indexOf',
            'inputs': {
              'VALUE': {
                'block': {
                  'type': 'variables_get',
                },
              },
            },
          },
          {
            'kind': 'block',
            'type': 'lists_getIndex',
            'inputs': {
              'VALUE': {
                'block': {
                  'type': 'variables_get',
                },
              },
            },
          },
          {
            'kind': 'block',
            'type': 'lists_setIndex',
            'inputs': {
              'LIST': {
                'block': {
                  'type': 'variables_get',
                },
              },
            },
          },
          {
            'kind': 'block',
            'type': 'lists_getSublist',
            'inputs': {
              'LIST': {
                'block': {
                  'type': 'variables_get',
                },
              },
            },
          },
          {
            'kind': 'block',
            'type': 'lists_split',
            'inputs': {
              'DELIM': {
                'shadow': {
                  'type': 'text',
                  'fields': {
                    'TEXT': ',',
                  },
                },
              },
            },
          },
          {
            'kind': 'block',
            'type': 'lists_sort',
          },
          {
            'kind': 'block',
            'type': 'lists_reverse',
          },
        ],
      },
      {
        'kind': 'category',
        'name': 'Variables',
        'categorystyle': 'variable_category',
        'custom': 'VARIABLE',
      },
      {
        'kind': 'category',
        'name': 'Functions',
        'categorystyle': 'procedure_category',
        'custom': 'PROCEDURE',
      },
      {
        'kind': 'category',
        'name': 'Conn Mod',
        'categorystyle': 'custom_category',
        'contents': [
          // {
          //   'kind': 'block',
          //   'type': 'sequence_device',
          // },
          {
            'kind': 'block',
            'type': 'sequencer',
          },
          {
            'kind': 'block',
            'type': 'print_string',
          },
          {
            'kind': 'block',
            'type': 'conn_mod_set_relay_output',
          },
          {
            'kind': 'block',
            'type': 'conn_mod_set_analog_output',
          },
          {
            'kind': 'block',
            'type': 'conn_mod_set_universal_input_mode',
          },
          {
            'kind': 'block',
            'type': 'conn_mod_get_universal_input_value',
          },
          {
            'kind': 'block',
            'type': 'conn_mod_get_sensor_bus_value',
          },
          {
            'kind': 'block',
            'type': 'conn_mod_get_universal_input_mode',
          },
          {
            'kind': 'block',
            'type': 'conn_mod_relay_output_number',
          },
          {
            'kind': 'block',
            'type': 'conn_mod_relay_output_state',
          },
          {
            'kind': 'block',
            'type': 'conn_mod_analog_output_number',
          },
          {
            'kind': 'block',
            'type': 'conn_mod_analog_output_value',
          },
          {
            'kind': 'block',
            'type': 'conn_mod_universal_input_number',
          },
          {
            'kind': 'block',
            'type': 'conn_mod_universal_input_mode',
          },
          {
            'kind': 'block',
            'type': 'sensor_bus_sensor_type',
          },
          {
            'kind': 'block',
            'type': 'conn_mod_sensor_number',
          },
          {
            'kind': 'block',
            'type': 'sequence_run_startup',
          },
          {
             'kind': 'block',
             'type': 'sequence_run_continuous',
          },
          // {
          //   'kind': 'block',
          //   'type': 'sequence_delay_milliseconds',
          // },
          // {
          //   'kind': 'block',
          //   'type': 'sequence_enter',
          // },
          // {
          //   'kind': 'block',
          //   'type': 'sequence_exit',
          // },
          {
            'kind': 'block',
            'type': 'universal_input_standard_sensor_types',
          },
          {
            'kind': 'block',
            'type': 'universal_input_get_mode_from_standard_sensor_type',
          },
          {
            'kind': 'block',
            'type': 'universal_input_convert_value_from_standard_sensor_type',
          },
          {
            'kind': 'block',
            'type': 'conn_mod_write_universal_input_modbus_register',
          },
          {
            'kind': 'block',
            'type': 'conn_mod_read_universal_input_mapping_modbus_register',
          },
          {
            'kind': 'block',
            'type': 'conn_mod_read_analog_output_modbus_register',
          },
          {
            'kind': 'block',
            'type': 'conn_mod_read_relay_output_modbus_register',
          },
          {
            'kind': 'block',
            'type': 'conn_mod_configure_home_screen_input',
          },
          {
            'kind': 'block',
            'type': 'conn_mod_update_home_screen_input',
          },
          {
            'kind': 'block',
            'type': 'universal_input_get_name_from_standard_sensor_type',
          },
          {
            'kind': 'block',
            'type': 'universal_input_get_units_from_standard_sensor_type',
          },
          {
            'kind': 'block',
            'type': 'pi_controller_process',
          },
          {
            'kind': 'block',
            'type': 'pi_controller_number',
          },
          {
            'kind': 'block',
            'type': 'modbus_register_type',
          },
          {
            'kind': 'block',
            'type': 'modbus_register_address',
          },
          {
            'kind': 'block',
            'type': 'conn_mod_read_modbus_register',
          },
          {
            'kind': 'block',
            'type': 'conn_mod_write_modbus_register',
          },
          {
            'kind': 'block',
            'type': 'conn_mod_configure_custom_modbus_register',
          },
          {
            'kind': 'block',
            'type': 'conversion_scale_value',
          },
          {
            'kind': 'block',
            'type': 'conn_mod_enable_fail_safe',
          }
        ],
      }
    ],
  };
export default DEVICE_TOOLBOX;