import { Component, EventEmitter, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-sequence-scope-selection-modal',
  templateUrl: './sequence-scope-selection-modal.component.html',
  styleUrls: ['./sequence-scope-selection-modal.component.scss']
})
export class SequenceScopeSelectionModalComponent {
  constructor(
    public dialogRef: MatDialogRef<SequenceScopeSelectionModalComponent>
  ) { }
  scopeData: any;
  isFixedHeight!: boolean;
  selectedData: any[] = [];
  copySelectedData: any[] = [];
  isFilteringData: boolean = false; 
  @Output() selectionChanged = new EventEmitter<any[]>();

  ngOnInit() {
    this.dialogRef.beforeClosed()
    .pipe(take(1))
    .subscribe(() => {
      this.returnResultOnDialogClose();
    });
    if(this.scopeData.length > 0) {
      this.updateSelectedData();
    }
  }

  returnResultOnDialogClose() {
   if(this.scopeData.length > 0) {
     if (this.isFilteringData) {
       this.selectedData = [...this.copySelectedData];
       this.isFilteringData = false;
     }
     this.dialogRef.close(this.selectedData);
   } else {
      this.dialogRef.close([]);
   }
  }

  allSystemsSelected() {
    return this.scopeData.every((system: any) => system.selected);
  }

  toggleSelectAll(checked: boolean) {
    this.scopeData.forEach((system: any) => {
        system.selected = checked;
        this.updateCopySelectedData(system.ccuName, checked);
    });
    this.updateSelectedData();
  }

  updateAllComplete(system: any) {
    this.updateCopySelectedData(system.ccuName, system.selected);
    this.updateSelectedData();
  }

  updateCopySelectedData(ccuName: string, selected: boolean) {
    const systemIndex = this.copySelectedData.findIndex((system: any) => system.ccuName === ccuName);
    if(systemIndex !== -1 && !selected) {
        // Remove CCU if it was unselected
        this.copySelectedData.splice(systemIndex, 1);
    } else if (systemIndex === -1 && selected) {
        // Add CCU if it was selected
        this.copySelectedData.push({ ccuName: ccuName, selected: selected });
    } 
  }

  updateSelectedData() {
    // If filtering is active, update selectedData array based on the filtered results
    if (this.isFilteringData) {
      this.selectedData = this.scopeData
      .filter((ccu: any) => ccu.selected && ccu.filtered)
    } else {
      // If filtering is not active, update selectedData
      this.selectedData = this.scopeData
      .filter((ccu: any) => ccu.selected)
    }
  }

  applyFilter(filterValue: any) {
    const searchTerm = filterValue.toLowerCase().trim();
    this.isFilteringData = searchTerm ? true : false;
    this.copySelectedData = [...this.selectedData];
    // Filter systems based on the search term in ccuName or profile
    this.scopeData.forEach((system: any) => {
      system.filtered = system.ccuName.toLowerCase().includes(searchTerm) || system.formattedProfile.toLowerCase().includes(searchTerm);
    });
    this.updateSelectedData();
  }

  someSystemsSelected() {
    return this.scopeData.some((system: any) => system.selected) && !this.allSystemsSelected();
  }

  anyFilteredSystems(): boolean {
    return this.scopeData.some((system: any) => !this.isFilteringData || system.filtered);
  }

}
