<div class="accordion">
    <div class="accordion__item">

        <div class="blockAccordionTitle">
            <div class="d-flex justify-content-between">
                <div class="style-updated" (click)="activateDropdown()">
                    <i [ngClass]="(isOpen) ? 'fa fa-angle-down arrow-down' : 'fa fa-angle-right arrow-right'"
                        class="customDropdownIcon" aria-hidden="true"></i>
                    <span class="accStyle">{{blockTitle}}</span>
                </div>
                <a *ngIf="showButton" class="textstyle" (click)="addFilter()"> Add
                </a>
            </div>
        </div>
        <div [ngClass]="(isOpen) ? 'accordion__item__body--open' : 'accordion__item__body'">
            <div>
                <ng-content></ng-content>
            </div>
        </div>
    </div>
</div>