import { BlockLogo } from 'src/app/shared/enums/SequenceKeys.enum';
import { BlockColors } from '../blockly-theme';

export const CUSTOM_FUNCTIONS = [
  {
    "type": "getCurrentValueMany",
    "message0": "%1 get current value for multiple at %2 for %3 %4",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "field_dropdown",
        "name": "level",
        "value": "10",
        "options": [
          [
            "default",
            "default"
          ],
          [
            "highest",
            "highest"
          ],
          [
            "level 1",
            "1"
          ],
          [
            "level 2",
            "2"
          ],
          [
            "level 3",
            "3"
          ], [
            "level 4",
            "4"
          ], [
            "level 5",
            "5"
          ], [
            "level 6",
            "6"
          ], [
            "level 7",
            "7"
          ], [
            "level 8",
            "8"
          ], [
            "level 9",
            "9"
          ],
          [
            "level 10",
            "10"
          ], [
            "level 11",
            "11"
          ], [
            "level 12",
            "12"
          ],
          [
            "level 13",
            "13"
          ], [
            "level 14",
            "14"
          ],
          [
            "1evel 15",
            "15"
          ], [
            "1evel 16",
            "16"
          ], [
            "1evel 17",
            "17"
          ]
        ]
      }, 
      {
        "type": "input_value",
        "name": "PointID"
      },
      {
        "type": "input_dummy"
      }
    ],
    "output": null,
    "colour": BlockColors.CUSTOM_FUNCTIONS,
    "tooltip": "Fetch writable value for point id",
    "helpUrl": "Fetch writable value for point id"
  },
  {
    "type": "pointWriteValueMany",
    "message0": "%1 write values for multiple writable points %2 at %3 for %4 %5",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "input_value",
        "name": "value",
        "check": "Number"
      },
      {
        "type": "field_dropdown",
        "name": "level",
        "value": "10",
        "options": [
          [
            "level 1",
            "1"
          ],
          [
            "level 2",
            "2"
          ],
          [
            "level 3",
            "3"
          ], [
            "level 4",
            "4"
          ], [
            "level 5",
            "5"
          ], [
            "level 6",
            "6"
          ], [
            "level 7",
            "7"
          ], [
            "level 8",
            "8"
          ], [
            "level 9",
            "9"
          ],
          [
            "level 10",
            "10"
          ], [
            "level 11",
            "11"
          ], [
            "level 12",
            "12"
          ],
          [
            "level 13",
            "13"
          ], [
            "level 14",
            "14"
          ],
          [
            "level 15",
            "15"
          ], [
            "level 16",
            "16"
          ], [
            "level 17",
            "17"
          ]
        ]
      }, 
      {
        "type": "input_value",
        "name": "PointID"
      },
      {
        "type": "input_dummy"
      }
    ],
    "previousStatement": null,
    "nextStatement": null,
    "colour": BlockColors.CUSTOM_FUNCTIONS,
    "tooltip": "Fetch writable his value for point id",
    "helpUrl": "Fetch writable his value for point id"
  },
  {
    "type": "hisWriteValueMany",
    "message0": "%1 write values for multiple historized points %2 for %3 %4 ",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "input_value",
        "name": "value",
        "check": "Number"
      },
      {
        "type": "input_value",
        "name": "PointID"
      },
      {
        "type": "input_dummy"
      }
    ],
    "previousStatement": null,
    "nextStatement": null,
    "colour": BlockColors.CUSTOM_FUNCTIONS,
    "tooltip": "Fetch his value for point id",
    "helpUrl": "Fetch his value for point id"
  },
  {
    "type": "writeValueandHistorizeMany",
    "message0": "%1 write values for multiple writable and historized points %2 at %3 for %4 %5",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "input_value",
        "name": "value",
        "check": "Number"
      },
      {
        "type": "field_dropdown",
        "name": "level",
        "value": "10",
        "options": [
          [
            "level 1",
            "1"
          ],
          [
            "level 2",
            "2"
          ],
          [
            "level 3",
            "3"
          ], [
            "level 4",
            "4"
          ], [
            "level 5",
            "5"
          ], [
            "level 6",
            "6"
          ], [
            "level 7",
            "7"
          ], [
            "level 8",
            "8"
          ], [
            "level 9",
            "9"
          ],
          [
            "level 10",
            "10"
          ], [
            "level 11",
            "11"
          ], [
            "level 12",
            "12"
          ],
          [
            "level 13",
            "13"
          ], [
            "level 14",
            "14"
          ],
          [
            "level 15",
            "15"
          ], [
            "level 16",
            "16"
          ], [
            "level 17",
            "17"
          ]
        ]
      }, 
      {
        "type": "input_value",
        "name": "PointID"
      },
      {
        "type": "input_dummy"
      }
    ],
    "previousStatement": null,
    "nextStatement": null,
    "colour": BlockColors.CUSTOM_FUNCTIONS,
    "tooltip": "Fetch writable his value for point id",
    "helpUrl": "Fetch writable his value for point id"
  }
];