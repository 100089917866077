import * as Blockly from 'blockly';

export class BlocklyValidators {
  private static validatorsAdded: boolean = false;

  static addValidators() {
    if(!this.validatorsAdded) {
      try {
        Blockly.Extensions.register(
          "unit_selection_validation",
          function (this: any) {
            this.setOnChange((event: Blockly.Events.Abstract) => {
              if (this.isInFlyout) {
                return;
              }
              if (
                event.type === Blockly.Events.CHANGE &&
                (event as Blockly.Events.BlockCreate).blockId === this.id
                      ) {
                        if (this.getFieldValue('FROM_UNIT') !== this.getFieldValue('TO_UNIT')) {
                          this.setWarningText(null);
                          this.setHighlighted(false);
                          this.isInErrorState = false;
                          
                        } else {
                          this.setWarningText('Conversion units cannot be same');
                          this.setHighlighted(true);
                          this.isInErrorState = true;
                        }
                      }
             
            });
          }
        );
        BlocklyValidators.validatorsAdded = true;
      }
      catch (e) {
        console.log("Error in adding code generators", e);
      }
    } else {
      console.log("Validators already added.");
    }
  }
}