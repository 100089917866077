<mat-form-field [class]="formFieldClass">
  <mat-select #selectDropdown [(value)] = "selectedValue" (keydown.ArrowDown)="openDropdown()" 
    (keydown.Enter)="$event.stopImmediatePropagation()" (keydown.ArrowUp)="$event.stopImmediatePropagation()" 
    (keydown.ArrowLeft)="$event.stopImmediatePropagation()" (keydown.ArrowRight)="$event.stopImmediatePropagation()" 
    (change)="setOption($event)" [hideSingleSelectionIndicator]="true"
    [placeholder]="placeholderLabel" [multiple]="multiSelect" [disabled]="disabled" [disableOptionCentering]="true">
    <mat-select-trigger *ngIf="multiSelect">
      <div [ngClass]="{'mat-select-trigger-clearable': showClear && selectedValues.length}">
        {{selectedValues.length ? (displayKey?(selectedValues[0][displayKey]):selectedValues[0]) : ''}}
        <span *ngIf="selectedValues?.length > 1" class="example-additional-selection">
          (+{{selectedValues.length - 1}} more)
        </span>
      </div>
    </mat-select-trigger>

    <!-- search start -->
    <div [class.hide]="!search" class="mat-select-search-inner"
        [ngClass]="{'mat-select-search-inner-multiple': matSelect.multiple}">
      <input matInput
            class="mat-select-search-input"
            #searchSelectInput
            (keydown)="_handleKeydown($event)"
            [(ngModel)]="searchText"
            (input)="searchText"
            (ngModelChange)="inputChanged($event)"
            autocomplete="off"
      />

      <button mat-button *ngIf="searchText"
          mat-icon-button
          aria-label="Clear"
          (click)="_reset(true)"
          class="mat-select-search-clear">
          <mat-icon class="appIcon">close</mat-icon>
      </button>
    </div>
    <div *ngIf="(noEntriesFoundLabel && filteredList.length === 0 && !emptyResults)"
        class="mat-select-search-no-entries-found">
      {{noEntriesFoundLabel}}
    </div>
    <div *ngIf="searchingLabel && isSearching"
        class="mat-select-search-no-entries-found">
      {{searchingLabel}}
    </div>
    <!-- search end -->
    
    <div class="selectionOptions" #dropDownOptions *ngIf="!isSearching">
      <mat-option  *ngFor="let item of filteredList" [value]="valueKey?item[valueKey]:item"  [ngClass] ="{'indeterminate-state': selectedValues.length > 0 && selectedValues.length != data.length && item[valueKey] == 'all' }"  [disabled]="_checkPrimary&&primaryKey?item[primaryKey]:false" (click)="toggleSelection(item)">
        {{displayKey?item[displayKey]:item}}
      </mat-option>
    </div>
    <ng-container *ngIf="multiSelect">
      <div class="divider" *ngIf="selectedValues.length"></div>
      <div class="selectedOptionsList">
        <ng-container *ngFor="let item of selectedValues">
          <mat-option *ngIf="checkSelection(item)" [value]="valueKey?item[valueKey]:item" (click)="toggleSelection(item)">
            {{displayKey?item[displayKey]:item}}</mat-option>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="!multiSelect && showSelectVal">
      <div class="divider" *ngIf="selectedValues.length"></div>
      <div class="selectedOptionsList">
        <ng-container *ngFor="let item of selectedValues">
          <mat-option *ngIf="checkSelection(item)" [value]="valueKey?item[valueKey]:item" (click)="toggleSelection(item)">
            {{displayKey?item[displayKey]:item}}</mat-option>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="emptyResults">
      <div class="selectedOptionsList">
        <mat-option [disabled] ="emptyResults" class="hide-checkbox">
          {{noEntriesFoundLabel}}
        </mat-option>
      </div>
    </ng-container>
  </mat-select>
  <span *ngIf="isRequired" class="required-field" matPrefix>*</span>
  <button mat-icon-button *ngIf="showClear && selectedValues.length" aria-label="Clear" (click)="clearSelection($event,true)"
    class="mat-select-value-clear">
    <i class="fas fa-times color-secondary clear-icon" aria-hidden="true"></i>
  </button>

</mat-form-field>
