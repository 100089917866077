import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-delivery-logs-table',
  templateUrl: './delivery-logs-table.component.html',
  styleUrls: ['./delivery-logs-table.component.scss']
})
export class DeliveryLogsTableComponent {
  @Input() selectedSequence: any;
  @Input() table: any;
  @Input() headerColumns: any;
  @Input() isLoading: any;
  @Input() showCloseButton!: boolean;
  dataSource!: MatTableDataSource<any>;
  displayedColumns!: string[];
  originalTableData: any[] = [];
  currentDate = new Date();
  deliveryCount = 0;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;

  constructor() {
    this.dataSource = new MatTableDataSource();
    this.displayedColumns = [];
  }

  ngOnChanges(changes: SimpleChanges) {
    const table = changes['table']?.currentValue;
    if (table && Object.keys(table)?.length) { 
      this.originalTableData = this.table ? [...this.table] : [];
      this.dataSource.data = this.table;
      this.dataSource.sort = this.sort;
      this.deliveryCount = this.table.filter((item: any) => item.deliveredDateTime).length;
    }

    if (changes['headerColumns']?.currentValue) {
      this.headerColumns = changes['headerColumns'].currentValue;
      this.displayedColumns = this.headerColumns;
    }
  }

  getFormattedColumnName(columnName: string) {
    switch (columnName) {
      case 'deliveredDateTime':
        return 'Delivered Date & Time';
      case 'ackedDateTime':
        return 'Confirmed Receipt';
      case 'ccuName':
        return 'System';
      default:
        return columnName;
    }
  }


}
