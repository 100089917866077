import { Device, DFUDevice } from "./webusbdevice";
import * as CryptoJS from 'crypto-js';;
export class DFU {
    static DETACH = 0x00;
    static DNLOAD = 0x01;
    static UPLOAD = 0x02;
    static GETSTATUS = 0x03;
    static CLRSTATUS = 0x04;
    static GETSTATE = 0x05;
    static ABORT = 6;

    static appIDLE = 0;
    static appDETACH = 1;
    static dfuIDLE = 2;
    static dfuDNLOAD_SYNC = 3;
    static dfuDNBUSY = 4;
    static dfuDNLOAD_IDLE = 5;
    static dfuMANIFEST_SYNC = 6;
    static dfuMANIFEST = 7;
    static dfuMANIFEST_WAIT_RESET = 8;
    static dfuUPLOAD_IDLE = 9;
    static dfuERROR = 10;

    static STATUS_OK = 0x0;

    static SEQUENCE_ADDERESS ="0x080B6000";
    static SEQUENCE_META_ADDERESS ="0x080C7000";

    static GET_COMMANDS = 0x00;
    static SET_ADDRESS = 0x21;
    static ERASE_SECTOR = 0x41;
    static SEQUENCE_NAME_MAX_LENGTH = 44;

    static SEQUENCE_HMAC_KEY = new Uint8Array([
        0xfb, 0x54, 0x59, 0x99, 0x80, 0xd1, 0xc6, 0xf5, 0x04, 0x31, 0xda, 0xe7, 0xd3, 0x26, 0xf6, 0x2c
    ]);

    static hex4(n:any) {
        let s = n.toString(16)
        while (s.length < 4) {
            s = '0' + s;
        }
        return s;
    }

    static hexAddr8(n:any) {
        let s = n.toString(16)
        while (s.length < 8) {
            s = '0' + s;
        }
        return "0x" + s;
    }

    static niceSize(n:any) {
        const gigabyte = 1024 * 1024 * 1024;
        const megabyte = 1024 * 1024;
        const kilobyte = 1024;
        if (n >= gigabyte) {
            return n / gigabyte + "GiB";
        } else if (n >= megabyte) {
            return n / megabyte + "MiB";
        } else if (n >= kilobyte) {
            return n / kilobyte + "KiB";
        } else {
            return n + "B";
        }
    }

    static findDeviceDfuInterfaces(device:any) {
        let interfaces = [];
        for (let conf of device.configurations) {
            for (let intf of conf.interfaces) {
                for (let alt of intf.alternates) {
                    if (alt.interfaceClass == 0xFE &&
                        alt.interfaceSubclass == 0x01 &&
                        (alt.interfaceProtocol == 0x01 || alt.interfaceProtocol == 0x02)) {
                        let settings = {
                            "configuration": conf,
                            "interface": intf,
                            "alternate": alt,
                            "name": alt.interfaceName
                        };
                        interfaces.push(settings);
                    }
                }
            }
        }

        return interfaces;
    }

    static parseDeviceDescriptor(data:any) {
        return {
            bLength:            data.getUint8(0),
            bDescriptorType:    data.getUint8(1),
            bcdUSB:             data.getUint16(2, true),
            bDeviceClass:       data.getUint8(4),
            bDeviceSubClass:    data.getUint8(5),
            bDeviceProtocol:    data.getUint8(6),
            bMaxPacketSize:     data.getUint8(7),
            idVendor:           data.getUint16(8, true),
            idProduct:          data.getUint16(10, true),
            bcdDevice:          data.getUint16(12, true),
            iManufacturer:      data.getUint8(14),
            iProduct:           data.getUint8(15),
            iSerialNumber:      data.getUint8(16),
            bNumConfigurations: data.getUint8(17),
        };
    };

    static parseConfigurationDescriptor(data:any) {
        let descriptorData = new DataView(data.buffer.slice(9));
        let descriptors = DFU.parseSubDescriptors(descriptorData);
        return {
            bLength:            data.getUint8(0),
            bDescriptorType:    data.getUint8(1),
            wTotalLength:       data.getUint16(2, true),
            bNumInterfaces:     data.getUint8(4),
            bConfigurationValue:data.getUint8(5),
            iConfiguration:     data.getUint8(6),
            bmAttributes:       data.getUint8(7),
            bMaxPower:          data.getUint8(8),
            descriptors:        descriptors
        };
    };

    static parseInterfaceDescriptor(data:any) {
        return {
            bLength:            data.getUint8(0),
            bDescriptorType:    data.getUint8(1),
            bInterfaceNumber:   data.getUint8(2),
            bAlternateSetting:  data.getUint8(3),
            bNumEndpoints:      data.getUint8(4),
            bInterfaceClass:    data.getUint8(5),
            bInterfaceSubClass: data.getUint8(6),
            bInterfaceProtocol: data.getUint8(7),
            iInterface:         data.getUint8(8),
            descriptors:        []
        };
    };

    static parseFunctionalDescriptor(data:any) {
        return {
            bLength:           data.getUint8(0),
            bDescriptorType:   data.getUint8(1),
            bmAttributes:      data.getUint8(2),
            wDetachTimeOut:    data.getUint16(3, true),
            wTransferSize:     data.getUint16(5, true),
            bcdDFUVersion:     data.getUint16(7, true)
        };
    };

    static parseSubDescriptors(descriptorData:any) {
        const DT_INTERFACE = 4;
        const DT_ENDPOINT = 5;
        const DT_DFU_FUNCTIONAL = 0x21;
        const USB_CLASS_APP_SPECIFIC = 0xFE;
        const USB_SUBCLASS_DFU = 0x01;
        let remainingData = descriptorData;
        let descriptors = [];
        let currIntf:any;
        let inDfuIntf = false;
        while (remainingData.byteLength > 2) {
            let bLength = remainingData.getUint8(0);
            let bDescriptorType = remainingData.getUint8(1);
            let descData = new DataView(remainingData.buffer.slice(0, bLength));
            if (bDescriptorType == DT_INTERFACE) {
                currIntf = DFU.parseInterfaceDescriptor(descData);
                if (currIntf.bInterfaceClass == USB_CLASS_APP_SPECIFIC &&
                    currIntf.bInterfaceSubClass == USB_SUBCLASS_DFU) {
                    inDfuIntf = true;
                } else {
                    inDfuIntf = false;
                }
                descriptors.push(currIntf);
            } else if (inDfuIntf && bDescriptorType == DT_DFU_FUNCTIONAL) {
                let funcDesc = DFU.parseFunctionalDescriptor(descData)
                descriptors.push(funcDesc);
                currIntf.descriptors.push(funcDesc);
            } else {
                let desc = {
                    bLength: bLength,
                    bDescriptorType: bDescriptorType,
                    data: descData
                };
                descriptors.push(desc);
                if (currIntf) {
                    currIntf.descriptors.push(desc);
                }
            }
            remainingData = new DataView(remainingData.buffer.slice(bLength));
        }

        return descriptors;
    }

    static async fixInterfaceNames(device_:any, interfaces:any) {
        // Check if any interface names were not read correctly
        if (interfaces.some((intf:any) => (intf.name == null))) {
            // Manually retrieve the interface name string descriptors
            let tempDevice = new Device(device_, interfaces[0]);
            await tempDevice.device_.open();
            await tempDevice.device_.selectConfiguration(1);
            let mapping = await tempDevice.readInterfaceNames();
            await tempDevice.close();

            for (let intf of interfaces) {
                if (intf.name === null) {
                    let configIndex = intf.configuration.configurationValue;
                    let intfNumber = intf["interface"].interfaceNumber;
                    let alt = intf.alternate.alternateSetting;
                    intf.name = mapping[configIndex][intfNumber][alt];
                }
            }
        }
    }

    static formatDFUInterfaceAlternate(settings:any) {
        let mode = "Unknown"
        if (settings.alternate.interfaceProtocol == 0x01) {
            mode = "Runtime";
        } else if (settings.alternate.interfaceProtocol == 0x02) {
            mode = "DFU";
        }

        const cfg = settings.configuration.configurationValue;
        const intf = settings["interface"].interfaceNumber;
        const alt = settings.alternate.alternateSetting;
        const name = (settings.name) ? settings.name : "UNKNOWN";

        return `${mode}: cfg=${cfg}, intf=${intf}, alt=${alt}, name="${name}"`;
    }

    static getDFUDescriptorProperties(device:any) {
        // Attempt to read the DFU functional descriptor
        // TODO: read the selected configuration's descriptor
        return device.readConfigurationDescriptor(0).then(
            (data:any) => {
                let configDesc = DFU.parseConfigurationDescriptor(data);
                let funcDesc = null;
                let configValue = device.settings.configuration.configurationValue;
                if (configDesc.bConfigurationValue == configValue) {
                    for (let desc of configDesc.descriptors) {
                        if (desc.bDescriptorType == 0x21 && desc.hasOwnProperty("bcdDFUVersion")) {
                            funcDesc = desc;
                            break;
                        }
                    }
                }

                if (funcDesc) {
                    return {
                        WillDetach:            ((funcDesc.bmAttributes & 0x08) != 0),
                        ManifestationTolerant: ((funcDesc.bmAttributes & 0x04) != 0),
                        CanUpload:             ((funcDesc.bmAttributes & 0x02) != 0),
                        CanDnload:             ((funcDesc.bmAttributes & 0x01) != 0),
                        TransferSize:          funcDesc.wTransferSize,
                        DetachTimeOut:         funcDesc.wDetachTimeOut,
                        DFUVersion:            funcDesc.bcdDFUVersion
                    };
                } else {
                    return {};
                }
            },
            (error:any) => {}
        );
    }

    static async upload(device:any,firmwareFile:any,transferSize:any,startAddress:string,disconnect:boolean) {
  
        if (device && firmwareFile != null) {
           
            try {
                let status = await device.getStatus();
                if (status.state == DFU.dfuERROR) {
                    await device.clearStatus();
                }
            } catch (error) {
                console.log(error);
                device.logWarning("Failed to clear status");
            }
            
            device.startAddress = parseInt(startAddress, 16);
            if(!disconnect) {
                firmwareFile = await DFU.readBlobContent(firmwareFile);
            }
            await device.do_download(transferSize, firmwareFile, false).then(
                async () => {
                    if (disconnect) {
                         // Reset to exit MANIFEST_WAIT_RESET
                        device.waitDisconnected(5000).then(
                            (dev:any) => {
                                console.log("Device disconnected");
                                device = null;
                            },
                            (error:any) => {
                                // It didn't reset and disconnect for some reason...
                                console.log("Device unexpectedly tolerated manifestation.");
                            }
                        );
                    }
                },
                (error:any) => {
                    console.log("Error during download:", error);
                }
            )
        }
      }

    static async readBlobContent(blob:any) {
        return await blob.arrayBuffer();
    }

    static async connect(device:any) {
        try {
            await device.open();
        } catch (error) {
           console.log("Failed to open device:", error);
            throw error;
        }
    
        // Attempt to parse the DFU functional descriptor
        let desc:any = {};
        try {
            desc = await DFU.getDFUDescriptorProperties(device);
        } catch (error) {
            console.log("Failed to get DFU descriptor properties:", error);
            throw error;
        }
    
        let memorySummary = "";
        if (desc && Object.keys(desc).length > 0) {
            device.properties = desc;
            let info = `WillDetach=${desc.WillDetach}, ManifestationTolerant=${desc.ManifestationTolerant}, CanUpload=${desc.CanUpload}, CanDnload=${desc.CanDnload}, TransferSize=${desc.TransferSize}, DetachTimeOut=${desc.DetachTimeOut}, Version=${DFU.hex4(desc.DFUVersion)}`;
           
    
            if (desc.DFUVersion == 0x011a && device.settings.alternate.interfaceProtocol == 0x02) {
                device = new DFUDevice(device.device_, device.settings);
                if (device.memoryInfo) {
                    let totalSize = 0;
                    for (let segment of device.memoryInfo.segments) {
                        totalSize += segment.end - segment.start;
                    }
                    memorySummary = `Selected memory region: ${device.memoryInfo.name} (${DFU.niceSize(totalSize)})`;
                    for (let segment of device.memoryInfo.segments) {
                        let properties = [];
                        if (segment.readable) {
                            properties.push("readable");
                        }
                        if (segment.erasable) {
                            properties.push("erasable");
                        }
                        if (segment.writable) {
                            properties.push("writable");
                        }
                        let propertySummary = properties.join(", ");
                        if (!propertySummary) {
                            propertySummary = "inaccessible";
                        }
    
                        memorySummary += `\n${DFU.hexAddr8(segment.start)}-${DFU.hexAddr8(segment.end-1)} (${propertySummary})`;
                    }
                }
            }
        }
    
        // Update buttons based on capabilities
        if (device.settings.alternate.interfaceProtocol == 0x01) {
          console.log("Not DFU device");
        } else {
            // DFU
            console.log("DFU device");
           
        }
    
        if (device.memoryInfo) {
           
            device.startAddress = parseInt(DFU.SEQUENCE_ADDERESS, 16); ;
        }
    
        return device;
    }

    static parseMemoryDescriptor(desc:any) {
        const nameEndIndex = desc.indexOf("/");
        if (!desc.startsWith("@") || nameEndIndex == -1) {
            throw `Not a DfuSe memory descriptor: "${desc}"`;
        }

        const name = desc.substring(1, nameEndIndex).trim();
        const segmentString = desc.substring(nameEndIndex);

        let segments:any = [];

        const sectorMultipliers:any = {
            ' ': 1,
            'B': 1,
            'K': 1024,
            'M': 1048576
        };

        let contiguousSegmentRegex = /\/\s*(0x[0-9a-fA-F]{1,8})\s*\/(\s*[0-9]+\s*\*\s*[0-9]+\s?[ BKM]\s*[abcdefg]\s*,?\s*)+/g;
        let contiguousSegmentMatch;
        while (contiguousSegmentMatch = contiguousSegmentRegex.exec(segmentString)) {
            let segmentRegex = /([0-9]+)\s*\*\s*([0-9]+)\s?([ BKM])\s*([abcdefg])\s*,?\s*/g;
            let startAddress = parseInt(contiguousSegmentMatch[1], 16);
            let segmentMatch;
            while (segmentMatch = segmentRegex.exec(contiguousSegmentMatch[0])) {
                let segment:any = {}
                let sectorCount = parseInt(segmentMatch[1], 10);
                let sectorSize = parseInt(segmentMatch[2]) * sectorMultipliers[segmentMatch[3]];
                let properties = segmentMatch[4].charCodeAt(0) - 'a'.charCodeAt(0) + 1;
                segment.start = startAddress;
                segment.sectorSize = sectorSize;
                segment.end = startAddress + sectorSize * sectorCount;
                segment.readable = (properties & 0x1) != 0;
                segment.erasable = (properties & 0x2) != 0;
                segment.writable = (properties & 0x4) != 0;
                segments.push(segment);

                startAddress += sectorSize * sectorCount;
            }
        }

        return {"name": name, "segments": segments};
    };

// Maximum number of characters that can be contained in the sequence name hex string, including the null terminator


static stringToPaddedArrayBuffer(str:any, length:number) {
  const strBuffer = new TextEncoder().encode(str);
  const buffer = new ArrayBuffer(length);
  const view = new Uint8Array(buffer);
  view.set(strBuffer.slice(0, length - 1), 0); // Trim the string to 1 less than the length
  return buffer;
}

static generateHmac(key:any, message:any) {
  const wordArrayKey = CryptoJS.lib.WordArray.create(new Uint8Array(key));
  const wordArrayMessage = CryptoJS.lib.WordArray.create(new Uint8Array(message));
  const hmac = CryptoJS.HmacSHA256(wordArrayMessage, wordArrayKey);
  const hmacWords = hmac.words;
  const hmacBuffer = new ArrayBuffer(hmacWords.length * 4);
  const view = new DataView(hmacBuffer);
  for (let i = 0; i < hmacWords.length; i++) {
    view.setInt32(i * 4, hmacWords[i], false); // Set the endianness to false to reverse the byte order
  }
  return hmacBuffer;
}

static generateMetadataPacked(sequenceLengthBuffer:any, sequenceSignatureBuffer:any, sequenceNameBuffer:any) {
  const metadata = new Uint8Array(sequenceLengthBuffer.byteLength + sequenceSignatureBuffer.byteLength + sequenceNameBuffer.byteLength);
  metadata.set(new Uint8Array(sequenceLengthBuffer), 0);
  metadata.set(new Uint8Array(sequenceSignatureBuffer), sequenceLengthBuffer.byteLength);
  metadata.set(new Uint8Array(sequenceNameBuffer), sequenceLengthBuffer.byteLength + sequenceSignatureBuffer.byteLength);
  return metadata.buffer;
}

static async generateMetadata(sequence:any, sequenceName:string) {
  // Convert name to a byte array
  // SEQUENCE_NAME_MAX_LENGTH represents the amount of space to store the string, therefore number of characters is SEQUENCE_NAME_MAX_LENGTH - 1, as the last byte is reserved for the null terminator
  const sequenceNameBuffer = DFU.stringToPaddedArrayBuffer(sequenceName, DFU.SEQUENCE_NAME_MAX_LENGTH);
  sequence = await DFU.readBlobContent(sequence);
  // Get the length of the sequence in bytes
  const sequenceLength = sequence.byteLength;
  const sequenceLengthBuffer = new ArrayBuffer(4);
  new DataView(sequenceLengthBuffer).setUint32(0, sequenceLength, true);

  const keyBuffer = new Uint8Array(DFU.SEQUENCE_HMAC_KEY).buffer;

  // Generate the sequence signature using HMAC-SHA256 with provided key
  const sequenceSignatureBuffer = DFU.generateHmac(keyBuffer, sequence);

  console.log('sequenceSignatureBuffer:', sequenceSignatureBuffer);

  // Print sequenceLengthBuffer as a hex value
  console.log('Sequence Length:', Array.from(new Uint8Array(sequenceLengthBuffer)).map(byte => byte.toString(16).padStart(2, '0')).join(' '));
  // Print length of sequenceLengthBuffer
  console.log('Sequence Length Buffer Length:', sequenceLengthBuffer.byteLength);

  // Print sequenceSignatureBuffer as a hex value
  console.log('Sequence Signature:', Array.from(new Uint8Array(sequenceSignatureBuffer)).map(byte => byte.toString(16).padStart(2, '0')).join(' '));
  // Print length of sequenceSignatureBuffer
  console.log('Sequence Signature Buffer Length:', sequenceSignatureBuffer.byteLength);

  // Print sequenceNameBuffer as a hex value
  console.log('Sequence Name:', Array.from(new Uint8Array(sequenceNameBuffer)).map(byte => byte.toString(16).padStart(2, '0')).join(' '));
  // Print length of sequenceNameBuffer
  console.log('Sequence Name Buffer Length:', sequenceNameBuffer.byteLength);

  // Pack the metadata fields into a single byte array
  const metadata = DFU.generateMetadataPacked(sequenceLengthBuffer, sequenceSignatureBuffer, sequenceNameBuffer);

  // Print the metadata and indicate in the log message
  console.log('Sequence Metadata (Packed):', Array.from(new Uint8Array(metadata)).map(byte => byte.toString(16).padStart(2, '0')).join(' '));

  // Print length of sequence metadata
  console.log('Sequence Metadata Length:', metadata.byteLength);

  // Return the metadata
  return metadata;
}

static replaceValuesInArrayBuffer(originalBuffer: ArrayBuffer, replacementBuffer: ArrayBuffer, startIndex: number, endIndex: number): ArrayBuffer {
    const originalView = new DataView(originalBuffer);
    const replacementView = new DataView(replacementBuffer);

    for (let i = 0; i < endIndex; i++) {
        const offset = startIndex + i;
        if (i < replacementBuffer.byteLength) {
            originalView.setUint8(offset, replacementView.getUint8(i));
        } else {
            // Offset is out of bounds, write an empty value (0) or adjust as needed
            originalView.setUint8(offset, 0xFF);
        }
    }


    return originalBuffer;
}

}
    