<ng-container>
    <div class="header">
        <div class="header-icons-container" *ngIf="showConfirmIcon">
            <div class="text-center">
                <i class="fa fa-exclamation-triangle color-primary fa-lg" aria-hidden="true"></i>
            </div>
        </div>
        <div class="header-content-container">
            <div class="header-main-content">
                <h1 mat-dialog-title class="text-capitalize sg-modal-title" [ngClass]="subTitle ? 'm-b-10' : 'm-b-2'" *ngIf="title">{{ title }}</h1>
            </div>
            <h4 mat-dialog-sub-title class="text-capitalize sg-modal-sub-title m-b-10" *ngIf="subTitle">{{ subTitle }}</h4>
        </div>
    </div>
</ng-container>
<div mat-dialog-content>
    <div  class="scrollable-content min">
        <div class="action-msg" *ngIf="!isCanvasAction" id="actionMsgType{{sequenceFunction.eventType}}">{{sequenceFunction.eventLabel}} this sequence will impact users on <span class="countStyle" id="siteCount">{{sequenceFunction.siteCount}} {{sequenceFunction.siteText}}</span>,
            including those <br> who have liked it <span class="countStyle" id="likeCount">({{sequenceFunction.likeCount}} {{sequenceFunction.likeText}})</span> & bookmarked it <span class="countStyle" id="likeCount">({{sequenceFunction.bookmarkCount}} {{sequenceFunction.bookmarkText}}).</span>
        </div>

        <div class="action-msg" *ngIf="isCanvasAction" id="actionMsgType{{sequenceFunction.eventType}}">
            This canvas contains {{sequenceFunction.sequenceCount}} {{sequenceFunction.sequenceText}}.
        </div>

    </div>
</div>
<div mat-dialog-actions  [align]="'end'">
    <ng-container *ngIf="type == 'dialog'">
        <button class="text-uppercase btn color-primary" (click)="onNoClick()">Ok</button>
    </ng-container>
    <ng-container *ngIf="type == 'confirm'">
        <button class="text-uppercase btn color-primary" (click)="onNoClick()">Cancel</button>
        <span class="seperator">|</span>
        <button class="text-uppercase btn btn-text color-primary" [mat-dialog-close]="'confirm'"
            cdkFocusInitial (click)="onBtnAction('confirm')">{{confirmBtnText ? confirmBtnText : 'CONFIRM'}}</button>
    </ng-container>
</div>