
import { Injectable } from '@angular/core';
import { initialize } from "@devcycle/devcycle-js-sdk";
import { DVCVariable } from '@devcycle/devcycle-js-sdk/src/Variable';
import { Subject } from 'rxjs';
import { ConfigurationService } from './configuration.service';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class FeatureToggleService {

  private flags : any;
  flagChange = new Subject();

  featureFlagsSubject = new Subject();
  initalizedSubject = new Subject();
  //devCycle
  dvcClient:any;
  constructor(
    public configService: ConfigurationService,
    public locaStorageService: LocalStorageService, ) {
   
  }

  async devCycleInit() {
    try {
      // Call initialize with the client key and a user object
      // await on the features to be loaded from our servers
      this.dvcClient = await initialize(this.configService.getConfig('devCycleClientId'), { email: this.locaStorageService.getUserEmail(), user_id: this.locaStorageService.getUserId(),customData:{}}).onClientInitialized();
      this.flags = this.dvcClient.allVariables();
      this.initalizedSubject.next(true);
      this.dvcClient.subscribe(
        "variableUpdated:*",
        (key: string, variable: DVCVariable<any>) => {
          // key is the variable that has been updated
          // The new value can be accessed from the variable object passed in: variable.value
          this.flags[key] = variable;
          this.flagChange.next(this.flags);
        }
      );
    } catch(ex) {
      console.log('Error initializing DVC: ${ex}')
    }
  }

  getFlags() {
    if(this.flags) {
      this.featureFlagsSubject.next(this.flags);
    } else {
      this.initalizedSubject.subscribe(()=>{
        this.featureFlagsSubject.next(this.flags);
      })
    }
  }

  close() {
    this.dvcClient?.close()
  }
}
