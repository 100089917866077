import { DFU } from "./dfu-helper";
declare var navigator:any;

export const GET_COMMANDS = 0x00;
export const SET_ADDRESS = 0x21;
export const ERASE_SECTOR = 0x41;

export interface MemorySegment {
    start: number;
    sectorSize: number;
    end: number;
    readable: boolean;
    erasable: boolean;
    writable: boolean;
}

export interface MemoryInfo {
    name: string;
    segments: MemorySegment[];
}

export class Device {
    device_: any;
    settings: any;
    intfNumber: any;
    constructor(device:any,settings:any) { 
        this.device_ = device;
        this.settings = settings;
        this.intfNumber = settings["interface"].interfaceNumber;
    }
   
    logDebug(msg:any) {

    };

    logInfo(msg:any) {
        console.log(msg);
    };

    logWarning(msg:any) {
        console.log(msg);
    };

    logError(msg:any) {
        console.log(msg);
    };

    logProgress(done:any, total:any) {
        if (typeof total === 'undefined') {
            console.log(done)
        } else {
            console.log(done + '/' + total);
        }
    };

    async open() {
        await this.device_.open();
        const confValue = this.settings.configuration.configurationValue;
        if (this.device_.configuration === null ||
            this.device_.configuration.configurationValue != confValue) {
            await this.device_.selectConfiguration(confValue);
        }

        const intfNumber = this.settings["interface"].interfaceNumber;
        if (!this.device_.configuration.interfaces[intfNumber].claimed) {
            await this.device_.claimInterface(intfNumber);
        }

        const altSetting = this.settings.alternate.alternateSetting;
        let intf = this.device_.configuration.interfaces[intfNumber];
        if (intf.alternate === null ||
            intf.alternate.alternateSetting != altSetting ||
            intf.alternates.length > 1) {
            try {
                await this.device_.selectAlternateInterface(intfNumber, altSetting);
            } catch (error:any) {
                if (intf.alternate.alternateSetting == altSetting &&
                    error.endsWith("Unable to set device interface.")) {
                    this.logWarning(`Redundant SET_INTERFACE request to select altSetting ${altSetting} failed`);
                } else {
                    throw error;
                }
            }
        }
    }

    async close() {
        try {
            await this.device_.close();
        } catch (error) {
            console.log(error);
        }
    };

    readDeviceDescriptor() {
        const GET_DESCRIPTOR = 0x06;
        const DT_DEVICE = 0x01;
        const wValue = (DT_DEVICE << 8);

        return this.device_.controlTransferIn({
            "requestType": "standard",
            "recipient": "device",
            "request": GET_DESCRIPTOR,
            "value": wValue,
            "index": 0
        }, 18).then(
            (result:any) => {
                if (result.status == "ok") {
                     return Promise.resolve(result.data);
                } else {
                    return Promise.reject(result.status);
                }
            }
        );
    };

    async readStringDescriptor(index:any, langID:any) {
        if (typeof langID === 'undefined') {
            langID = 0;
        }

        const GET_DESCRIPTOR = 0x06;
        const DT_STRING = 0x03;
        const wValue = (DT_STRING << 8) | index;

        const request_setup = {
            "requestType": "standard",
            "recipient": "device",
            "request": GET_DESCRIPTOR,
            "value": wValue,
            "index": langID
        }

        // Read enough for bLength
        var result = await this.device_.controlTransferIn(request_setup, 1);

        if (result.status == "ok") {
            // Retrieve the full descriptor
            const bLength = result.data.getUint8(0);
            result = await this.device_.controlTransferIn(request_setup, bLength);
            if (result.status == "ok") {
                const len = (bLength-2) / 2;
                let u16_words = [];
                for (let i=0; i < len; i++) {
                    u16_words.push(result.data.getUint16(2+i*2, true));
                }
                if (langID == 0) {
                    // Return the langID array
                    return u16_words;
                } else {
                    // Decode from UCS-2 into a string
                    return String.fromCharCode.apply(String, u16_words);
                }
            }
        }
        
        throw `Failed to read string descriptor ${index}: ${result.status}`;
    };

    async readInterfaceNames() {
        const DT_INTERFACE = 4;

        let configs:any = {};
        let allStringIndices = new Set();
        for (let configIndex=0; configIndex < this.device_.configurations.length; configIndex++) {
            const rawConfig = await this.readConfigurationDescriptor(configIndex);
            let configDesc = DFU.parseConfigurationDescriptor(rawConfig);
            let configValue = configDesc.bConfigurationValue;
            configs[configValue] = {};

            // Retrieve string indices for interface names
            for (let desc of configDesc.descriptors) {
                if (desc.bDescriptorType == DT_INTERFACE) {
                    if (!(desc.bInterfaceNumber in configs[configValue])) {
                        configs[configValue][desc.bInterfaceNumber] = {};
                    }
                    configs[configValue][desc.bInterfaceNumber][desc.bAlternateSetting] = desc.iInterface;
                    if (desc.iInterface > 0) {
                        allStringIndices.add(desc.iInterface);
                    }
                }
            }
        }

        let strings:any = {};
        // Retrieve interface name strings
        for (let index of allStringIndices) {
            try {
                strings[index as number] = await this.readStringDescriptor(index, 0x0409);
            } catch (error) {
                console.log(error);
                strings[index as number] = null;
            }
        }

        for (let configValue in configs) {
            for (let intfNumber in configs[configValue]) {
                for (let alt in configs[configValue][intfNumber]) {
                    const iIndex = configs[configValue][intfNumber][alt];
                    configs[configValue][intfNumber][alt] = strings[iIndex];
                }
            }
        }

        return configs;
    };

    

    readConfigurationDescriptor(index:any) {
        const GET_DESCRIPTOR = 0x06;
        const DT_CONFIGURATION = 0x02;
        const wValue = ((DT_CONFIGURATION << 8) | index);

        return this.device_.controlTransferIn({
            "requestType": "standard",
            "recipient": "device",
            "request": GET_DESCRIPTOR,
            "value": wValue,
            "index": 0
        }, 4).then(
            (result:any) => {
                if (result.status == "ok") {
                    // Read out length of the configuration descriptor
                    let wLength = result.data.getUint16(2, true);
                    return this.device_.controlTransferIn({
                        "requestType": "standard",
                        "recipient": "device",
                        "request": GET_DESCRIPTOR,
                        "value": wValue,
                        "index": 0
                    }, wLength);
                } else {
                    return Promise.reject(result.status);
                }
            }
        ).then(
            (result:any) => {
                if (result.status == "ok") {
                    return Promise.resolve(result.data);
                } else {
                    return Promise.reject(result.status);
                }
            }
        );
    };

    requestOut(bRequest:any, data:any = undefined, wValue=0) {
        return this.device_.controlTransferOut({
            "requestType": "class",
            "recipient": "interface",
            "request": bRequest,
            "value": wValue,
            "index": this.intfNumber
        }, data).then(
            (result:any) => {
                if (result.status == "ok") {
                    return Promise.resolve(result.bytesWritten);
                } else {
                    return Promise.reject(result.status);
                }
            },
            (error:any) => {
                return Promise.reject("ControlTransferOut failed: " + error);
            }
        );
    };

    requestIn(bRequest:any, wLength:any, wValue=0) {
        return this.device_.controlTransferIn({
            "requestType": "class",
            "recipient": "interface",
            "request": bRequest,
            "value": wValue,
            "index": this.intfNumber
        }, wLength).then(
            (result:any) => {
                if (result.status == "ok") {
                    return Promise.resolve(result.data);
                } else {
                    return Promise.reject(result.status);
                }
            },
            (error:any) => {
                return Promise.reject("ControlTransferIn failed: " + error);
            }
        );
    };

    detach() {
        return this.requestOut(DFU.DETACH, undefined, 1000);
    }

    async waitDisconnected(timeout:number) {
        let device:any = this;
        let usbDevice = this.device_;
        return new Promise(function(resolve, reject) {
            let timeoutID:any;
            if (timeout > 0) {
                function onTimeout() {
                    navigator.usb.removeEventListener("disconnect", onDisconnect);
                    if (device.disconnected !== true) {
                        reject("Disconnect timeout expired");
                    }
                }
                timeoutID = setTimeout(reject, timeout);
            }

            function onDisconnect(event:any) {
                if (event.device === usbDevice) {
                    if (timeout > 0) {
                        clearTimeout(timeoutID);
                    }
                    device.disconnected = true;
                    navigator.usb.removeEventListener("disconnect", onDisconnect);
                    event.stopPropagation();
                    resolve(device);
                }
            }

            navigator.usb.addEventListener("disconnect", onDisconnect);
        });
    };

    download(data:any, blockNum:any) {
        return this.requestOut(DFU.DNLOAD, data, blockNum);
    };


    upload(length:number, blockNum:any) {
        return this.requestIn(DFU.UPLOAD, length, blockNum)
    };

    clearStatus() {
        return this.requestOut(DFU.CLRSTATUS);
    };

   
    getStatus() {
        return this.requestIn(DFU.GETSTATUS, 6).then(
            (data:any) =>
                Promise.resolve({
                    "status": data.getUint8(0),
                    "pollTimeout": data.getUint32(1, true) & 0xFFFFFF,
                    "state": data.getUint8(4)
                }),
            (error:any) =>
                Promise.reject("DFU GETSTATUS failed: " + error)
        );
    };

    getState () {
        return this.requestIn(DFU.GETSTATE, 1).then(
            (data:any) => Promise.resolve(data.getUint8(0)),
            (error:any) => Promise.reject("DFU GETSTATE failed: " + error)
        );
    };

    abort() {
        return this.requestOut(DFU.ABORT);
    };

    async abortToIdle() {
        await this.abort();
        let state = await this.getState();
        if (state == DFU.dfuERROR) {
            await this.clearStatus();
            state = await this.getState();
        }
        if (state != DFU.dfuIDLE) {
            throw "Failed to return to idle state after abort: state " + state.state;
        }
    };

    async do_upload(xfer_size:number, max_size=Infinity, first_block=0) {
        let transaction = first_block;
        let blocks = [];
        let bytes_read = 0;

        this.logInfo("Copying data from DFU device to browser");
        // Initialize progress to 0
        this.logProgress(0, max_size);

        let result;
        let bytes_to_read;
        do {
            bytes_to_read = Math.min(xfer_size, max_size - bytes_read);
            result = await this.upload(bytes_to_read, transaction++);
            this.logDebug("Read " + result.byteLength + " bytes");
            if (result.byteLength > 0) {
                blocks.push(result);
                bytes_read += result.byteLength;
            }
            if (Number.isFinite(max_size)) {
                this.logProgress(bytes_read, max_size);
            } else {
                this.logProgress(bytes_read,max_size);
            }
        } while ((bytes_read < max_size) && (result.byteLength == bytes_to_read));

        if (bytes_read == max_size) {
            await this.abortToIdle();
        }

        this.logInfo(`Read ${bytes_read} bytes`);

        return new Blob(blocks, { type: "application/octet-stream" });
    };

    async poll_until(state_predicate:any) {
        let dfu_status = await this.getStatus();

        let device = this;
        function async_sleep(duration_ms:number) {
            return new Promise(function(resolve, reject) {
                device.logDebug("Sleeping for " + duration_ms + "ms");
                setTimeout(resolve, duration_ms);
            });
        }
        
        while (!state_predicate(dfu_status.state) && dfu_status.state != DFU.dfuERROR) {
            await async_sleep(dfu_status.pollTimeout);
            dfu_status = await this.getStatus();
        }

        return dfu_status;
    };

    poll_until_idle(idle_state:any) {
        return this.poll_until((state:any) => (state == idle_state));
    };

    async do_download(xfer_size:number, data:any, manifestationTolerant:any) {
        let bytes_sent = 0;
        let expected_size = data.byteLength;
        let transaction = 0;

        this.logInfo("Copying data from browser to DFU device");

        // Initialize progress to 0
        this.logProgress(bytes_sent, expected_size);

        while (bytes_sent < expected_size) {
            const bytes_left = expected_size - bytes_sent;
            const chunk_size = Math.min(bytes_left, xfer_size);

            let bytes_written = 0;
            let dfu_status;
            try {
                bytes_written = await this.download(data.slice(bytes_sent, bytes_sent+chunk_size), transaction++);
                this.logDebug("Sent " + bytes_written + " bytes");
                dfu_status = await this.poll_until_idle(DFU.dfuDNLOAD_IDLE);
            } catch (error) {
                throw "Error during DFU download: " + error;
            }

            if (dfu_status.status != DFU.STATUS_OK) {
                throw `DFU DOWNLOAD failed state=${dfu_status.state}, status=${dfu_status.status}`;
            }

            this.logDebug("Wrote " + bytes_written + " bytes");
            bytes_sent += bytes_written;

            this.logProgress(bytes_sent, expected_size);
        }

        this.logDebug("Sending empty block");
        try {
            await this.download(new ArrayBuffer(0), transaction++);
        } catch (error) {
            throw "Error during final DFU download: " + error;
        }

        this.logInfo("Wrote " + bytes_sent + " bytes");
        this.logInfo("Manifesting new firmware");

        if (manifestationTolerant) {
            // Transition to MANIFEST_SYNC state
            let dfu_status;
            try {
                // Wait until it returns to idle.
                // If it's not really manifestation tolerant, it might transition to MANIFEST_WAIT_RESET
                dfu_status = await this.poll_until((state:any) => (state == DFU.dfuIDLE || state == DFU.dfuMANIFEST_WAIT_RESET));
                if (dfu_status.state == DFU.dfuMANIFEST_WAIT_RESET) {
                    this.logDebug("Device transitioned to MANIFEST_WAIT_RESET even though it is manifestation tolerant");
                }
                if (dfu_status.status != DFU.STATUS_OK) {
                    throw `DFU MANIFEST failed state=${dfu_status.state}, status=${dfu_status.status}`;
                }
            } catch (error:any) {
                if (error.endsWith("ControlTransferIn failed: NotFoundError: Device unavailable.") ||
                    error.endsWith("ControlTransferIn failed: NotFoundError: The device was disconnected.")) {
                    this.logWarning("Unable to poll final manifestation status");
                } else {
                    throw "Error during DFU manifest: " + error;
                }
            }
        } else {
            // Try polling once to initiate manifestation
            try {
                let final_status = await this.getStatus();
                this.logDebug(`Final DFU status: state=${final_status.state}, status=${final_status.status}`);
            } catch (error) {
                this.logDebug("Manifest GET_STATUS poll error: " + error);
            }
        }

        // Reset to exit MANIFEST_WAIT_RESET
        try {
            await this.device_.reset();
        } catch (error) {
            if (error == "NetworkError: Unable to reset the device." ||
                error == "NotFoundError: Device unavailable." ||
                error == "NotFoundError: The device was disconnected.") {
                this.logDebug("Ignored reset error");
            } else {
                throw "Error during reset for manifestation: " + error;
            }
        }

        return;
    };
    
    
}

export class DFUDevice extends Device {
    memoryInfo: MemoryInfo | null = null;
    startAddress: number = NaN;

    constructor(device: any, settings: any) {
        super(device, settings);
        if (settings.name) {
            this.memoryInfo = DFU.parseMemoryDescriptor(settings.name);
        }
    }

    async dfuseCommand(command:any, param:any, len:any) {
        if (typeof param === 'undefined' && typeof len === 'undefined') {
            param = 0x00;
            len = 1;
        }

        const commandNames:any = {
            0x00: "GET_COMMANDS",
            0x21: "SET_ADDRESS",
            0x41: "ERASE_SECTOR"
        };

        let payload = new ArrayBuffer(len + 1);
        let view = new DataView(payload);
        view.setUint8(0, command);
        if (len == 1) {
            view.setUint8(1, param);
        } else if (len == 4) {
            view.setUint32(1, param, true);
        } else {
            throw "Don't know how to handle data of len " + len;
        }

        try {
            await this.download(payload, 0);
        } catch (error) {
            throw "Error during special DfuSe command " + commandNames[command] + ":" + error;
        }

        let status = await this.poll_until((state:any) => (state != DFU.dfuDNBUSY));
        if (status.status != DFU.STATUS_OK) {
            throw "Special DfuSe command " + commandNames[command] + " failed";
        }
    };

    getSegment(addr:any) {
        if (!this.memoryInfo || ! this.memoryInfo.segments) {
            throw "No memory map information available";
        }

        for (let segment of this.memoryInfo.segments) {
            if (segment.start <= addr && addr < segment.end) {
                return segment;
            }
        }

        return null;
    };

    getSectorStart(addr:any, segment:any) {
        if (typeof segment === 'undefined') {
            segment = this.getSegment(addr);
        }

        if (!segment) {
            throw `Address ${addr.toString(16)} outside of memory map`;
        }

        const sectorIndex = Math.floor((addr - segment.start)/segment.sectorSize);
        return segment.start + sectorIndex * segment.sectorSize;
    };

    getSectorEnd(addr:any, segment:any) {
        if (typeof segment === 'undefined') {
            segment = this.getSegment(addr);
        }

        if (!segment) {
            throw `Address ${addr.toString(16)} outside of memory map`;
        }

        const sectorIndex = Math.floor((addr - segment.start)/segment.sectorSize);
        return segment.start + (sectorIndex + 1) * segment.sectorSize;
    };

    getFirstWritableSegment() {
        if (!this.memoryInfo || ! this.memoryInfo.segments) {
            throw "No memory map information available";
        }

        for (let segment of this.memoryInfo.segments) {
            if (segment.writable) {
                return segment;
            }
        }

        return null;
    };

    getMaxReadSize(startAddr:any) {
        if (!this.memoryInfo || ! this.memoryInfo.segments) {
            throw "No memory map information available";
        }

        let numBytes = 0;
        for (let segment of this.memoryInfo.segments) {
            if (segment.start <= startAddr && startAddr < segment.end) {
                // Found the first segment the read starts in
                if (segment.readable) {
                    numBytes += segment.end - startAddr;
                } else {
                    return 0;
                }
            } else if (segment.start == startAddr + numBytes) {
                // Include a contiguous segment
                if (segment.readable) {
                    numBytes += (segment.end - segment.start);
                } else {
                    break;
                }
            }
        }

        return numBytes;
    };

    async erase(startAddr:any, length:any) {
        let segment:any = this.getSegment(startAddr);
        let addr = this.getSectorStart(startAddr, segment);
        const endAddr = this.getSectorEnd(startAddr + length - 1,segment);

        let bytesErased = 0;
        const bytesToErase = endAddr - addr;
        if (bytesToErase > 0) {
            this.logProgress(bytesErased, bytesToErase);
        }

        while (addr < endAddr) {
            if (segment.end <= addr) {
                segment = this.getSegment(addr);
            }
            if (!segment.erasable) {
                // Skip over the non-erasable section
                bytesErased = Math.min(bytesErased + segment.end - addr, bytesToErase);
                addr = segment.end;
                this.logProgress(bytesErased, bytesToErase);
                continue;
            }
            const sectorIndex = Math.floor((addr - segment.start)/segment.sectorSize);
            const sectorAddr = segment.start + sectorIndex * segment.sectorSize;
            this.logDebug(`Erasing ${segment.sectorSize}B at 0x${sectorAddr.toString(16)}`);
            try {
                await this.dfuseCommand(ERASE_SECTOR, sectorAddr, 4);
            } catch (error) {
                throw "Error during sector erase: " + error;
            }
          
            addr = sectorAddr + segment.sectorSize;
            bytesErased += segment.sectorSize;
            this.logProgress(bytesErased, bytesToErase);
        }
    };

    override async do_download(xfer_size:any, data:any, manifestationTolerant:any) {
        if (!this.memoryInfo || ! this.memoryInfo.segments) {
            throw "No memory map available";
        }

        this.logInfo("Erasing DFU device memory");
        
        let bytes_sent = 0;
        let expected_size = data.byteLength;

        let startAddress = this.startAddress;
        if (isNaN(startAddress)) {
            startAddress = this.memoryInfo.segments[0].start;
            this.logWarning("Using inferred start address 0x" + startAddress.toString(16));
        } else if (this.getSegment(startAddress) === null) {
            this.logError(`Start address 0x${startAddress.toString(16)} outside of memory map bounds`);
        }

        let size = 65536
        let isMetadata = startAddress != 134963200
         if(isMetadata) {
            let segment:any = this.getSegment(startAddress);
            let addr = this.getSectorStart(startAddress, segment);
            const endAddr = this.getSectorEnd(startAddress + 512 - 1,segment);
            size = endAddr - addr;
            //Step 1: Read the entire segment into a buffer
            let blob:any = await this.do_upload(1024, size);

            let buffer:any = await DFU.readBlobContent(blob);
            //Step 2: Modify the sequence bytes to the segemnt

            buffer = DFU.replaceValuesInArrayBuffer(buffer, data, 2560, 512);
            data = buffer;
            expected_size = size;
        }

        await this.erase(startAddress, isMetadata ? 512 : size);

        this.logInfo("Copying data from browser to DFU device");

        let address = startAddress;
        while (bytes_sent < expected_size) {
            const bytes_left = expected_size - bytes_sent;
            const chunk_size = Math.min(bytes_left, xfer_size);

            let bytes_written = 0;
            let dfu_status;
            try {
                await this.dfuseCommand(SET_ADDRESS, address, 4);
                this.logDebug(`Set address to 0x${address.toString(16)}`);
                bytes_written = await this.download(data.slice(bytes_sent, bytes_sent+chunk_size), 2);
                this.logDebug("Sent " + bytes_written + " bytes");
                dfu_status = await this.poll_until_idle(DFU.dfuDNLOAD_IDLE);
                address += chunk_size;
            } catch (error) {
                throw "Error during DfuSe download: " + error;
            }

            if (dfu_status.status != DFU.STATUS_OK) {
                throw `DFU DOWNLOAD failed state=${dfu_status.state}, status=${dfu_status.status}`;
            }

            this.logDebug("Wrote " + bytes_written + " bytes");
            bytes_sent += bytes_written;

            this.logProgress(bytes_sent, expected_size);
        }
        this.logInfo(`Wrote ${bytes_sent} bytes`);

        this.logInfo("Manifesting new firmware");
        try {
            if(isMetadata) {
                await this.dfuseCommand(DFU.SET_ADDRESS, startAddress, 4);
                await this.download(new ArrayBuffer(0), 0);
            }
        } catch (error) {
            throw "Error during DfuSe manifestation: " + error;
        }

        try {
            if(isMetadata) {
                await this.poll_until((state:any) => (state == DFU.dfuMANIFEST));
            } else {
                let dfu_status = await this.poll_until_idle(DFU.dfuDNLOAD_IDLE);
                if (dfu_status.status != DFU.STATUS_OK) {
                    throw `DFU DOWNLOAD failed state=${dfu_status.state}, status=${dfu_status.status}`;
                }
            }
        } catch (error) {
            this.logError(error);
        }
    }

    override async do_upload(xfer_size:number, max_size:number) {
        let startAddress = this.startAddress;
        if (isNaN(startAddress)) {
            startAddress = this.memoryInfo?.segments[0].start || 0;
            this.logWarning("Using inferred start address 0x" + startAddress.toString(16));
        } else if (this.getSegment(startAddress) === null) {
            this.logWarning(`Start address 0x${startAddress.toString(16)} outside of memory map bounds`);
        }

        this.logInfo(`Reading up to 0x${max_size.toString(16)} bytes starting at 0x${startAddress.toString(16)}`);
        let state = await this.getState();
        if (state != DFU.dfuIDLE) {
            await this.abortToIdle();
        }
        await this.dfuseCommand(SET_ADDRESS, startAddress, 4);
        await this.abortToIdle();

        // DfuSe encodes the read address based on the transfer size,
        // the block number - 2, and the SET_ADDRESS pointer.
        return await super.do_upload.call(this, xfer_size, max_size, 2);
    }
    
}