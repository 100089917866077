import { 
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  forwardRef,
  AfterViewInit
} from '@angular/core';
import { debounceTime, take, takeUntil } from 'rxjs/operators';

import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs/internal/Subject';
import { ObjectUtil } from '../../utils/object-util';

@Component({
  selector: 'app-select-dropdown',
  templateUrl: './select-dropdown.component.html',
  styleUrls: ['./select-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectDropdownComponent),
      multi: true
    }
  ]
})
export class SelectDropdownComponent implements OnInit, OnDestroy, AfterViewInit {
    
  @Input()
  get data(): any[] {
    return this.list;
  }
  set data(val: any[]) {
    this.list = val || [];
    this.isSearching = false;
    this.filterResults();
  }
  @Input()
  get checkPrimary() {
    return this._checkPrimary;
  }
  set checkPrimary(val) {
    this._checkPrimary = val;
  }
  // Whatever name for this (selectedValue) you choose here, use it in the .html file.
  public get selectedValue(): any { return this._value; }
  public set selectedValue(v: any) {
    if (v !== this._value) {
      this._value = v;
      if (!v) {
        this.selectedValues = [];
      }
      this.onChange(v);
    }
  }

  constructor(private changeDetectorRef: ChangeDetectorRef) {
  }
  @Input() formFieldClass!: string;
  /** Label of the search placeholder */
  @Input() placeholderLabel = 'Select';

  /** Label to be shown when no entries are found. Set to null if no message should be shown. */
  @Input() noEntriesFoundLabel = 'No results found';

  @Input() multiSelect = false;
  @Input() remoteFilter = false;
  @Input() ccuSelected = false;
 
  @Input() searchInExistingList?= true;
  @Input() searchingLabel?= 'Searching ...';
  list: Array<any> = [];

  @Input() displayKey = '';
  @Input() valueKey = '';
  @Input() isRequired: boolean = false;
  @Input() disabled: boolean = false;
  @Input() search = true;
  _checkPrimary = false;
  @Input() primaryKey = 'hasPrimary';
  @Input() showClear: boolean = false;
  @Input() showSelectVal: boolean = false;
  @Input() emptyResults = false;
  @Input() heatmap = false;

  @Output() valueChanged = new EventEmitter();

  /** Reference to the search input field */
  @ViewChild('searchSelectInput', { read: ElementRef, static: true }) searchSelectInput!: ElementRef;
  /** Reference to the search input field */
  @ViewChild('selectDropdown', { static: true }) matSelect!: MatSelect;
  @ViewChild('dropDownOptions', { read: ElementRef }) public dropDownOptions!: ElementRef<any>;
  @Output() filterMode: EventEmitter<any> = new EventEmitter();

  private _value!: string;



  /** Reference to the MatSelect options */
  public _options!: QueryList<MatOption>;

  searchUpdate = new Subject<string>();



  /** Whether the backdrop class has been set */
  private overlayClassSet = false;

  /** Event that emits when the current value changes */
  private change = new EventEmitter<string>();

  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();

  /** list filtered by search keyword for multi-selection */
  public filteredList: any = [];

  selectedValues: any = [];
  singleLabel: any = [];
  searchText: string = '';

  isSearching = false;

  onChange = (_: any) => { };
  onTouched = () => { };

  writeValue(value: any): void {
    this.selectedValue = value;

  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  ngOnInit() {
    // set custom panel class
    const panelClass = 'mat-select-search-panel';
    if (this.matSelect.panelClass) {
      if (Array.isArray(this.matSelect.panelClass)) {
        this.matSelect.panelClass.push(panelClass);
      } else if (typeof this.matSelect.panelClass === 'string') {
        this.matSelect.panelClass = [this.matSelect.panelClass, panelClass];
      } else if (typeof this.matSelect.panelClass === 'object') {
        // this.matSelect.panelClass[panelClass] = true;
      }
    } else {
      this.matSelect.panelClass = panelClass;
    }

    // Debounce search.
    this.searchUpdate.pipe(
      takeUntil(this._onDestroy),
      debounceTime(400))
      .subscribe(value => {
        if (this.remoteFilter) {
          this.filterMode.emit({
            searchText: this.searchText
          });
        }

        if (this.searchInExistingList) {
          this.filterResults();
        } else {
          if (this.remoteFilter) {
            this.isSearching = true;
          }
        }

      });

    // when the select dropdown panel is opened or closed
    this.matSelect.openedChange
      .pipe(takeUntil(this._onDestroy))
      .subscribe((opened) => {
        if (opened) {
          // focus the search field when opening
          this.selectAll();
          this._focus();
        } else {
          // clear it when closingtOpto
          this._reset();
         this.selectAll();
          this.valueChanged.emit(this.selectedValues);
        }
      });

    // set the first item active after the options changed
    this.matSelect.openedChange
      .pipe(take(1))
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this._options = this.matSelect.options;
      });

    // detect changes when the input changes
    this.change
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.changeDetectorRef.detectChanges();
      });
  }

  inputChanged(event: any) {
    this.searchUpdate.next(event);

  }

  selectAll() {
    if(this.selectedValues.length == 0 && this.heatmap) {
      this.selectedValues = ObjectUtil.deepCopy(this.list);
      this.selectedValue = this.list.map(op=>this.valueKey?op[this.valueKey]:op);
    }
  }
  
  ngAfterViewInit() {
    this.setOverlayClass();
  }

  filterResults() {
    this.filteredList = this.list;
    if (!this.searchInExistingList) return;
    
    // get the search keyword
    let search = this.searchText;
    search = search.toLowerCase();
    // filter the banks

    if (search.length) {
      this.filteredList = this.list.filter(item => {
        if (this.displayKey) {
          return item[this.displayKey].toLowerCase().includes(search);
        } else {
          return item.toString().toLowerCase().includes(search);
        }
      });
    }
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
    this.searchText = '';
  }

  setOption(optionChangedEvent: any) {
    if (this.matSelect.panelOpen) {
      this.selectedValue = optionChangedEvent.value;

      if (typeof this.selectedValue == 'string') {
        this.selectedValues = this.list.filter((selectedRow) => selectedRow[this.valueKey] == this.selectedValue);
      }

      if (typeof this.selectedValue == 'object') {
        let selectedValue = ObjectUtil.deepClone(this.selectedValue);
        this.list.forEach((item) => {
          if (this.selectedValue.length) {
            selectedValue.forEach((selectedItem: any) => {
              if (selectedItem == item[this.valueKey]) {
                this.selectedValues.push(item);
              }
            })
          }
        })
      }
      // filter the values for the selected value;
      this.valueChanged.emit(this.selectedValues);
    }
  }

  /**
   * Handles the key down event with MatSelect.
   * Allows e.g. selecting with enter key, navigation with arrow keys, etc.
   * @param KeyboardEvent event
   */
   _handleKeydown(event: any) {
    // tslint:disable-next-line: deprecation
    if (event.keyCode === 9) { 
      event.preventDefault();
      event.stopPropagation();
    }
    if (event.keyCode === 32) {
        // do not propagate spaces to MatSelect, as this would select the currently active option
        event.stopPropagation();
    }
    if (event.keyCode === 38) {
        this.dropDownOptions.nativeElement.scrollTo({ top: (this.dropDownOptions.nativeElement.scrollTop - 26), behavior: 'auto' });
    }
    else if (event.keyCode === 40) {
        this.dropDownOptions.nativeElement.scrollTo({ top: (this.dropDownOptions.nativeElement.scrollTop + 26), behavior: 'auto' });
    }
}

  openDropdown() {
    this.matSelect.open();
  }


  /**
   * Focuses the search input field
   */
  public _focus() {
    if (!this.searchSelectInput) {
      return;
    }
    // save and restore scrollTop of panel, since it will be reset by focus()
    // note: this is hacky
    const panel = this.matSelect.panel.nativeElement;
    const scrollTop = panel.scrollTop;

    // focus
    this.searchSelectInput.nativeElement.focus();

    panel.scrollTop = scrollTop;

    this.filterResults();
  }

  /**
   * Resets the current search value
   * @param boolean focus whether to focus after resetting
   */
  public _reset(focus?: boolean) {
    this.searchText = '';
    this.filterResults();
    if (this.remoteFilter) {
      this.filterMode.emit({
        searchText: this.searchText
      });
    }

    if (focus) {
      this._focus();
    }
  }

  /**
   * Sets the overlay class  to correct offsetY
   * so that the selected option is at the position of the select box when opening
   */
  private setOverlayClass() {
    if (this.overlayClassSet) {
      return;
    }
    
    const overlayClass = 'cdk-overlay-pane-select-search';
    // note: this is hacky, but currently there is no better way to do this
    if (!this.searchSelectInput) { return; }
    this.searchSelectInput.nativeElement?.parentElement?.parentElement
      ?.parentElement?.parentElement?.parentElement?.classList.add(overlayClass);

    this.overlayClassSet = true;
  }

  checkSelection(item: any) {
    if (!item) { return false; }
    if (this.displayKey) {
      const index = this.filteredList.findIndex((val: any) => {
        return val[this.displayKey] === item[this.displayKey];
      });
      return index === -1;
    }
    return this.list.indexOf(item) === -1;
  }

  toggleSelection(item: any) {
    if (this.multiSelect) {
      const index = this.selectedValues.findIndex((ele: any) => {
        return this.valueKey ? ele[this.valueKey] === item[this.valueKey] : ele === item;
      });
      if (index === -1) {
        this.selectedValues.push(item);
        if (this.selectedValues[0][this.valueKey] != 'all' && this.heatmap) {
          this.selectedValues.splice(0, 0, this.data[0]);
          if (!this.selectedValue.includes("all")) {
            this.selectedValue.push('all');
          }
        }
      } else {
        this.selectedValues.splice(index, 1);
      }
    } else {
      this.selectedValues = [item];
    }
  }

  clearSelection(event: any, type: any) {
    // Prevent the dropown drop appearing when the clear button is clicked
    if(type){
      event.stopPropagation();
    }

    this.selectedValue = undefined;
    this.selectedValues = [];
    this.valueChanged.emit(this.selectedValues);
  }

}
