<div class="legends-container">
    <div class="legend-item info">
        <i class="fas fa-info log-level-indicator info"></i>
        <p class="legend-label">Info</p>
    </div>
    <div class="legend-item warn">
        <i class="fas fa-info log-level-indicator warn"></i>
        <p class="legend-label">Warn</p>
    </div>
    <div class="legend-item trace">
        <i class="fas fa-info log-level-indicator trace"></i>
        <p class="legend-label">Trace</p>
    </div>
    <div class="legend-item error">
        <i class="fas fa-info log-level-indicator error"></i>
        <p class="legend-label">Error</p>
    </div>
</div>