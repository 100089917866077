<ng-container>
    <div class="header">
        <div class="header-content-container">
            <div class="header-main-content">
                <h1 mat-dialog-title class="text-capitalize sg-modal-title" [ngClass]="subTitle ? 'm-b-10' : 'm-b-2'" *ngIf="title">{{ title }}</h1>
                <div class="spacer"></div>
                <div class="close-button-container">
                  <button mat-icon-button mat-dialog-close class="close-button">
                    <i class="fas fa-times"></i>
                  </button>
                </div>
            </div>
            <h4 mat-dialog-sub-title class="text-capitalize sg-modal-sub-title m-b-10" *ngIf="subTitle">{{ subTitle }}</h4>
        </div>
    </div>
</ng-container>
<div mat-dialog-content>
    <div class="row align-items-center">
        <div class="p-l-0 pre-wrap">
            <div class="scrollable-content">
                <div class="updates-container" *ngIf="htmlContent.length > 0">
                    <div class="updated-item" *ngFor="let modifiedData of htmlContent">
                        <div class="label"><span class="key">Sequence: </span> <span class="value">{{modifiedData.sequence}}</span></div>
                        <div class="data-list-container"
                            *ngIf="modifiedData.updatedFunctions && modifiedData.updatedFunctions.length > 0">
                            <div class="label">Updated functions:</div>
                            <ul class="data-list">
                                <li class="data-list-item" *ngFor="let functionName of modifiedData.updatedFunctions">{{ functionName }}</li>
                            </ul>
                        </div>
                        <div class="data-list-container" *ngIf="modifiedData.deletedFunctions && modifiedData.deletedFunctions.length > 0">
                            Deleted functions
                            <ul class="data-list">
                                <li class="data-list-item" *ngFor="let functionName of modifiedData.deletedFunctions">{{ functionName }}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions  [align]="'end'">
    <ng-container *ngIf="type == 'dialog'">
        <button class="text-uppercase btn color-primary" (click)="onNoClick()">Ok</button>
    </ng-container>
</div>