export const DEFAULT_TEMPLATE:any = {
    "blocks": {
        "languageVersion": 0,
        "blocks": [
            {
                "type": "procedures_defnoreturn",
                "id": "7Yh?vRk$^+cpR9ETLuab",
                "x": -2111,
                "y": -484,
                "extraState": {
                    "params": [
                        {
                            "name": "universal_input_number",
                            "id": "5F`WY$BZJ;/mtIUiYew!"
                        }
                    ]
                },
                "icons": {
                    "comment": {
                        "text": "Describe this function...",
                        "pinned": false,
                        "height": 80,
                        "width": 160
                    }
                },
                "fields": {
                    "NAME": "ConfigureUniversalInputFromModbusRegisterMapping"
                },
                "inputs": {
                    "STACK": {
                        "block": {
                            "type": "conn_mod_set_universal_input_mode",
                            "id": "]dS3[lod0,]QAvxwF_YA",
                            "inputs": {
                                "uin_number": {
                                    "block": {
                                        "type": "variables_get",
                                        "id": ",1zFv,HXIS[QY}flc:Yx",
                                        "fields": {
                                            "VAR": {
                                                "id": "5F`WY$BZJ;/mtIUiYew!"
                                            }
                                        }
                                    }
                                },
                                "uin_mode": {
                                    "block": {
                                        "type": "universal_input_get_mode_from_standard_sensor_type",
                                        "id": "xM^4Djl#+GV1c|45tnMn",
                                        "inputs": {
                                            "sensor_type_index": {
                                                "block": {
                                                    "type": "conn_mod_read_universal_input_mapping_modbus_register",
                                                    "id": "u??m$yzO7DuT7GdzUF$w",
                                                    "inputs": {
                                                        "uin_number": {
                                                            "block": {
                                                                "type": "variables_get",
                                                                "id": ".4:H[ERGt5/De!PJMrdw",
                                                                "fields": {
                                                                    "VAR": {
                                                                        "id": "5F`WY$BZJ;/mtIUiYew!"
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            },
            {
                "type": "procedures_defnoreturn",
                "id": "NpOgmZCa8@y#*_j=RFR`",
                "x": -2106,
                "y": -355,
                "extraState": {
                    "params": [
                        {
                            "name": "universal_input_number",
                            "id": "5F`WY$BZJ;/mtIUiYew!"
                        }
                    ]
                },
                "icons": {
                    "comment": {
                        "text": "Describe this function...",
                        "pinned": false,
                        "height": 80,
                        "width": 160
                    }
                },
                "fields": {
                    "NAME": "ConfigureHomeScreenInputFromModbusRegisterMapping"
                },
                "inputs": {
                    "STACK": {
                        "block": {
                            "type": "conn_mod_configure_home_screen_input",
                            "id": "lO%I.S06bf(H:H{UeIbc",
                            "inputs": {
                                "uin_number": {
                                    "block": {
                                        "type": "variables_get",
                                        "id": "KWBo25Wn#d=P_T:D#G:!",
                                        "fields": {
                                            "VAR": {
                                                "id": "5F`WY$BZJ;/mtIUiYew!"
                                            }
                                        }
                                    }
                                },
                                "input_name": {
                                    "block": {
                                        "type": "universal_input_get_name_from_standard_sensor_type",
                                        "id": "G`slgLAC0(mP[#-U3Oxw",
                                        "inputs": {
                                            "sensor_type_index": {
                                                "block": {
                                                    "type": "conn_mod_read_universal_input_mapping_modbus_register",
                                                    "id": "l/k3*2pM1+JCcFy/23;Q",
                                                    "inputs": {
                                                        "uin_number": {
                                                            "block": {
                                                                "type": "variables_get",
                                                                "id": "^r?or?l.X#A8PDTJHP[#",
                                                                "fields": {
                                                                    "VAR": {
                                                                        "id": "5F`WY$BZJ;/mtIUiYew!"
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                },
                                "input_units": {
                                    "block": {
                                        "type": "universal_input_get_units_from_standard_sensor_type",
                                        "id": "Z`Pq.Y!!`sH3$7tAI0y8",
                                        "inputs": {
                                            "sensor_type_index": {
                                                "block": {
                                                    "type": "conn_mod_read_universal_input_mapping_modbus_register",
                                                    "id": "jCTt=s8@AYZT)Hq#o_pF",
                                                    "inputs": {
                                                        "uin_number": {
                                                            "block": {
                                                                "type": "variables_get",
                                                                "id": "J~~Ud]X@1=/6gPkAYU`G",
                                                                "fields": {
                                                                    "VAR": {
                                                                        "id": "5F`WY$BZJ;/mtIUiYew!"
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                },
                                "input_val": {
                                    "block": {
                                        "type": "procedures_callreturn",
                                        "id": "%@/~agHB.cS.w|vY-YL6",
                                        "extraState": {
                                            "name": "GetUniversalInputMappedValueUsingModbusRegisterMapping",
                                            "params": [
                                                "universal_input_number"
                                            ]
                                        },
                                        "inputs": {
                                            "ARG0": {
                                                "block": {
                                                    "type": "variables_get",
                                                    "id": "-bSOpwl|V@R?uA0WpK3U",
                                                    "fields": {
                                                        "VAR": {
                                                            "id": "5F`WY$BZJ;/mtIUiYew!"
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            },
            {
                "type": "procedures_defnoreturn",
                "id": "q}.culMW`v`BjmUf(`O@",
                "x": -2101,
                "y": -227,
                "extraState": {
                    "params": [
                        {
                            "name": "universal_input_number",
                            "id": "5F`WY$BZJ;/mtIUiYew!"
                        }
                    ]
                },
                "icons": {
                    "comment": {
                        "text": "Describe this function...",
                        "pinned": false,
                        "height": 80,
                        "width": 160
                    }
                },
                "fields": {
                    "NAME": "UpdateHomeScreenInputFromModbusRegisterMapping"
                },
                "inputs": {
                    "STACK": {
                        "block": {
                            "type": "conn_mod_update_home_screen_input",
                            "id": "r^q!GD5J_+wAzE8AW_7x",
                            "inputs": {
                                "uin_number": {
                                    "block": {
                                        "type": "variables_get",
                                        "id": "#tlYPlhL{M`z33{u/Ev`",
                                        "fields": {
                                            "VAR": {
                                                "id": "5F`WY$BZJ;/mtIUiYew!"
                                            }
                                        }
                                    }
                                },
                                "input_val": {
                                    "block": {
                                        "type": "procedures_callreturn",
                                        "id": "6*qP$bYq`,kPLo,fD#in",
                                        "extraState": {
                                            "name": "GetUniversalInputMappedValueUsingModbusRegisterMapping",
                                            "params": [
                                                "universal_input_number"
                                            ]
                                        },
                                        "inputs": {
                                            "ARG0": {
                                                "block": {
                                                    "type": "variables_get",
                                                    "id": "?s.L7j2OPE[VBltNuIRf",
                                                    "fields": {
                                                        "VAR": {
                                                            "id": "5F`WY$BZJ;/mtIUiYew!"
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            },
            {
                "type": "procedures_defreturn",
                "id": "EzX0x6sTN(.q/E#WC9,[",
                "x": -2112,
                "y": -579,
                "extraState": {
                    "params": [
                        {
                            "name": "universal_input_number",
                            "id": "5F`WY$BZJ;/mtIUiYew!"
                        }
                    ],
                    "hasStatements": false
                },
                "icons": {
                    "comment": {
                        "text": "Describe this function...",
                        "pinned": false,
                        "height": 80,
                        "width": 160
                    }
                },
                "fields": {
                    "NAME": "GetUniversalInputMappedValueUsingModbusRegisterMapping"
                },
                "inputs": {
                    "RETURN": {
                        "block": {
                            "type": "universal_input_convert_value_from_standard_sensor_type",
                            "id": "agBIITziC!88sGYOD-(J",
                            "inputs": {
                                "uin_value": {
                                    "block": {
                                        "type": "conn_mod_get_universal_input_value",
                                        "id": "*sHPp.FhF=YabNGhzfWh",
                                        "inputs": {
                                            "uin_number": {
                                                "block": {
                                                    "type": "variables_get",
                                                    "id": "9FV=u%5QJo~iUIxeIm[c",
                                                    "fields": {
                                                        "VAR": {
                                                            "id": "5F`WY$BZJ;/mtIUiYew!"
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                },
                                "sensor_type_index": {
                                    "block": {
                                        "type": "conn_mod_read_universal_input_mapping_modbus_register",
                                        "id": "NETF%[!AiD(@i7X4;M6;",
                                        "inputs": {
                                            "uin_number": {
                                                "block": {
                                                    "type": "variables_get",
                                                    "id": "WEBMW#GcK00fcy~zw8C$",
                                                    "fields": {
                                                        "VAR": {
                                                            "id": "5F`WY$BZJ;/mtIUiYew!"
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            },
            {
                "type": "procedures_defnoreturn",
                "id": "L]d;R1U.]_pDR(m-VBm;",
                "x": -2099,
                "y": -105,
                "extraState": {
                    "params": [
                        {
                            "name": "universal_input_number",
                            "id": "5F`WY$BZJ;/mtIUiYew!"
                        }
                    ]
                },
                "icons": {
                    "comment": {
                        "text": "Describe this function...",
                        "pinned": false,
                        "height": 80,
                        "width": 160
                    }
                },
                "fields": {
                    "NAME": "PrintUniversalInputMappedValueToLog"
                },
                "inputs": {
                    "STACK": {
                        "block": {
                            "type": "print_string",
                            "id": "vqOoxe]t`MS4c~-F64jL",
                            "inputs": {
                                "string_to_print": {
                                    "block": {
                                        "type": "text_join",
                                        "id": "$J2=]Ve{/gDRaq?/b5r8",
                                        "extraState": {
                                            "itemCount": 4
                                        },
                                        "inputs": {
                                            "ADD0": {
                                                "block": {
                                                    "type": "text",
                                                    "id": "Yys541BzVWHlO;Ad1+v5",
                                                    "fields": {
                                                        "TEXT": "Input "
                                                    }
                                                }
                                            },
                                            "ADD1": {
                                                "block": {
                                                    "type": "variables_get",
                                                    "id": "N-0O-vf0OR/|{z5IJq8=",
                                                    "fields": {
                                                        "VAR": {
                                                            "id": "5F`WY$BZJ;/mtIUiYew!"
                                                        }
                                                    }
                                                }
                                            },
                                            "ADD2": {
                                                "block": {
                                                    "type": "text",
                                                    "id": "vv|c#H_u+V.)S:Ym/erU",
                                                    "fields": {
                                                        "TEXT": ": "
                                                    }
                                                }
                                            },
                                            "ADD3": {
                                                "block": {
                                                    "type": "procedures_callreturn",
                                                    "id": "r=#-Ef6_jqE2lQo1UtWJ",
                                                    "extraState": {
                                                        "name": "GetUniversalInputMappedValueUsingModbusRegisterMapping",
                                                        "params": [
                                                            "universal_input_number"
                                                        ]
                                                    },
                                                    "inputs": {
                                                        "ARG0": {
                                                            "block": {
                                                                "type": "variables_get",
                                                                "id": "q9.)0Ej3k:b~y$%BFs@9",
                                                                "fields": {
                                                                    "VAR": {
                                                                        "id": "5F`WY$BZJ;/mtIUiYew!"
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            },
            {
                "type": "sequence_run_startup",
                "id": "PO=SO3ki);NipVBkoylM",
                "x": -2241,
                "y": 171,
                "inputs": {
                    "sequence": {
                        "block": {
                            "type": "procedures_callnoreturn",
                            "id": "@nhI/2uHY~n#+9mN_Ul!",
                            "extraState": {
                                "name": "ConfigureUniversalInputFromModbusRegisterMapping",
                                "params": [
                                    "universal_input_number"
                                ]
                            },
                            "inputs": {
                                "ARG0": {
                                    "block": {
                                        "type": "conn_mod_universal_input_number",
                                        "id": "X/mw$T!(:zenfuRU|tbD",
                                        "fields": {
                                            "channel_number": "0"
                                        }
                                    }
                                }
                            },
                            "next": {
                                "block": {
                                    "type": "procedures_callnoreturn",
                                    "id": "vAK~2mOfLk:@qy+Tt|X1",
                                    "extraState": {
                                        "name": "ConfigureUniversalInputFromModbusRegisterMapping",
                                        "params": [
                                            "universal_input_number"
                                        ]
                                    },
                                    "inputs": {
                                        "ARG0": {
                                            "block": {
                                                "type": "conn_mod_universal_input_number",
                                                "id": "U8Y6GGms}rPpuXb4aZPn",
                                                "fields": {
                                                    "channel_number": "1"
                                                }
                                            }
                                        }
                                    },
                                    "next": {
                                        "block": {
                                            "type": "procedures_callnoreturn",
                                            "id": ";Lt?a|#4,f7eWt,DA1R2",
                                            "extraState": {
                                                "name": "ConfigureUniversalInputFromModbusRegisterMapping",
                                                "params": [
                                                    "universal_input_number"
                                                ]
                                            },
                                            "inputs": {
                                                "ARG0": {
                                                    "block": {
                                                        "type": "conn_mod_universal_input_number",
                                                        "id": "Th2c*?C|OLKH;6FzoAbP",
                                                        "fields": {
                                                            "channel_number": "2"
                                                        }
                                                    }
                                                }
                                            },
                                            "next": {
                                                "block": {
                                                    "type": "procedures_callnoreturn",
                                                    "id": "w!`ipg3V.9P5dvyYP@x%",
                                                    "extraState": {
                                                        "name": "ConfigureUniversalInputFromModbusRegisterMapping",
                                                        "params": [
                                                            "universal_input_number"
                                                        ]
                                                    },
                                                    "inputs": {
                                                        "ARG0": {
                                                            "block": {
                                                                "type": "conn_mod_universal_input_number",
                                                                "id": "/m#tzg:`QV[lrcTM~i1+",
                                                                "fields": {
                                                                    "channel_number": "3"
                                                                }
                                                            }
                                                        }
                                                    },
                                                    "next": {
                                                        "block": {
                                                            "type": "procedures_callnoreturn",
                                                            "id": "V|.;RCrQ~bGn!}ytRVM6",
                                                            "extraState": {
                                                                "name": "ConfigureUniversalInputFromModbusRegisterMapping",
                                                                "params": [
                                                                    "universal_input_number"
                                                                ]
                                                            },
                                                            "inputs": {
                                                                "ARG0": {
                                                                    "block": {
                                                                        "type": "conn_mod_universal_input_number",
                                                                        "id": "@cm#+^eMK:uT?IGiG3}+",
                                                                        "fields": {
                                                                            "channel_number": "4"
                                                                        }
                                                                    }
                                                                }
                                                            },
                                                            "next": {
                                                                "block": {
                                                                    "type": "procedures_callnoreturn",
                                                                    "id": "V8Kz%t,qzO;y+J1J:AFZ",
                                                                    "extraState": {
                                                                        "name": "ConfigureUniversalInputFromModbusRegisterMapping",
                                                                        "params": [
                                                                            "universal_input_number"
                                                                        ]
                                                                    },
                                                                    "inputs": {
                                                                        "ARG0": {
                                                                            "block": {
                                                                                "type": "conn_mod_universal_input_number",
                                                                                "id": "]fIyX`;vDshOkBnah0J%",
                                                                                "fields": {
                                                                                    "channel_number": "5"
                                                                                }
                                                                            }
                                                                        }
                                                                    },
                                                                    "next": {
                                                                        "block": {
                                                                            "type": "procedures_callnoreturn",
                                                                            "id": "3ZXmdsr~x-1nv{?Kj).g",
                                                                            "extraState": {
                                                                                "name": "ConfigureUniversalInputFromModbusRegisterMapping",
                                                                                "params": [
                                                                                    "universal_input_number"
                                                                                ]
                                                                            },
                                                                            "inputs": {
                                                                                "ARG0": {
                                                                                    "block": {
                                                                                        "type": "conn_mod_universal_input_number",
                                                                                        "id": "Vu$w`34TyLkk9(7FwuDP",
                                                                                        "fields": {
                                                                                            "channel_number": "6"
                                                                                        }
                                                                                    }
                                                                                }
                                                                            },
                                                                            "next": {
                                                                                "block": {
                                                                                    "type": "procedures_callnoreturn",
                                                                                    "id": "6oxvK2].k#tE,x7DUn5`",
                                                                                    "extraState": {
                                                                                        "name": "ConfigureUniversalInputFromModbusRegisterMapping",
                                                                                        "params": [
                                                                                            "universal_input_number"
                                                                                        ]
                                                                                    },
                                                                                    "inputs": {
                                                                                        "ARG0": {
                                                                                            "block": {
                                                                                                "type": "conn_mod_universal_input_number",
                                                                                                "id": "BG}`h~aNe:1@MS10V`F2",
                                                                                                "fields": {
                                                                                                    "channel_number": "7"
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    },
                                                                                    "next": {
                                                                                        "block": {
                                                                                            "type": "procedures_callnoreturn",
                                                                                            "id": "J7n/Gt-g{C+cjGx7.r3[",
                                                                                            "extraState": {
                                                                                                "name": "ConfigureHomeScreenInputFromModbusRegisterMapping",
                                                                                                "params": [
                                                                                                    "universal_input_number"
                                                                                                ]
                                                                                            },
                                                                                            "inputs": {
                                                                                                "ARG0": {
                                                                                                    "block": {
                                                                                                        "type": "conn_mod_universal_input_number",
                                                                                                        "id": "7kVJ9;!1-Pm(}xG5zCO_",
                                                                                                        "fields": {
                                                                                                            "channel_number": "0"
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            },
                                                                                            "next": {
                                                                                                "block": {
                                                                                                    "type": "procedures_callnoreturn",
                                                                                                    "id": "2(`Bw0!^IhP;3dm?l:Kn",
                                                                                                    "extraState": {
                                                                                                        "name": "ConfigureHomeScreenInputFromModbusRegisterMapping",
                                                                                                        "params": [
                                                                                                            "universal_input_number"
                                                                                                        ]
                                                                                                    },
                                                                                                    "inputs": {
                                                                                                        "ARG0": {
                                                                                                            "block": {
                                                                                                                "type": "conn_mod_universal_input_number",
                                                                                                                "id": "Ca!I,rRkub_*oM?r:p_`",
                                                                                                                "fields": {
                                                                                                                    "channel_number": "1"
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    },
                                                                                                    "next": {
                                                                                                        "block": {
                                                                                                            "type": "procedures_callnoreturn",
                                                                                                            "id": "IB4[K!4=YI_y!r,DD{sS",
                                                                                                            "extraState": {
                                                                                                                "name": "ConfigureHomeScreenInputFromModbusRegisterMapping",
                                                                                                                "params": [
                                                                                                                    "universal_input_number"
                                                                                                                ]
                                                                                                            },
                                                                                                            "inputs": {
                                                                                                                "ARG0": {
                                                                                                                    "block": {
                                                                                                                        "type": "conn_mod_universal_input_number",
                                                                                                                        "id": "1$5MBcSW`1N;_;[!2SO(",
                                                                                                                        "fields": {
                                                                                                                            "channel_number": "2"
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            },
                                                                                                            "next": {
                                                                                                                "block": {
                                                                                                                    "type": "procedures_callnoreturn",
                                                                                                                    "id": "^ttXjb+9tBj|wXw3H*q7",
                                                                                                                    "extraState": {
                                                                                                                        "name": "ConfigureHomeScreenInputFromModbusRegisterMapping",
                                                                                                                        "params": [
                                                                                                                            "universal_input_number"
                                                                                                                        ]
                                                                                                                    },
                                                                                                                    "inputs": {
                                                                                                                        "ARG0": {
                                                                                                                            "block": {
                                                                                                                                "type": "conn_mod_universal_input_number",
                                                                                                                                "id": "k3Rs3t%59[)h?!{jN-60",
                                                                                                                                "fields": {
                                                                                                                                    "channel_number": "3"
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                    },
                                                                                                                    "next": {
                                                                                                                        "block": {
                                                                                                                            "type": "procedures_callnoreturn",
                                                                                                                            "id": "5(CQcBQq9no5,XE|i*Vk",
                                                                                                                            "extraState": {
                                                                                                                                "name": "ConfigureHomeScreenInputFromModbusRegisterMapping",
                                                                                                                                "params": [
                                                                                                                                    "universal_input_number"
                                                                                                                                ]
                                                                                                                            },
                                                                                                                            "inputs": {
                                                                                                                                "ARG0": {
                                                                                                                                    "block": {
                                                                                                                                        "type": "conn_mod_universal_input_number",
                                                                                                                                        "id": "T%d``4!CA|i/tYOFbR:*",
                                                                                                                                        "fields": {
                                                                                                                                            "channel_number": "4"
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            },
                                                                                                                            "next": {
                                                                                                                                "block": {
                                                                                                                                    "type": "procedures_callnoreturn",
                                                                                                                                    "id": "6x[!YCfquvDG7nE#}tB6",
                                                                                                                                    "extraState": {
                                                                                                                                        "name": "ConfigureHomeScreenInputFromModbusRegisterMapping",
                                                                                                                                        "params": [
                                                                                                                                            "universal_input_number"
                                                                                                                                        ]
                                                                                                                                    },
                                                                                                                                    "inputs": {
                                                                                                                                        "ARG0": {
                                                                                                                                            "block": {
                                                                                                                                                "type": "conn_mod_universal_input_number",
                                                                                                                                                "id": "{DoP#+mdqKAhj1:fo:PB",
                                                                                                                                                "fields": {
                                                                                                                                                    "channel_number": "5"
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                    },
                                                                                                                                    "next": {
                                                                                                                                        "block": {
                                                                                                                                            "type": "procedures_callnoreturn",
                                                                                                                                            "id": ";8d*`I:.p}U,=lWag]@~",
                                                                                                                                            "extraState": {
                                                                                                                                                "name": "ConfigureHomeScreenInputFromModbusRegisterMapping",
                                                                                                                                                "params": [
                                                                                                                                                    "universal_input_number"
                                                                                                                                                ]
                                                                                                                                            },
                                                                                                                                            "inputs": {
                                                                                                                                                "ARG0": {
                                                                                                                                                    "block": {
                                                                                                                                                        "type": "conn_mod_universal_input_number",
                                                                                                                                                        "id": "`J.G{y+BqGYws]j(jE%e",
                                                                                                                                                        "fields": {
                                                                                                                                                            "channel_number": "6"
                                                                                                                                                        }
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                            },
                                                                                                                                            "next": {
                                                                                                                                                "block": {
                                                                                                                                                    "type": "procedures_callnoreturn",
                                                                                                                                                    "id": "6aAgBN_I0HA,}Z]5eeDC",
                                                                                                                                                    "extraState": {
                                                                                                                                                        "name": "ConfigureHomeScreenInputFromModbusRegisterMapping",
                                                                                                                                                        "params": [
                                                                                                                                                            "universal_input_number"
                                                                                                                                                        ]
                                                                                                                                                    },
                                                                                                                                                    "inputs": {
                                                                                                                                                        "ARG0": {
                                                                                                                                                            "block": {
                                                                                                                                                                "type": "conn_mod_universal_input_number",
                                                                                                                                                                "id": "aixM)J_zL*y,rxl;[CZ5",
                                                                                                                                                                "fields": {
                                                                                                                                                                    "channel_number": "7"
                                                                                                                                                                }
                                                                                                                                                            }
                                                                                                                                                        }
                                                                                                                                                    },
                                                                                                                                                    "next": {
                                                                                                                                                        "block": {
                                                                                                                                                            "type": "conn_mod_enable_fail_safe",
                                                                                                                                                            "id": "|:9hDng:kQ70#VI0jTcl",
                                                                                                                                                            "inputs": {
                                                                                                                                                                "fail_safe_enable": {
                                                                                                                                                                    "block": {
                                                                                                                                                                        "type": "logic_boolean",
                                                                                                                                                                        "id": "R!7(*c,XbNEe[`TBW}X4",
                                                                                                                                                                        "fields": {
                                                                                                                                                                            "BOOL": "TRUE"
                                                                                                                                                                        }
                                                                                                                                                                    }
                                                                                                                                                                }
                                                                                                                                                            }
                                                                                                                                                        }
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            },
            {
                "type": "sequence_run_continuous",
                "id": "nOhhK:gXx9Q+O8mq51T#",
                "x": -1244,
                "y": 154,
                "inputs": {
                    "run_rate": {
                        "block": {
                            "type": "math_number",
                            "id": ",MP5/E`4XgB}!k*mI3!_",
                            "fields": {
                                "NUM": 5
                            }
                        }
                    },
                    "sequence": {
                        "block": {
                            "type": "procedures_callnoreturn",
                            "id": ",C(6XDw@)g5^Aw}~g!iT",
                            "extraState": {
                                "name": "ConfigureUniversalInputFromModbusRegisterMapping",
                                "params": [
                                    "universal_input_number"
                                ]
                            },
                            "inputs": {
                                "ARG0": {
                                    "block": {
                                        "type": "conn_mod_universal_input_number",
                                        "id": "*Jz@[yqhFTbS(l|-0SQW",
                                        "fields": {
                                            "channel_number": "0"
                                        }
                                    }
                                }
                            },
                            "next": {
                                "block": {
                                    "type": "procedures_callnoreturn",
                                    "id": "7LeeJK7n(ggO)yAOVm-F",
                                    "extraState": {
                                        "name": "ConfigureUniversalInputFromModbusRegisterMapping",
                                        "params": [
                                            "universal_input_number"
                                        ]
                                    },
                                    "inputs": {
                                        "ARG0": {
                                            "block": {
                                                "type": "conn_mod_universal_input_number",
                                                "id": "PYV%AapUoqFYHzD?}Ot-",
                                                "fields": {
                                                    "channel_number": "1"
                                                }
                                            }
                                        }
                                    },
                                    "next": {
                                        "block": {
                                            "type": "procedures_callnoreturn",
                                            "id": "2H7lc_)-Osoa;p7qUL,3",
                                            "extraState": {
                                                "name": "ConfigureUniversalInputFromModbusRegisterMapping",
                                                "params": [
                                                    "universal_input_number"
                                                ]
                                            },
                                            "inputs": {
                                                "ARG0": {
                                                    "block": {
                                                        "type": "conn_mod_universal_input_number",
                                                        "id": "0L!2)EpQb1(;]DwIouZ7",
                                                        "fields": {
                                                            "channel_number": "2"
                                                        }
                                                    }
                                                }
                                            },
                                            "next": {
                                                "block": {
                                                    "type": "procedures_callnoreturn",
                                                    "id": "Xh}M/Leep+r{g!iAJ/6*",
                                                    "extraState": {
                                                        "name": "ConfigureUniversalInputFromModbusRegisterMapping",
                                                        "params": [
                                                            "universal_input_number"
                                                        ]
                                                    },
                                                    "inputs": {
                                                        "ARG0": {
                                                            "block": {
                                                                "type": "conn_mod_universal_input_number",
                                                                "id": "nr)r8m|zI~o%SubQL/Oa",
                                                                "fields": {
                                                                    "channel_number": "3"
                                                                }
                                                            }
                                                        }
                                                    },
                                                    "next": {
                                                        "block": {
                                                            "type": "procedures_callnoreturn",
                                                            "id": "Xc*#Ln)sJ:WF@b}Lf5]p",
                                                            "extraState": {
                                                                "name": "ConfigureUniversalInputFromModbusRegisterMapping",
                                                                "params": [
                                                                    "universal_input_number"
                                                                ]
                                                            },
                                                            "inputs": {
                                                                "ARG0": {
                                                                    "block": {
                                                                        "type": "conn_mod_universal_input_number",
                                                                        "id": "ElW2lJ./q4z;C1/:?3la",
                                                                        "fields": {
                                                                            "channel_number": "4"
                                                                        }
                                                                    }
                                                                }
                                                            },
                                                            "next": {
                                                                "block": {
                                                                    "type": "procedures_callnoreturn",
                                                                    "id": "R+nDQe0bkL]UDq4|2m_I",
                                                                    "extraState": {
                                                                        "name": "ConfigureUniversalInputFromModbusRegisterMapping",
                                                                        "params": [
                                                                            "universal_input_number"
                                                                        ]
                                                                    },
                                                                    "inputs": {
                                                                        "ARG0": {
                                                                            "block": {
                                                                                "type": "conn_mod_universal_input_number",
                                                                                "id": ".l)aL5hN2%d[r-Dd4A%)",
                                                                                "fields": {
                                                                                    "channel_number": "5"
                                                                                }
                                                                            }
                                                                        }
                                                                    },
                                                                    "next": {
                                                                        "block": {
                                                                            "type": "procedures_callnoreturn",
                                                                            "id": "D|!~K,(ie[IUC4$-:W,V",
                                                                            "extraState": {
                                                                                "name": "ConfigureUniversalInputFromModbusRegisterMapping",
                                                                                "params": [
                                                                                    "universal_input_number"
                                                                                ]
                                                                            },
                                                                            "inputs": {
                                                                                "ARG0": {
                                                                                    "block": {
                                                                                        "type": "conn_mod_universal_input_number",
                                                                                        "id": "#q;XMjY.Tygkm^gA1:l:",
                                                                                        "fields": {
                                                                                            "channel_number": "6"
                                                                                        }
                                                                                    }
                                                                                }
                                                                            },
                                                                            "next": {
                                                                                "block": {
                                                                                    "type": "procedures_callnoreturn",
                                                                                    "id": "Gamre@UGy?khJcHn29g:",
                                                                                    "extraState": {
                                                                                        "name": "ConfigureUniversalInputFromModbusRegisterMapping",
                                                                                        "params": [
                                                                                            "universal_input_number"
                                                                                        ]
                                                                                    },
                                                                                    "inputs": {
                                                                                        "ARG0": {
                                                                                            "block": {
                                                                                                "type": "conn_mod_universal_input_number",
                                                                                                "id": "wy@PjJl]t)y5_br::st}",
                                                                                                "fields": {
                                                                                                    "channel_number": "7"
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    },
                                                                                    "next": {
                                                                                        "block": {
                                                                                            "type": "procedures_callnoreturn",
                                                                                            "id": "bnb$c=JSZc:!U|$P#=oR",
                                                                                            "extraState": {
                                                                                                "name": "ConfigureHomeScreenInputFromModbusRegisterMapping",
                                                                                                "params": [
                                                                                                    "universal_input_number"
                                                                                                ]
                                                                                            },
                                                                                            "inputs": {
                                                                                                "ARG0": {
                                                                                                    "block": {
                                                                                                        "type": "conn_mod_universal_input_number",
                                                                                                        "id": "|Czg1YWmzoy.qP-A_/{O",
                                                                                                        "fields": {
                                                                                                            "channel_number": "0"
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            },
                                                                                            "next": {
                                                                                                "block": {
                                                                                                    "type": "procedures_callnoreturn",
                                                                                                    "id": "WX~v6*TNu^=92txO1G~z",
                                                                                                    "extraState": {
                                                                                                        "name": "ConfigureHomeScreenInputFromModbusRegisterMapping",
                                                                                                        "params": [
                                                                                                            "universal_input_number"
                                                                                                        ]
                                                                                                    },
                                                                                                    "inputs": {
                                                                                                        "ARG0": {
                                                                                                            "block": {
                                                                                                                "type": "conn_mod_universal_input_number",
                                                                                                                "id": "ZQj3S530x`oZEa|Mz0Aq",
                                                                                                                "fields": {
                                                                                                                    "channel_number": "1"
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    },
                                                                                                    "next": {
                                                                                                        "block": {
                                                                                                            "type": "procedures_callnoreturn",
                                                                                                            "id": "9;k.4_%!-d2[+%IudI}B",
                                                                                                            "extraState": {
                                                                                                                "name": "ConfigureHomeScreenInputFromModbusRegisterMapping",
                                                                                                                "params": [
                                                                                                                    "universal_input_number"
                                                                                                                ]
                                                                                                            },
                                                                                                            "inputs": {
                                                                                                                "ARG0": {
                                                                                                                    "block": {
                                                                                                                        "type": "conn_mod_universal_input_number",
                                                                                                                        "id": "N^~~fEdbf(f7G5s]9,$i",
                                                                                                                        "fields": {
                                                                                                                            "channel_number": "2"
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            },
                                                                                                            "next": {
                                                                                                                "block": {
                                                                                                                    "type": "procedures_callnoreturn",
                                                                                                                    "id": "5w8F{Ym)58_4C()Mc%UD",
                                                                                                                    "extraState": {
                                                                                                                        "name": "ConfigureHomeScreenInputFromModbusRegisterMapping",
                                                                                                                        "params": [
                                                                                                                            "universal_input_number"
                                                                                                                        ]
                                                                                                                    },
                                                                                                                    "inputs": {
                                                                                                                        "ARG0": {
                                                                                                                            "block": {
                                                                                                                                "type": "conn_mod_universal_input_number",
                                                                                                                                "id": "1%[d![IA$nzh^_?#+si_",
                                                                                                                                "fields": {
                                                                                                                                    "channel_number": "3"
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                    },
                                                                                                                    "next": {
                                                                                                                        "block": {
                                                                                                                            "type": "procedures_callnoreturn",
                                                                                                                            "id": "{0n.QL6k;C?I-eoxCn+m",
                                                                                                                            "extraState": {
                                                                                                                                "name": "ConfigureHomeScreenInputFromModbusRegisterMapping",
                                                                                                                                "params": [
                                                                                                                                    "universal_input_number"
                                                                                                                                ]
                                                                                                                            },
                                                                                                                            "inputs": {
                                                                                                                                "ARG0": {
                                                                                                                                    "block": {
                                                                                                                                        "type": "conn_mod_universal_input_number",
                                                                                                                                        "id": "lCmh^S)?D^7V,5TnzcMS",
                                                                                                                                        "fields": {
                                                                                                                                            "channel_number": "4"
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            },
                                                                                                                            "next": {
                                                                                                                                "block": {
                                                                                                                                    "type": "procedures_callnoreturn",
                                                                                                                                    "id": "Xe/(#G[OJ00`(SDH,tpE",
                                                                                                                                    "extraState": {
                                                                                                                                        "name": "ConfigureHomeScreenInputFromModbusRegisterMapping",
                                                                                                                                        "params": [
                                                                                                                                            "universal_input_number"
                                                                                                                                        ]
                                                                                                                                    },
                                                                                                                                    "inputs": {
                                                                                                                                        "ARG0": {
                                                                                                                                            "block": {
                                                                                                                                                "type": "conn_mod_universal_input_number",
                                                                                                                                                "id": "lF$`+uY~Zy2+;]0v4/O~",
                                                                                                                                                "fields": {
                                                                                                                                                    "channel_number": "5"
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                    },
                                                                                                                                    "next": {
                                                                                                                                        "block": {
                                                                                                                                            "type": "procedures_callnoreturn",
                                                                                                                                            "id": "RC0-{p-tKdiT2Z|:[=5g",
                                                                                                                                            "extraState": {
                                                                                                                                                "name": "ConfigureHomeScreenInputFromModbusRegisterMapping",
                                                                                                                                                "params": [
                                                                                                                                                    "universal_input_number"
                                                                                                                                                ]
                                                                                                                                            },
                                                                                                                                            "inputs": {
                                                                                                                                                "ARG0": {
                                                                                                                                                    "block": {
                                                                                                                                                        "type": "conn_mod_universal_input_number",
                                                                                                                                                        "id": "!X!zT6C=c!3fF}}-m-7v",
                                                                                                                                                        "fields": {
                                                                                                                                                            "channel_number": "6"
                                                                                                                                                        }
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                            },
                                                                                                                                            "next": {
                                                                                                                                                "block": {
                                                                                                                                                    "type": "procedures_callnoreturn",
                                                                                                                                                    "id": "7#{y*H,,3O|@RXU7FHVK",
                                                                                                                                                    "extraState": {
                                                                                                                                                        "name": "ConfigureHomeScreenInputFromModbusRegisterMapping",
                                                                                                                                                        "params": [
                                                                                                                                                            "universal_input_number"
                                                                                                                                                        ]
                                                                                                                                                    },
                                                                                                                                                    "inputs": {
                                                                                                                                                        "ARG0": {
                                                                                                                                                            "block": {
                                                                                                                                                                "type": "conn_mod_universal_input_number",
                                                                                                                                                                "id": "V!f:?=78n;Ktz!F]ssvX",
                                                                                                                                                                "fields": {
                                                                                                                                                                    "channel_number": "7"
                                                                                                                                                                }
                                                                                                                                                            }
                                                                                                                                                        }
                                                                                                                                                    },
                                                                                                                                                    "next": {
                                                                                                                                                        "block": {
                                                                                                                                                            "type": "procedures_callnoreturn",
                                                                                                                                                            "id": "Z(yK[j}F@jn$UM(.va6$",
                                                                                                                                                            "extraState": {
                                                                                                                                                                "name": "PrintUniversalInputMappedValueToLog",
                                                                                                                                                                "params": [
                                                                                                                                                                    "universal_input_number"
                                                                                                                                                                ]
                                                                                                                                                            },
                                                                                                                                                            "inputs": {
                                                                                                                                                                "ARG0": {
                                                                                                                                                                    "block": {
                                                                                                                                                                        "type": "conn_mod_universal_input_number",
                                                                                                                                                                        "id": "RqAuPX6%(w[mlkH%gGX.",
                                                                                                                                                                        "fields": {
                                                                                                                                                                            "channel_number": "0"
                                                                                                                                                                        }
                                                                                                                                                                    }
                                                                                                                                                                }
                                                                                                                                                            },
                                                                                                                                                            "next": {
                                                                                                                                                                "block": {
                                                                                                                                                                    "type": "procedures_callnoreturn",
                                                                                                                                                                    "id": ";1!{|K!1AuV6Pl;g.rF8",
                                                                                                                                                                    "extraState": {
                                                                                                                                                                        "name": "PrintUniversalInputMappedValueToLog",
                                                                                                                                                                        "params": [
                                                                                                                                                                            "universal_input_number"
                                                                                                                                                                        ]
                                                                                                                                                                    },
                                                                                                                                                                    "inputs": {
                                                                                                                                                                        "ARG0": {
                                                                                                                                                                            "block": {
                                                                                                                                                                                "type": "conn_mod_universal_input_number",
                                                                                                                                                                                "id": "@|N(9@efYR,d$`BX0JQH",
                                                                                                                                                                                "fields": {
                                                                                                                                                                                    "channel_number": "1"
                                                                                                                                                                                }
                                                                                                                                                                            }
                                                                                                                                                                        }
                                                                                                                                                                    },
                                                                                                                                                                    "next": {
                                                                                                                                                                        "block": {
                                                                                                                                                                            "type": "procedures_callnoreturn",
                                                                                                                                                                            "id": "ME,%TfSa{Lo0Z5Gs17+#",
                                                                                                                                                                            "extraState": {
                                                                                                                                                                                "name": "PrintUniversalInputMappedValueToLog",
                                                                                                                                                                                "params": [
                                                                                                                                                                                    "universal_input_number"
                                                                                                                                                                                ]
                                                                                                                                                                            },
                                                                                                                                                                            "inputs": {
                                                                                                                                                                                "ARG0": {
                                                                                                                                                                                    "block": {
                                                                                                                                                                                        "type": "conn_mod_universal_input_number",
                                                                                                                                                                                        "id": "#F[hgOQwfMy32i)}{rXr",
                                                                                                                                                                                        "fields": {
                                                                                                                                                                                            "channel_number": "2"
                                                                                                                                                                                        }
                                                                                                                                                                                    }
                                                                                                                                                                                }
                                                                                                                                                                            },
                                                                                                                                                                            "next": {
                                                                                                                                                                                "block": {
                                                                                                                                                                                    "type": "procedures_callnoreturn",
                                                                                                                                                                                    "id": "-*(Zqj+!zgp}cIsQ;h;.",
                                                                                                                                                                                    "extraState": {
                                                                                                                                                                                        "name": "PrintUniversalInputMappedValueToLog",
                                                                                                                                                                                        "params": [
                                                                                                                                                                                            "universal_input_number"
                                                                                                                                                                                        ]
                                                                                                                                                                                    },
                                                                                                                                                                                    "inputs": {
                                                                                                                                                                                        "ARG0": {
                                                                                                                                                                                            "block": {
                                                                                                                                                                                                "type": "conn_mod_universal_input_number",
                                                                                                                                                                                                "id": "|(0p@WiPVQ^Z,9[+(i!v",
                                                                                                                                                                                                "fields": {
                                                                                                                                                                                                    "channel_number": "3"
                                                                                                                                                                                                }
                                                                                                                                                                                            }
                                                                                                                                                                                        }
                                                                                                                                                                                    },
                                                                                                                                                                                    "next": {
                                                                                                                                                                                        "block": {
                                                                                                                                                                                            "type": "procedures_callnoreturn",
                                                                                                                                                                                            "id": "KQ(NcrCHW,m9UsevB=)X",
                                                                                                                                                                                            "extraState": {
                                                                                                                                                                                                "name": "PrintUniversalInputMappedValueToLog",
                                                                                                                                                                                                "params": [
                                                                                                                                                                                                    "universal_input_number"
                                                                                                                                                                                                ]
                                                                                                                                                                                            },
                                                                                                                                                                                            "inputs": {
                                                                                                                                                                                                "ARG0": {
                                                                                                                                                                                                    "block": {
                                                                                                                                                                                                        "type": "conn_mod_universal_input_number",
                                                                                                                                                                                                        "id": "oO+#quu2Jf.Xrjk`a}sc",
                                                                                                                                                                                                        "fields": {
                                                                                                                                                                                                            "channel_number": "4"
                                                                                                                                                                                                        }
                                                                                                                                                                                                    }
                                                                                                                                                                                                }
                                                                                                                                                                                            },
                                                                                                                                                                                            "next": {
                                                                                                                                                                                                "block": {
                                                                                                                                                                                                    "type": "procedures_callnoreturn",
                                                                                                                                                                                                    "id": "*?RK}l#[RTVjVX.|^+L~",
                                                                                                                                                                                                    "extraState": {
                                                                                                                                                                                                        "name": "PrintUniversalInputMappedValueToLog",
                                                                                                                                                                                                        "params": [
                                                                                                                                                                                                            "universal_input_number"
                                                                                                                                                                                                        ]
                                                                                                                                                                                                    },
                                                                                                                                                                                                    "inputs": {
                                                                                                                                                                                                        "ARG0": {
                                                                                                                                                                                                            "block": {
                                                                                                                                                                                                                "type": "conn_mod_universal_input_number",
                                                                                                                                                                                                                "id": "EqKlu/l4iEcs|QQweZJ2",
                                                                                                                                                                                                                "fields": {
                                                                                                                                                                                                                    "channel_number": "5"
                                                                                                                                                                                                                }
                                                                                                                                                                                                            }
                                                                                                                                                                                                        }
                                                                                                                                                                                                    },
                                                                                                                                                                                                    "next": {
                                                                                                                                                                                                        "block": {
                                                                                                                                                                                                            "type": "procedures_callnoreturn",
                                                                                                                                                                                                            "id": "PEq*xG1RLHOLAe--G`~I",
                                                                                                                                                                                                            "extraState": {
                                                                                                                                                                                                                "name": "PrintUniversalInputMappedValueToLog",
                                                                                                                                                                                                                "params": [
                                                                                                                                                                                                                    "universal_input_number"
                                                                                                                                                                                                                ]
                                                                                                                                                                                                            },
                                                                                                                                                                                                            "inputs": {
                                                                                                                                                                                                                "ARG0": {
                                                                                                                                                                                                                    "block": {
                                                                                                                                                                                                                        "type": "conn_mod_universal_input_number",
                                                                                                                                                                                                                        "id": "*;q7;Aj,hMNAn^(S;l}@",
                                                                                                                                                                                                                        "fields": {
                                                                                                                                                                                                                            "channel_number": "6"
                                                                                                                                                                                                                        }
                                                                                                                                                                                                                    }
                                                                                                                                                                                                                }
                                                                                                                                                                                                            },
                                                                                                                                                                                                            "next": {
                                                                                                                                                                                                                "block": {
                                                                                                                                                                                                                    "type": "procedures_callnoreturn",
                                                                                                                                                                                                                    "id": "Z-`IUnSO]kOaX)8!Kljd",
                                                                                                                                                                                                                    "extraState": {
                                                                                                                                                                                                                        "name": "PrintUniversalInputMappedValueToLog",
                                                                                                                                                                                                                        "params": [
                                                                                                                                                                                                                            "universal_input_number"
                                                                                                                                                                                                                        ]
                                                                                                                                                                                                                    },
                                                                                                                                                                                                                    "inputs": {
                                                                                                                                                                                                                        "ARG0": {
                                                                                                                                                                                                                            "block": {
                                                                                                                                                                                                                                "type": "conn_mod_universal_input_number",
                                                                                                                                                                                                                                "id": "Jrd|#UBRKE_`OON=NWcr",
                                                                                                                                                                                                                                "fields": {
                                                                                                                                                                                                                                    "channel_number": "7"
                                                                                                                                                                                                                                }
                                                                                                                                                                                                                            }
                                                                                                                                                                                                                        }
                                                                                                                                                                                                                    },
                                                                                                                                                                                                                    "next": {
                                                                                                                                                                                                                        "block": {
                                                                                                                                                                                                                            "type": "conn_mod_write_universal_input_modbus_register",
                                                                                                                                                                                                                            "id": "YHJc=X]rJy+BNMl58+Vd",
                                                                                                                                                                                                                            "inputs": {
                                                                                                                                                                                                                                "uin_number": {
                                                                                                                                                                                                                                    "block": {
                                                                                                                                                                                                                                        "type": "conn_mod_universal_input_number",
                                                                                                                                                                                                                                        "id": "!rn(en#NXV+m@-ND2}%U",
                                                                                                                                                                                                                                        "fields": {
                                                                                                                                                                                                                                            "channel_number": "0"
                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                "uin_value": {
                                                                                                                                                                                                                                    "block": {
                                                                                                                                                                                                                                        "type": "procedures_callreturn",
                                                                                                                                                                                                                                        "id": "-eH4tdqAN-vriIEvw?-`",
                                                                                                                                                                                                                                        "extraState": {
                                                                                                                                                                                                                                            "name": "GetUniversalInputMappedValueUsingModbusRegisterMapping",
                                                                                                                                                                                                                                            "params": [
                                                                                                                                                                                                                                                "universal_input_number"
                                                                                                                                                                                                                                            ]
                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                        "inputs": {
                                                                                                                                                                                                                                            "ARG0": {
                                                                                                                                                                                                                                                "block": {
                                                                                                                                                                                                                                                    "type": "conn_mod_universal_input_number",
                                                                                                                                                                                                                                                    "id": "y,4TFZ_J%_6qosFOB~Ot",
                                                                                                                                                                                                                                                    "fields": {
                                                                                                                                                                                                                                                        "channel_number": "0"
                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                }
                                                                                                                                                                                                                            },
                                                                                                                                                                                                                            "next": {
                                                                                                                                                                                                                                "block": {
                                                                                                                                                                                                                                    "type": "conn_mod_write_universal_input_modbus_register",
                                                                                                                                                                                                                                    "id": "E^qxr$CsdwoWuju#cxb_",
                                                                                                                                                                                                                                    "inputs": {
                                                                                                                                                                                                                                        "uin_number": {
                                                                                                                                                                                                                                            "block": {
                                                                                                                                                                                                                                                "type": "conn_mod_universal_input_number",
                                                                                                                                                                                                                                                "id": "wP/!.l~`@%np4i2}e;RF",
                                                                                                                                                                                                                                                "fields": {
                                                                                                                                                                                                                                                    "channel_number": "1"
                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                        "uin_value": {
                                                                                                                                                                                                                                            "block": {
                                                                                                                                                                                                                                                "type": "procedures_callreturn",
                                                                                                                                                                                                                                                "id": "TqTUN_UPDu?Tq^WA*{+]",
                                                                                                                                                                                                                                                "extraState": {
                                                                                                                                                                                                                                                    "name": "GetUniversalInputMappedValueUsingModbusRegisterMapping",
                                                                                                                                                                                                                                                    "params": [
                                                                                                                                                                                                                                                        "universal_input_number"
                                                                                                                                                                                                                                                    ]
                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                "inputs": {
                                                                                                                                                                                                                                                    "ARG0": {
                                                                                                                                                                                                                                                        "block": {
                                                                                                                                                                                                                                                            "type": "conn_mod_universal_input_number",
                                                                                                                                                                                                                                                            "id": "13epvgbH?M^$N11Et=Jv",
                                                                                                                                                                                                                                                            "fields": {
                                                                                                                                                                                                                                                                "channel_number": "1"
                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                    "next": {
                                                                                                                                                                                                                                        "block": {
                                                                                                                                                                                                                                            "type": "conn_mod_write_universal_input_modbus_register",
                                                                                                                                                                                                                                            "id": "VaeK_$boO4E{x@$N;[{(",
                                                                                                                                                                                                                                            "inputs": {
                                                                                                                                                                                                                                                "uin_number": {
                                                                                                                                                                                                                                                    "block": {
                                                                                                                                                                                                                                                        "type": "conn_mod_universal_input_number",
                                                                                                                                                                                                                                                        "id": "j+|;.?6|E4HEWz5(u6hb",
                                                                                                                                                                                                                                                        "fields": {
                                                                                                                                                                                                                                                            "channel_number": "2"
                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                "uin_value": {
                                                                                                                                                                                                                                                    "block": {
                                                                                                                                                                                                                                                        "type": "procedures_callreturn",
                                                                                                                                                                                                                                                        "id": "]UN*HqgY;jxSMMda?,oX",
                                                                                                                                                                                                                                                        "extraState": {
                                                                                                                                                                                                                                                            "name": "GetUniversalInputMappedValueUsingModbusRegisterMapping",
                                                                                                                                                                                                                                                            "params": [
                                                                                                                                                                                                                                                                "universal_input_number"
                                                                                                                                                                                                                                                            ]
                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                        "inputs": {
                                                                                                                                                                                                                                                            "ARG0": {
                                                                                                                                                                                                                                                                "block": {
                                                                                                                                                                                                                                                                    "type": "conn_mod_universal_input_number",
                                                                                                                                                                                                                                                                    "id": "2{w#f2x|E3MtqQ%P)7cm",
                                                                                                                                                                                                                                                                    "fields": {
                                                                                                                                                                                                                                                                        "channel_number": "2"
                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                            "next": {
                                                                                                                                                                                                                                                "block": {
                                                                                                                                                                                                                                                    "type": "conn_mod_write_universal_input_modbus_register",
                                                                                                                                                                                                                                                    "id": "=YY$*/OO,;?(P:.IKL|o",
                                                                                                                                                                                                                                                    "inputs": {
                                                                                                                                                                                                                                                        "uin_number": {
                                                                                                                                                                                                                                                            "block": {
                                                                                                                                                                                                                                                                "type": "conn_mod_universal_input_number",
                                                                                                                                                                                                                                                                "id": "|+U_L[VYTwYp=N:8)s!^",
                                                                                                                                                                                                                                                                "fields": {
                                                                                                                                                                                                                                                                    "channel_number": "3"
                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                        "uin_value": {
                                                                                                                                                                                                                                                            "block": {
                                                                                                                                                                                                                                                                "type": "procedures_callreturn",
                                                                                                                                                                                                                                                                "id": "mTO1v)!v5X4J31KJ=P6Q",
                                                                                                                                                                                                                                                                "extraState": {
                                                                                                                                                                                                                                                                    "name": "GetUniversalInputMappedValueUsingModbusRegisterMapping",
                                                                                                                                                                                                                                                                    "params": [
                                                                                                                                                                                                                                                                        "universal_input_number"
                                                                                                                                                                                                                                                                    ]
                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                "inputs": {
                                                                                                                                                                                                                                                                    "ARG0": {
                                                                                                                                                                                                                                                                        "block": {
                                                                                                                                                                                                                                                                            "type": "conn_mod_universal_input_number",
                                                                                                                                                                                                                                                                            "id": "]T8A84Xdg81s,/,/cm!m",
                                                                                                                                                                                                                                                                            "fields": {
                                                                                                                                                                                                                                                                                "channel_number": "3"
                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                    "next": {
                                                                                                                                                                                                                                                        "block": {
                                                                                                                                                                                                                                                            "type": "conn_mod_write_universal_input_modbus_register",
                                                                                                                                                                                                                                                            "id": "R0eRDiDp}L,pZpf^mM*X",
                                                                                                                                                                                                                                                            "inputs": {
                                                                                                                                                                                                                                                                "uin_number": {
                                                                                                                                                                                                                                                                    "block": {
                                                                                                                                                                                                                                                                        "type": "conn_mod_universal_input_number",
                                                                                                                                                                                                                                                                        "id": "TFm8(7*zy*e{s@dk-^`,",
                                                                                                                                                                                                                                                                        "fields": {
                                                                                                                                                                                                                                                                            "channel_number": "4"
                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                "uin_value": {
                                                                                                                                                                                                                                                                    "block": {
                                                                                                                                                                                                                                                                        "type": "procedures_callreturn",
                                                                                                                                                                                                                                                                        "id": "0/@yhnx5N|q6GYG3itko",
                                                                                                                                                                                                                                                                        "extraState": {
                                                                                                                                                                                                                                                                            "name": "GetUniversalInputMappedValueUsingModbusRegisterMapping",
                                                                                                                                                                                                                                                                            "params": [
                                                                                                                                                                                                                                                                                "universal_input_number"
                                                                                                                                                                                                                                                                            ]
                                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                                        "inputs": {
                                                                                                                                                                                                                                                                            "ARG0": {
                                                                                                                                                                                                                                                                                "block": {
                                                                                                                                                                                                                                                                                    "type": "conn_mod_universal_input_number",
                                                                                                                                                                                                                                                                                    "id": "*~P_=UtwaMtROCKA*C.J",
                                                                                                                                                                                                                                                                                    "fields": {
                                                                                                                                                                                                                                                                                        "channel_number": "4"
                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                            "next": {
                                                                                                                                                                                                                                                                "block": {
                                                                                                                                                                                                                                                                    "type": "conn_mod_write_universal_input_modbus_register",
                                                                                                                                                                                                                                                                    "id": "+_S}/0MMBgQC`6NgZ!:|",
                                                                                                                                                                                                                                                                    "inputs": {
                                                                                                                                                                                                                                                                        "uin_number": {
                                                                                                                                                                                                                                                                            "block": {
                                                                                                                                                                                                                                                                                "type": "conn_mod_universal_input_number",
                                                                                                                                                                                                                                                                                "id": ",Q+[1kP~}a-]TS0rOx8A",
                                                                                                                                                                                                                                                                                "fields": {
                                                                                                                                                                                                                                                                                    "channel_number": "5"
                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                                        "uin_value": {
                                                                                                                                                                                                                                                                            "block": {
                                                                                                                                                                                                                                                                                "type": "procedures_callreturn",
                                                                                                                                                                                                                                                                                "id": "@LIMlT/mlfJchiNKGhxl",
                                                                                                                                                                                                                                                                                "extraState": {
                                                                                                                                                                                                                                                                                    "name": "GetUniversalInputMappedValueUsingModbusRegisterMapping",
                                                                                                                                                                                                                                                                                    "params": [
                                                                                                                                                                                                                                                                                        "universal_input_number"
                                                                                                                                                                                                                                                                                    ]
                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                                "inputs": {
                                                                                                                                                                                                                                                                                    "ARG0": {
                                                                                                                                                                                                                                                                                        "block": {
                                                                                                                                                                                                                                                                                            "type": "conn_mod_universal_input_number",
                                                                                                                                                                                                                                                                                            "id": "ZLJ2Ce0G!`1Bxvxqt*oc",
                                                                                                                                                                                                                                                                                            "fields": {
                                                                                                                                                                                                                                                                                                "channel_number": "5"
                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                    "next": {
                                                                                                                                                                                                                                                                        "block": {
                                                                                                                                                                                                                                                                            "type": "conn_mod_write_universal_input_modbus_register",
                                                                                                                                                                                                                                                                            "id": "RdM@J/1~0DX}Z9351)pH",
                                                                                                                                                                                                                                                                            "inputs": {
                                                                                                                                                                                                                                                                                "uin_number": {
                                                                                                                                                                                                                                                                                    "block": {
                                                                                                                                                                                                                                                                                        "type": "conn_mod_universal_input_number",
                                                                                                                                                                                                                                                                                        "id": "^ijMo*cdn-4rBuI0bBOx",
                                                                                                                                                                                                                                                                                        "fields": {
                                                                                                                                                                                                                                                                                            "channel_number": "6"
                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                                "uin_value": {
                                                                                                                                                                                                                                                                                    "block": {
                                                                                                                                                                                                                                                                                        "type": "procedures_callreturn",
                                                                                                                                                                                                                                                                                        "id": "UTh:u;fDY8u^ugWapgP]",
                                                                                                                                                                                                                                                                                        "extraState": {
                                                                                                                                                                                                                                                                                            "name": "GetUniversalInputMappedValueUsingModbusRegisterMapping",
                                                                                                                                                                                                                                                                                            "params": [
                                                                                                                                                                                                                                                                                                "universal_input_number"
                                                                                                                                                                                                                                                                                            ]
                                                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                                                        "inputs": {
                                                                                                                                                                                                                                                                                            "ARG0": {
                                                                                                                                                                                                                                                                                                "block": {
                                                                                                                                                                                                                                                                                                    "type": "conn_mod_universal_input_number",
                                                                                                                                                                                                                                                                                                    "id": "R-(#V`b)Qp=Hi1W32(u3",
                                                                                                                                                                                                                                                                                                    "fields": {
                                                                                                                                                                                                                                                                                                        "channel_number": "6"
                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                            "next": {
                                                                                                                                                                                                                                                                                "block": {
                                                                                                                                                                                                                                                                                    "type": "conn_mod_write_universal_input_modbus_register",
                                                                                                                                                                                                                                                                                    "id": "}s@[)f2!]1f~fZSlHuL`",
                                                                                                                                                                                                                                                                                    "inputs": {
                                                                                                                                                                                                                                                                                        "uin_number": {
                                                                                                                                                                                                                                                                                            "block": {
                                                                                                                                                                                                                                                                                                "type": "conn_mod_universal_input_number",
                                                                                                                                                                                                                                                                                                "id": "@t91Cs2?O4tohKkX_Ks}",
                                                                                                                                                                                                                                                                                                "fields": {
                                                                                                                                                                                                                                                                                                    "channel_number": "7"
                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                                                        "uin_value": {
                                                                                                                                                                                                                                                                                            "block": {
                                                                                                                                                                                                                                                                                                "type": "procedures_callreturn",
                                                                                                                                                                                                                                                                                                "id": "Gl}5e;)Ly:ac$+:Z-M0?",
                                                                                                                                                                                                                                                                                                "extraState": {
                                                                                                                                                                                                                                                                                                    "name": "GetUniversalInputMappedValueUsingModbusRegisterMapping",
                                                                                                                                                                                                                                                                                                    "params": [
                                                                                                                                                                                                                                                                                                        "universal_input_number"
                                                                                                                                                                                                                                                                                                    ]
                                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                                                "inputs": {
                                                                                                                                                                                                                                                                                                    "ARG0": {
                                                                                                                                                                                                                                                                                                        "block": {
                                                                                                                                                                                                                                                                                                            "type": "conn_mod_universal_input_number",
                                                                                                                                                                                                                                                                                                            "id": ".rvtrTI%tI5TkjL8DCy*",
                                                                                                                                                                                                                                                                                                            "fields": {
                                                                                                                                                                                                                                                                                                                "channel_number": "7"
                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                    "next": {
                                                                                                                                                                                                                                                                                        "block": {
                                                                                                                                                                                                                                                                                            "type": "procedures_callnoreturn",
                                                                                                                                                                                                                                                                                            "id": "#4`)}%)qt7L~[zr(FTj*",
                                                                                                                                                                                                                                                                                            "extraState": {
                                                                                                                                                                                                                                                                                                "name": "UpdateHomeScreenInputFromModbusRegisterMapping",
                                                                                                                                                                                                                                                                                                "params": [
                                                                                                                                                                                                                                                                                                    "universal_input_number"
                                                                                                                                                                                                                                                                                                ]
                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                            "inputs": {
                                                                                                                                                                                                                                                                                                "ARG0": {
                                                                                                                                                                                                                                                                                                    "block": {
                                                                                                                                                                                                                                                                                                        "type": "conn_mod_universal_input_number",
                                                                                                                                                                                                                                                                                                        "id": "%w^xCSDU10q}WF}?%Tpr",
                                                                                                                                                                                                                                                                                                        "fields": {
                                                                                                                                                                                                                                                                                                            "channel_number": "0"
                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                            "next": {
                                                                                                                                                                                                                                                                                                "block": {
                                                                                                                                                                                                                                                                                                    "type": "procedures_callnoreturn",
                                                                                                                                                                                                                                                                                                    "id": "J/@z_#Cj#fgXAI#1sua7",
                                                                                                                                                                                                                                                                                                    "extraState": {
                                                                                                                                                                                                                                                                                                        "name": "UpdateHomeScreenInputFromModbusRegisterMapping",
                                                                                                                                                                                                                                                                                                        "params": [
                                                                                                                                                                                                                                                                                                            "universal_input_number"
                                                                                                                                                                                                                                                                                                        ]
                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                    "inputs": {
                                                                                                                                                                                                                                                                                                        "ARG0": {
                                                                                                                                                                                                                                                                                                            "block": {
                                                                                                                                                                                                                                                                                                                "type": "conn_mod_universal_input_number",
                                                                                                                                                                                                                                                                                                                "id": "rrmMzJeG^q_YdkP[98%@",
                                                                                                                                                                                                                                                                                                                "fields": {
                                                                                                                                                                                                                                                                                                                    "channel_number": "1"
                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                    "next": {
                                                                                                                                                                                                                                                                                                        "block": {
                                                                                                                                                                                                                                                                                                            "type": "procedures_callnoreturn",
                                                                                                                                                                                                                                                                                                            "id": "vc|f?(M%E7D5~trG.Tza",
                                                                                                                                                                                                                                                                                                            "extraState": {
                                                                                                                                                                                                                                                                                                                "name": "UpdateHomeScreenInputFromModbusRegisterMapping",
                                                                                                                                                                                                                                                                                                                "params": [
                                                                                                                                                                                                                                                                                                                    "universal_input_number"
                                                                                                                                                                                                                                                                                                                ]
                                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                                            "inputs": {
                                                                                                                                                                                                                                                                                                                "ARG0": {
                                                                                                                                                                                                                                                                                                                    "block": {
                                                                                                                                                                                                                                                                                                                        "type": "conn_mod_universal_input_number",
                                                                                                                                                                                                                                                                                                                        "id": "tlw(NKIA_MOy?Y(#$l+j",
                                                                                                                                                                                                                                                                                                                        "fields": {
                                                                                                                                                                                                                                                                                                                            "channel_number": "2"
                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                                            "next": {
                                                                                                                                                                                                                                                                                                                "block": {
                                                                                                                                                                                                                                                                                                                    "type": "procedures_callnoreturn",
                                                                                                                                                                                                                                                                                                                    "id": "PcM-3]L=$#nMZy1o?k7K",
                                                                                                                                                                                                                                                                                                                    "extraState": {
                                                                                                                                                                                                                                                                                                                        "name": "UpdateHomeScreenInputFromModbusRegisterMapping",
                                                                                                                                                                                                                                                                                                                        "params": [
                                                                                                                                                                                                                                                                                                                            "universal_input_number"
                                                                                                                                                                                                                                                                                                                        ]
                                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                                    "inputs": {
                                                                                                                                                                                                                                                                                                                        "ARG0": {
                                                                                                                                                                                                                                                                                                                            "block": {
                                                                                                                                                                                                                                                                                                                                "type": "conn_mod_universal_input_number",
                                                                                                                                                                                                                                                                                                                                "id": "hQ/]Yt5.2j,fb_]BGcc:",
                                                                                                                                                                                                                                                                                                                                "fields": {
                                                                                                                                                                                                                                                                                                                                    "channel_number": "3"
                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                                    "next": {
                                                                                                                                                                                                                                                                                                                        "block": {
                                                                                                                                                                                                                                                                                                                            "type": "procedures_callnoreturn",
                                                                                                                                                                                                                                                                                                                            "id": "O}V.R(dgxGYZO-{ZDfwy",
                                                                                                                                                                                                                                                                                                                            "extraState": {
                                                                                                                                                                                                                                                                                                                                "name": "UpdateHomeScreenInputFromModbusRegisterMapping",
                                                                                                                                                                                                                                                                                                                                "params": [
                                                                                                                                                                                                                                                                                                                                    "universal_input_number"
                                                                                                                                                                                                                                                                                                                                ]
                                                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                                                            "inputs": {
                                                                                                                                                                                                                                                                                                                                "ARG0": {
                                                                                                                                                                                                                                                                                                                                    "block": {
                                                                                                                                                                                                                                                                                                                                        "type": "conn_mod_universal_input_number",
                                                                                                                                                                                                                                                                                                                                        "id": "/1!`%$?C0!,GpuB$6I1M",
                                                                                                                                                                                                                                                                                                                                        "fields": {
                                                                                                                                                                                                                                                                                                                                            "channel_number": "4"
                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                                                            "next": {
                                                                                                                                                                                                                                                                                                                                "block": {
                                                                                                                                                                                                                                                                                                                                    "type": "procedures_callnoreturn",
                                                                                                                                                                                                                                                                                                                                    "id": "Nu$tSSb==lfI_OzULGSD",
                                                                                                                                                                                                                                                                                                                                    "extraState": {
                                                                                                                                                                                                                                                                                                                                        "name": "UpdateHomeScreenInputFromModbusRegisterMapping",
                                                                                                                                                                                                                                                                                                                                        "params": [
                                                                                                                                                                                                                                                                                                                                            "universal_input_number"
                                                                                                                                                                                                                                                                                                                                        ]
                                                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                                                    "inputs": {
                                                                                                                                                                                                                                                                                                                                        "ARG0": {
                                                                                                                                                                                                                                                                                                                                            "block": {
                                                                                                                                                                                                                                                                                                                                                "type": "conn_mod_universal_input_number",
                                                                                                                                                                                                                                                                                                                                                "id": "%w[kolS6:wyq0(8jEwi/",
                                                                                                                                                                                                                                                                                                                                                "fields": {
                                                                                                                                                                                                                                                                                                                                                    "channel_number": "5"
                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                                                    "next": {
                                                                                                                                                                                                                                                                                                                                        "block": {
                                                                                                                                                                                                                                                                                                                                            "type": "procedures_callnoreturn",
                                                                                                                                                                                                                                                                                                                                            "id": "Upwa?+qT[}+;rD`,,,B;",
                                                                                                                                                                                                                                                                                                                                            "extraState": {
                                                                                                                                                                                                                                                                                                                                                "name": "UpdateHomeScreenInputFromModbusRegisterMapping",
                                                                                                                                                                                                                                                                                                                                                "params": [
                                                                                                                                                                                                                                                                                                                                                    "universal_input_number"
                                                                                                                                                                                                                                                                                                                                                ]
                                                                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                                                                            "inputs": {
                                                                                                                                                                                                                                                                                                                                                "ARG0": {
                                                                                                                                                                                                                                                                                                                                                    "block": {
                                                                                                                                                                                                                                                                                                                                                        "type": "conn_mod_universal_input_number",
                                                                                                                                                                                                                                                                                                                                                        "id": "tkc5Ti[cK8@W^m=~cm3G",
                                                                                                                                                                                                                                                                                                                                                        "fields": {
                                                                                                                                                                                                                                                                                                                                                            "channel_number": "6"
                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                                                                            "next": {
                                                                                                                                                                                                                                                                                                                                                "block": {
                                                                                                                                                                                                                                                                                                                                                    "type": "procedures_callnoreturn",
                                                                                                                                                                                                                                                                                                                                                    "id": "KJotBiKue,sPSv9Ti!?v",
                                                                                                                                                                                                                                                                                                                                                    "extraState": {
                                                                                                                                                                                                                                                                                                                                                        "name": "UpdateHomeScreenInputFromModbusRegisterMapping",
                                                                                                                                                                                                                                                                                                                                                        "params": [
                                                                                                                                                                                                                                                                                                                                                            "universal_input_number"
                                                                                                                                                                                                                                                                                                                                                        ]
                                                                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                                                                    "inputs": {
                                                                                                                                                                                                                                                                                                                                                        "ARG0": {
                                                                                                                                                                                                                                                                                                                                                            "block": {
                                                                                                                                                                                                                                                                                                                                                                "type": "conn_mod_universal_input_number",
                                                                                                                                                                                                                                                                                                                                                                "id": "FF(:!a+rJ3|a~7;HH#iC",
                                                                                                                                                                                                                                                                                                                                                                "fields": {
                                                                                                                                                                                                                                                                                                                                                                    "channel_number": "7"
                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                                                                    "next": {
                                                                                                                                                                                                                                                                                                                                                        "block": {
                                                                                                                                                                                                                                                                                                                                                            "type": "print_string",
                                                                                                                                                                                                                                                                                                                                                            "id": "?QUEq3$}]F8110~NGZ-J",
                                                                                                                                                                                                                                                                                                                                                            "inputs": {
                                                                                                                                                                                                                                                                                                                                                                "string_to_print": {
                                                                                                                                                                                                                                                                                                                                                                    "block": {
                                                                                                                                                                                                                                                                                                                                                                        "type": "text_join",
                                                                                                                                                                                                                                                                                                                                                                        "id": "kVIp#Bsdt(0IDnCKS{)[",
                                                                                                                                                                                                                                                                                                                                                                        "extraState": {
                                                                                                                                                                                                                                                                                                                                                                            "itemCount": 2
                                                                                                                                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                                                                                                                                        "inputs": {
                                                                                                                                                                                                                                                                                                                                                                            "ADD0": {
                                                                                                                                                                                                                                                                                                                                                                                "block": {
                                                                                                                                                                                                                                                                                                                                                                                    "type": "text",
                                                                                                                                                                                                                                                                                                                                                                                    "id": "r@-1.ObN.Tv)/PSdJ-io",
                                                                                                                                                                                                                                                                                                                                                                                    "fields": {
                                                                                                                                                                                                                                                                                                                                                                                        "TEXT": "Relay Out 1 Reg: "
                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                                                                                                            "ADD1": {
                                                                                                                                                                                                                                                                                                                                                                                "block": {
                                                                                                                                                                                                                                                                                                                                                                                    "type": "conn_mod_read_relay_output_modbus_register",
                                                                                                                                                                                                                                                                                                                                                                                    "id": "by2vanSc:qCM!O.%0UTb",
                                                                                                                                                                                                                                                                                                                                                                                    "inputs": {
                                                                                                                                                                                                                                                                                                                                                                                        "relay_number": {
                                                                                                                                                                                                                                                                                                                                                                                            "block": {
                                                                                                                                                                                                                                                                                                                                                                                                "type": "conn_mod_relay_output_number",
                                                                                                                                                                                                                                                                                                                                                                                                "id": "1wdCN!^I^jW~`nj^v@n)",
                                                                                                                                                                                                                                                                                                                                                                                                "fields": {
                                                                                                                                                                                                                                                                                                                                                                                                    "relay_output_number": "0"
                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                                                                                            "next": {
                                                                                                                                                                                                                                                                                                                                                                "block": {
                                                                                                                                                                                                                                                                                                                                                                    "type": "print_string",
                                                                                                                                                                                                                                                                                                                                                                    "id": "-CDDFMr%o_pw8vUF3F)K",
                                                                                                                                                                                                                                                                                                                                                                    "inputs": {
                                                                                                                                                                                                                                                                                                                                                                        "string_to_print": {
                                                                                                                                                                                                                                                                                                                                                                            "block": {
                                                                                                                                                                                                                                                                                                                                                                                "type": "text_join",
                                                                                                                                                                                                                                                                                                                                                                                "id": "Qo2;/miK34)0*:y#;BVp",
                                                                                                                                                                                                                                                                                                                                                                                "extraState": {
                                                                                                                                                                                                                                                                                                                                                                                    "itemCount": 2
                                                                                                                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                                                                                                                                "inputs": {
                                                                                                                                                                                                                                                                                                                                                                                    "ADD0": {
                                                                                                                                                                                                                                                                                                                                                                                        "block": {
                                                                                                                                                                                                                                                                                                                                                                                            "type": "text",
                                                                                                                                                                                                                                                                                                                                                                                            "id": "oz{[KJZTaf/e}vfw*u,m",
                                                                                                                                                                                                                                                                                                                                                                                            "fields": {
                                                                                                                                                                                                                                                                                                                                                                                                "TEXT": "Relay Out 2 Reg: "
                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                                                                                                    "ADD1": {
                                                                                                                                                                                                                                                                                                                                                                                        "block": {
                                                                                                                                                                                                                                                                                                                                                                                            "type": "conn_mod_read_relay_output_modbus_register",
                                                                                                                                                                                                                                                                                                                                                                                            "id": "flDW]-1^eP+KtoR6O,V@",
                                                                                                                                                                                                                                                                                                                                                                                            "inputs": {
                                                                                                                                                                                                                                                                                                                                                                                                "relay_number": {
                                                                                                                                                                                                                                                                                                                                                                                                    "block": {
                                                                                                                                                                                                                                                                                                                                                                                                        "type": "conn_mod_relay_output_number",
                                                                                                                                                                                                                                                                                                                                                                                                        "id": "C#|~fL`A,33b08Ry|%KO",
                                                                                                                                                                                                                                                                                                                                                                                                        "fields": {
                                                                                                                                                                                                                                                                                                                                                                                                            "relay_output_number": "1"
                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                                                                                    "next": {
                                                                                                                                                                                                                                                                                                                                                                        "block": {
                                                                                                                                                                                                                                                                                                                                                                            "type": "print_string",
                                                                                                                                                                                                                                                                                                                                                                            "id": "RR)9Ov+Y=Ge[u/k$M]Hq",
                                                                                                                                                                                                                                                                                                                                                                            "inputs": {
                                                                                                                                                                                                                                                                                                                                                                                "string_to_print": {
                                                                                                                                                                                                                                                                                                                                                                                    "block": {
                                                                                                                                                                                                                                                                                                                                                                                        "type": "text_join",
                                                                                                                                                                                                                                                                                                                                                                                        "id": "vLoL;#MNJ}J]_:!M0MNW",
                                                                                                                                                                                                                                                                                                                                                                                        "extraState": {
                                                                                                                                                                                                                                                                                                                                                                                            "itemCount": 2
                                                                                                                                                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                                                                                                                                                        "inputs": {
                                                                                                                                                                                                                                                                                                                                                                                            "ADD0": {
                                                                                                                                                                                                                                                                                                                                                                                                "block": {
                                                                                                                                                                                                                                                                                                                                                                                                    "type": "text",
                                                                                                                                                                                                                                                                                                                                                                                                    "id": "I5;8/@F8X$jUlYam@EN5",
                                                                                                                                                                                                                                                                                                                                                                                                    "fields": {
                                                                                                                                                                                                                                                                                                                                                                                                        "TEXT": "Relay Out 3 Reg: "
                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                                                                                                                            "ADD1": {
                                                                                                                                                                                                                                                                                                                                                                                                "block": {
                                                                                                                                                                                                                                                                                                                                                                                                    "type": "conn_mod_read_relay_output_modbus_register",
                                                                                                                                                                                                                                                                                                                                                                                                    "id": "oy/h/)Bun5eQ1/r3aQ|E",
                                                                                                                                                                                                                                                                                                                                                                                                    "inputs": {
                                                                                                                                                                                                                                                                                                                                                                                                        "relay_number": {
                                                                                                                                                                                                                                                                                                                                                                                                            "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                "type": "conn_mod_relay_output_number",
                                                                                                                                                                                                                                                                                                                                                                                                                "id": "~-+y7l)naS/[0rduc0Yp",
                                                                                                                                                                                                                                                                                                                                                                                                                "fields": {
                                                                                                                                                                                                                                                                                                                                                                                                                    "relay_output_number": "2"
                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                                                                                                            "next": {
                                                                                                                                                                                                                                                                                                                                                                                "block": {
                                                                                                                                                                                                                                                                                                                                                                                    "type": "print_string",
                                                                                                                                                                                                                                                                                                                                                                                    "id": "`+D5Ww8l]k;_+RseM]YQ",
                                                                                                                                                                                                                                                                                                                                                                                    "inputs": {
                                                                                                                                                                                                                                                                                                                                                                                        "string_to_print": {
                                                                                                                                                                                                                                                                                                                                                                                            "block": {
                                                                                                                                                                                                                                                                                                                                                                                                "type": "text_join",
                                                                                                                                                                                                                                                                                                                                                                                                "id": "*QiOyL-jG`wz?jcpxozQ",
                                                                                                                                                                                                                                                                                                                                                                                                "extraState": {
                                                                                                                                                                                                                                                                                                                                                                                                    "itemCount": 2
                                                                                                                                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                                                                                                                                                "inputs": {
                                                                                                                                                                                                                                                                                                                                                                                                    "ADD0": {
                                                                                                                                                                                                                                                                                                                                                                                                        "block": {
                                                                                                                                                                                                                                                                                                                                                                                                            "type": "text",
                                                                                                                                                                                                                                                                                                                                                                                                            "id": "mttw1:zwVW+-zu]E$^20",
                                                                                                                                                                                                                                                                                                                                                                                                            "fields": {
                                                                                                                                                                                                                                                                                                                                                                                                                "TEXT": "Relay Out 4 Reg: "
                                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                                                                                                                    "ADD1": {
                                                                                                                                                                                                                                                                                                                                                                                                        "block": {
                                                                                                                                                                                                                                                                                                                                                                                                            "type": "conn_mod_read_relay_output_modbus_register",
                                                                                                                                                                                                                                                                                                                                                                                                            "id": "t~RA/ZJ4oCQk{TSk%]h~",
                                                                                                                                                                                                                                                                                                                                                                                                            "inputs": {
                                                                                                                                                                                                                                                                                                                                                                                                                "relay_number": {
                                                                                                                                                                                                                                                                                                                                                                                                                    "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                        "type": "conn_mod_relay_output_number",
                                                                                                                                                                                                                                                                                                                                                                                                                        "id": "ayU,~rC-6a:x/hg#{yhg",
                                                                                                                                                                                                                                                                                                                                                                                                                        "fields": {
                                                                                                                                                                                                                                                                                                                                                                                                                            "relay_output_number": "3"
                                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                                                                                                    "next": {
                                                                                                                                                                                                                                                                                                                                                                                        "block": {
                                                                                                                                                                                                                                                                                                                                                                                            "type": "print_string",
                                                                                                                                                                                                                                                                                                                                                                                            "id": "cZkHf8_L^HSk[z23V(Q;",
                                                                                                                                                                                                                                                                                                                                                                                            "inputs": {
                                                                                                                                                                                                                                                                                                                                                                                                "string_to_print": {
                                                                                                                                                                                                                                                                                                                                                                                                    "block": {
                                                                                                                                                                                                                                                                                                                                                                                                        "type": "text_join",
                                                                                                                                                                                                                                                                                                                                                                                                        "id": "jR;^}pee.Be;RNG*f6/Z",
                                                                                                                                                                                                                                                                                                                                                                                                        "extraState": {
                                                                                                                                                                                                                                                                                                                                                                                                            "itemCount": 2
                                                                                                                                                                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                                                                                                                                                                        "inputs": {
                                                                                                                                                                                                                                                                                                                                                                                                            "ADD0": {
                                                                                                                                                                                                                                                                                                                                                                                                                "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                    "type": "text",
                                                                                                                                                                                                                                                                                                                                                                                                                    "id": "I+CZ%M{]qRHXsrp3$}5q",
                                                                                                                                                                                                                                                                                                                                                                                                                    "fields": {
                                                                                                                                                                                                                                                                                                                                                                                                                        "TEXT": "Relay Out 5 Reg: "
                                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                                                                                                                                            "ADD1": {
                                                                                                                                                                                                                                                                                                                                                                                                                "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                    "type": "conn_mod_read_relay_output_modbus_register",
                                                                                                                                                                                                                                                                                                                                                                                                                    "id": "aMsSs01]=.GE)g5vwh{x",
                                                                                                                                                                                                                                                                                                                                                                                                                    "inputs": {
                                                                                                                                                                                                                                                                                                                                                                                                                        "relay_number": {
                                                                                                                                                                                                                                                                                                                                                                                                                            "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                "type": "conn_mod_relay_output_number",
                                                                                                                                                                                                                                                                                                                                                                                                                                "id": "Us?x#)~,q|myBdwH.E!Q",
                                                                                                                                                                                                                                                                                                                                                                                                                                "fields": {
                                                                                                                                                                                                                                                                                                                                                                                                                                    "relay_output_number": "4"
                                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                                                                                                                            "next": {
                                                                                                                                                                                                                                                                                                                                                                                                "block": {
                                                                                                                                                                                                                                                                                                                                                                                                    "type": "print_string",
                                                                                                                                                                                                                                                                                                                                                                                                    "id": "bDzcw`Gd@,!^#`cx-V1f",
                                                                                                                                                                                                                                                                                                                                                                                                    "inputs": {
                                                                                                                                                                                                                                                                                                                                                                                                        "string_to_print": {
                                                                                                                                                                                                                                                                                                                                                                                                            "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                "type": "text_join",
                                                                                                                                                                                                                                                                                                                                                                                                                "id": ";~wwyHrl[3h]{$(13$UI",
                                                                                                                                                                                                                                                                                                                                                                                                                "extraState": {
                                                                                                                                                                                                                                                                                                                                                                                                                    "itemCount": 2
                                                                                                                                                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                                                                                                                                                                "inputs": {
                                                                                                                                                                                                                                                                                                                                                                                                                    "ADD0": {
                                                                                                                                                                                                                                                                                                                                                                                                                        "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                            "type": "text",
                                                                                                                                                                                                                                                                                                                                                                                                                            "id": "PSB%5p$ltjz]+f[+RoVC",
                                                                                                                                                                                                                                                                                                                                                                                                                            "fields": {
                                                                                                                                                                                                                                                                                                                                                                                                                                "TEXT": "Relay Out 6 Reg: "
                                                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                                                                                                                                    "ADD1": {
                                                                                                                                                                                                                                                                                                                                                                                                                        "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                            "type": "conn_mod_read_relay_output_modbus_register",
                                                                                                                                                                                                                                                                                                                                                                                                                            "id": "F;dqj9f6ZJFcRqg{1j;J",
                                                                                                                                                                                                                                                                                                                                                                                                                            "inputs": {
                                                                                                                                                                                                                                                                                                                                                                                                                                "relay_number": {
                                                                                                                                                                                                                                                                                                                                                                                                                                    "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                        "type": "conn_mod_relay_output_number",
                                                                                                                                                                                                                                                                                                                                                                                                                                        "id": "L8Ps*B_9J[k,_I^8=y[[",
                                                                                                                                                                                                                                                                                                                                                                                                                                        "fields": {
                                                                                                                                                                                                                                                                                                                                                                                                                                            "relay_output_number": "5"
                                                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                                                                                                                    "next": {
                                                                                                                                                                                                                                                                                                                                                                                                        "block": {
                                                                                                                                                                                                                                                                                                                                                                                                            "type": "print_string",
                                                                                                                                                                                                                                                                                                                                                                                                            "id": "w^rRsVS(z:tSOr[YR7ji",
                                                                                                                                                                                                                                                                                                                                                                                                            "inputs": {
                                                                                                                                                                                                                                                                                                                                                                                                                "string_to_print": {
                                                                                                                                                                                                                                                                                                                                                                                                                    "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                        "type": "text_join",
                                                                                                                                                                                                                                                                                                                                                                                                                        "id": "qHGec9$Ad#[fehT/o%),",
                                                                                                                                                                                                                                                                                                                                                                                                                        "extraState": {
                                                                                                                                                                                                                                                                                                                                                                                                                            "itemCount": 2
                                                                                                                                                                                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                                                                                                                                                                                        "inputs": {
                                                                                                                                                                                                                                                                                                                                                                                                                            "ADD0": {
                                                                                                                                                                                                                                                                                                                                                                                                                                "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                    "type": "text",
                                                                                                                                                                                                                                                                                                                                                                                                                                    "id": "S`/xd7j2{AP~VX*@.oCw",
                                                                                                                                                                                                                                                                                                                                                                                                                                    "fields": {
                                                                                                                                                                                                                                                                                                                                                                                                                                        "TEXT": "Relay Out 7 Reg: "
                                                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                                                                                                                                                            "ADD1": {
                                                                                                                                                                                                                                                                                                                                                                                                                                "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                    "type": "conn_mod_read_relay_output_modbus_register",
                                                                                                                                                                                                                                                                                                                                                                                                                                    "id": "/TQ|Eoj1aq)]@V/KhJr+",
                                                                                                                                                                                                                                                                                                                                                                                                                                    "inputs": {
                                                                                                                                                                                                                                                                                                                                                                                                                                        "relay_number": {
                                                                                                                                                                                                                                                                                                                                                                                                                                            "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                "type": "conn_mod_relay_output_number",
                                                                                                                                                                                                                                                                                                                                                                                                                                                "id": "`U#q4-tn!gF+vu0B2LI(",
                                                                                                                                                                                                                                                                                                                                                                                                                                                "fields": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                    "relay_output_number": "6"
                                                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                                                                                                                                            "next": {
                                                                                                                                                                                                                                                                                                                                                                                                                "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                    "type": "print_string",
                                                                                                                                                                                                                                                                                                                                                                                                                    "id": ",RTBWmjvR![QI,Q41,]x",
                                                                                                                                                                                                                                                                                                                                                                                                                    "inputs": {
                                                                                                                                                                                                                                                                                                                                                                                                                        "string_to_print": {
                                                                                                                                                                                                                                                                                                                                                                                                                            "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                "type": "text_join",
                                                                                                                                                                                                                                                                                                                                                                                                                                "id": "3ric@c|A/U0^`6*[Roi[",
                                                                                                                                                                                                                                                                                                                                                                                                                                "extraState": {
                                                                                                                                                                                                                                                                                                                                                                                                                                    "itemCount": 2
                                                                                                                                                                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                                                                                                                                                                                "inputs": {
                                                                                                                                                                                                                                                                                                                                                                                                                                    "ADD0": {
                                                                                                                                                                                                                                                                                                                                                                                                                                        "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                            "type": "text",
                                                                                                                                                                                                                                                                                                                                                                                                                                            "id": "[jT4#c#YKcRH{Q|ycZl~",
                                                                                                                                                                                                                                                                                                                                                                                                                                            "fields": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                "TEXT": "Relay Out 8 Reg: "
                                                                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                                                                                                                                                    "ADD1": {
                                                                                                                                                                                                                                                                                                                                                                                                                                        "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                            "type": "conn_mod_read_relay_output_modbus_register",
                                                                                                                                                                                                                                                                                                                                                                                                                                            "id": "sk4!INFzZIeWt(()a!ZS",
                                                                                                                                                                                                                                                                                                                                                                                                                                            "inputs": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                "relay_number": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                    "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                        "type": "conn_mod_relay_output_number",
                                                                                                                                                                                                                                                                                                                                                                                                                                                        "id": "(1$hgYNBKec3E?Wf$hXz",
                                                                                                                                                                                                                                                                                                                                                                                                                                                        "fields": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                            "relay_output_number": "7"
                                                                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                                                                                                                                    "next": {
                                                                                                                                                                                                                                                                                                                                                                                                                        "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                            "type": "print_string",
                                                                                                                                                                                                                                                                                                                                                                                                                            "id": "A1R}6m]b87YH:?~dB4Rj",
                                                                                                                                                                                                                                                                                                                                                                                                                            "inputs": {
                                                                                                                                                                                                                                                                                                                                                                                                                                "string_to_print": {
                                                                                                                                                                                                                                                                                                                                                                                                                                    "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                        "type": "text_join",
                                                                                                                                                                                                                                                                                                                                                                                                                                        "id": "!io.$Po852TUc}|)`wAN",
                                                                                                                                                                                                                                                                                                                                                                                                                                        "extraState": {
                                                                                                                                                                                                                                                                                                                                                                                                                                            "itemCount": 2
                                                                                                                                                                                                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                                                                                                                                                                                                        "inputs": {
                                                                                                                                                                                                                                                                                                                                                                                                                                            "ADD0": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                    "type": "text",
                                                                                                                                                                                                                                                                                                                                                                                                                                                    "id": "uskWj~^Q,KQ0gOIx4NW*",
                                                                                                                                                                                                                                                                                                                                                                                                                                                    "fields": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                        "TEXT": "Analog Out 1 Reg: "
                                                                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                                                                                                                                                                            "ADD1": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                    "type": "conn_mod_read_analog_output_modbus_register",
                                                                                                                                                                                                                                                                                                                                                                                                                                                    "id": "phxEhEyy[Zhx-d#[(RDt",
                                                                                                                                                                                                                                                                                                                                                                                                                                                    "inputs": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                        "aout_number": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                            "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                "type": "conn_mod_analog_output_number",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                "id": "c/RSlxec,CF#nhmelFE`",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                "fields": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "analog_output_number": "0"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                                                                                                                                                            "next": {
                                                                                                                                                                                                                                                                                                                                                                                                                                "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                    "type": "print_string",
                                                                                                                                                                                                                                                                                                                                                                                                                                    "id": ".D`]dYkn?xI`X/o3-M$r",
                                                                                                                                                                                                                                                                                                                                                                                                                                    "inputs": {
                                                                                                                                                                                                                                                                                                                                                                                                                                        "string_to_print": {
                                                                                                                                                                                                                                                                                                                                                                                                                                            "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                "type": "text_join",
                                                                                                                                                                                                                                                                                                                                                                                                                                                "id": "*xd.N~/Zs?,^m)rJOOF=",
                                                                                                                                                                                                                                                                                                                                                                                                                                                "extraState": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                    "itemCount": 2
                                                                                                                                                                                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                                                                                                                                                                                                "inputs": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                    "ADD0": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                        "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                            "type": "text",
                                                                                                                                                                                                                                                                                                                                                                                                                                                            "id": "hWG0x}0Cz8^4pOl?35eF",
                                                                                                                                                                                                                                                                                                                                                                                                                                                            "fields": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                "TEXT": "Analog Out 2 Reg: "
                                                                                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                                                                                                                                                                    "ADD1": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                        "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                            "type": "conn_mod_read_analog_output_modbus_register",
                                                                                                                                                                                                                                                                                                                                                                                                                                                            "id": "HG2usSP:z!pslY)h)OP3",
                                                                                                                                                                                                                                                                                                                                                                                                                                                            "inputs": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                "aout_number": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "type": "conn_mod_analog_output_number",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "id": ".ttcKZ[_^3ns8RFn}w]J",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "fields": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "analog_output_number": "1"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                                                                                                                                                    "next": {
                                                                                                                                                                                                                                                                                                                                                                                                                                        "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                            "type": "print_string",
                                                                                                                                                                                                                                                                                                                                                                                                                                            "id": "zOrVRz(J~G;](XMRtK4a",
                                                                                                                                                                                                                                                                                                                                                                                                                                            "inputs": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                "string_to_print": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                    "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                        "type": "text_join",
                                                                                                                                                                                                                                                                                                                                                                                                                                                        "id": "@!7AdV^917iuqAfLeKo!",
                                                                                                                                                                                                                                                                                                                                                                                                                                                        "extraState": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                            "itemCount": 2
                                                                                                                                                                                                                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                                                                                                                                                                                                                        "inputs": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                            "ADD0": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "type": "text",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "id": "BY4+86m:2|_K+%I_B=ZD",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "fields": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "TEXT": "Analog Out 3 Reg: "
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                                                                                                                                                                                            "ADD1": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "type": "conn_mod_read_analog_output_modbus_register",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "id": "sNwo@y//|JL#mrBqJUs+",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "inputs": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "aout_number": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "type": "conn_mod_analog_output_number",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "id": "Mm-H5men%KKcVDa,*%9%",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "fields": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "analog_output_number": "2"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                                                                                                                                                                            "next": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                    "type": "print_string",
                                                                                                                                                                                                                                                                                                                                                                                                                                                    "id": "V.5K[yZ[-/WB4xTPJGCj",
                                                                                                                                                                                                                                                                                                                                                                                                                                                    "inputs": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                        "string_to_print": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                            "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                "type": "text_join",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                "id": "qT;c7D?nFWIUFhLflt(/",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                "extraState": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "itemCount": 2
                                                                                                                                                                                                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                "inputs": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "ADD0": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "type": "text",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "id": ",y9Dm]O(`4xv~F/39*zf",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "fields": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "TEXT": "Analog Out 4 Reg: "
                                                                                                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "ADD1": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "type": "conn_mod_read_analog_output_modbus_register",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "id": "/a(=h1!VthkrXvZ~=H!3",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "inputs": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "aout_number": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "type": "conn_mod_analog_output_number",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "id": "7c?6I},T3#,K)n;i|exF",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "fields": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "analog_output_number": "3"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                                                                                                                                                                    "next": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                        "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                            "type": "conn_mod_set_relay_output",
                                                                                                                                                                                                                                                                                                                                                                                                                                                            "id": "$.QU2Q14|)-c,?16jFp@",
                                                                                                                                                                                                                                                                                                                                                                                                                                                            "inputs": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                "relay_number": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "type": "conn_mod_relay_output_number",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "id": "sTa_J(^!3Ka!n)fzopGL",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "fields": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "relay_output_number": "0"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                "relay_state": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "type": "conn_mod_read_relay_output_modbus_register",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "id": "?o*s]L+lt0IAPf84Rnpx",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "inputs": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "relay_number": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "type": "conn_mod_relay_output_number",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "id": "JBX)T2rYX(^0Fc)EvRb=",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "fields": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "relay_output_number": "0"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                                                                                                                                                                                            "next": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "type": "conn_mod_set_relay_output",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "id": "[Z4xTlo`Y_[-3A[mQE]c",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "inputs": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "relay_number": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "type": "conn_mod_relay_output_number",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "id": "h$`011=,09M?asSF(0GM",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "fields": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "relay_output_number": "1"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "relay_state": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "type": "conn_mod_read_relay_output_modbus_register",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "id": "))jjqG{n9@e-^s!)dL(C",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "inputs": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "relay_number": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "type": "conn_mod_relay_output_number",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "id": "q#%*JJZ-=a~Ze8*Bm{eg",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "fields": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "relay_output_number": "1"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "next": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "type": "conn_mod_set_relay_output",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "id": "kp0R#N$LpmjGHbv6xr0p",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "inputs": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "relay_number": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "type": "conn_mod_relay_output_number",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "id": "%Tm_Vx,i-SRV[[O:D5tl",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "fields": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "relay_output_number": "2"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "relay_state": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "type": "conn_mod_read_relay_output_modbus_register",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "id": "-Qg^Zn{Wc^UVTs5[y]Mf",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "inputs": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "relay_number": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "type": "conn_mod_relay_output_number",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "id": "3wWLUGs9lqeOuB76ts^[",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "fields": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "relay_output_number": "2"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "next": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "type": "conn_mod_set_relay_output",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "id": "RkT7*iL.|F#einK^Jv@B",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "inputs": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "relay_number": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "type": "conn_mod_relay_output_number",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "id": "?txyIJlE{jb+k.c!hfZ6",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "fields": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "relay_output_number": "3"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "relay_state": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "type": "conn_mod_read_relay_output_modbus_register",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "id": "Srs7.`20J(7K/QeU)zz%",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "inputs": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "relay_number": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "type": "conn_mod_relay_output_number",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "id": "m(jP7h={Ol}#M_OrNk$n",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "fields": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "relay_output_number": "3"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "next": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "type": "conn_mod_set_relay_output",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "id": "WIykW_L~I}7=H7rvDH|I",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "inputs": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "relay_number": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "type": "conn_mod_relay_output_number",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "id": "+~D6iW(LdUw9O.yomRGg",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "fields": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "relay_output_number": "4"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "relay_state": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "type": "conn_mod_read_relay_output_modbus_register",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "id": "{6Ctbp2[53b8}n*LfaLn",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "inputs": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "relay_number": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "type": "conn_mod_relay_output_number",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "id": "|@)386~vZ6C7cvNDD~;(",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "fields": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "relay_output_number": "4"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "next": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "type": "conn_mod_set_relay_output",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "id": "1N?SggHrkdLH}hT(E]kU",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "inputs": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "relay_number": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "type": "conn_mod_relay_output_number",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "id": "1+(8`|3rU_C6^(4UCS]v",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "fields": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "relay_output_number": "5"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "relay_state": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "type": "conn_mod_read_relay_output_modbus_register",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "id": "|ibfjU:+vV*dvyMebOqI",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "inputs": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "relay_number": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "type": "conn_mod_relay_output_number",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "id": "viHvDkKX@%#t5TipBD##",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "fields": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "relay_output_number": "5"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "next": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "type": "conn_mod_set_relay_output",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "id": "idihpnPT`86]jW!0+v|a",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "inputs": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "relay_number": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "type": "conn_mod_relay_output_number",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "id": "052s$bo^Zlbk%{?jIRZg",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "fields": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "relay_output_number": "6"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "relay_state": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "type": "conn_mod_read_relay_output_modbus_register",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "id": "v|@-N6k`TyDwkM!$XaW%",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "inputs": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "relay_number": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "type": "conn_mod_relay_output_number",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "id": "/K)0#IVQU=F*Nhvd7a)R",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "fields": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "relay_output_number": "6"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "next": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "type": "conn_mod_set_relay_output",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "id": ";7}ZcZ+yzyF70HPzGW==",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "inputs": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "relay_number": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "type": "conn_mod_relay_output_number",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "id": "MuB,J4^}feGPQsF~gg!:",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "fields": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "relay_output_number": "7"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "relay_state": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "type": "conn_mod_read_relay_output_modbus_register",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "id": "%-dPF6$@MOUOJhSps*5j",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "inputs": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "relay_number": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "type": "conn_mod_relay_output_number",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "id": "nhS].KrahKet?yP:pLl_",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "fields": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "relay_output_number": "7"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "next": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "type": "conn_mod_set_analog_output",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "id": "3QPo[ACK?cuQD%,rP]Wd",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "inputs": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "aout_number": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "type": "conn_mod_analog_output_number",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "id": "Lkk9,*^r4{RN0OmR?-Fa",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "fields": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "analog_output_number": "0"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "aout_value": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "type": "conn_mod_read_analog_output_modbus_register",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "id": "u4@=L_dDw~fuXra0Ev?g",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "inputs": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "aout_number": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "type": "conn_mod_analog_output_number",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "id": "^UBY8ye+4C#SmjPq9+a;",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "fields": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "analog_output_number": "0"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "next": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "type": "conn_mod_set_analog_output",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "id": "@(m~:?c15g6%3OVhujTR",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "inputs": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "aout_number": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "type": "conn_mod_analog_output_number",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "id": ")CJRp/3:pCDI~3BeDqOq",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "fields": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "analog_output_number": "1"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "aout_value": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "type": "conn_mod_read_analog_output_modbus_register",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "id": "jZS4e!0O2d^1|HQ^6K|b",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "inputs": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "aout_number": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "type": "conn_mod_analog_output_number",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "id": "V)n_ODFsm6C#a?Ri=7vz",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "fields": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "analog_output_number": "1"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "next": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "type": "conn_mod_set_analog_output",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "id": "Ib?2oJ3#(/)%;if(I`[c",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "inputs": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "aout_number": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "type": "conn_mod_analog_output_number",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "id": "./:hW=|f_mNCfAh-HWR=",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "fields": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "analog_output_number": "2"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "aout_value": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "type": "conn_mod_read_analog_output_modbus_register",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "id": "=lwpck_j=gRodrV#jsMq",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "inputs": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "aout_number": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "type": "conn_mod_analog_output_number",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "id": "aa?Wi`Bmbb/D.eg+[l%a",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "fields": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "analog_output_number": "2"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "next": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "type": "conn_mod_set_analog_output",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "id": "^`CROFL]$]v%2`]oCr;L",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "inputs": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "aout_number": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "type": "conn_mod_analog_output_number",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "id": "SwsH5U6*(FXDJUwo1uke",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "fields": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "analog_output_number": "3"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "aout_value": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "type": "conn_mod_read_analog_output_modbus_register",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "id": "?W?_/-,9e5f6XJ:7Mqcr",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "inputs": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "aout_number": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        "block": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "type": "conn_mod_analog_output_number",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "id": "m2(7Ik%U?a,S%7)-+37?",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            "fields": {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                "analog_output_number": "3"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                }
                                                                                                                                                                                                                            }
                                                                                                                                                                                                                        }
                                                                                                                                                                                                                    }
                                                                                                                                                                                                                }
                                                                                                                                                                                                            }
                                                                                                                                                                                                        }
                                                                                                                                                                                                    }
                                                                                                                                                                                                }
                                                                                                                                                                                            }
                                                                                                                                                                                        }
                                                                                                                                                                                    }
                                                                                                                                                                                }
                                                                                                                                                                            }
                                                                                                                                                                        }
                                                                                                                                                                    }
                                                                                                                                                                }
                                                                                                                                                            }
                                                                                                                                                        }
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        ]
    },
    "variables": [
        {
            "name": "universal_input_number",
            "id": "5F`WY$BZJ;/mtIUiYew!"
        },
        {
            "name": "x",
            "id": "yZ1r^^#%g`IdJLGS5++W"
        }
    ]
}