export enum BlockType {
  SEQUENCE = 'marker',
  SEQUENCER = 'sequencer',
  SEQUENCE_SYSTEM = 'sequence_system',
  SEQUENCE_DEVICE = 'sequence_device',
}

export enum Frequency {
  DAILY = 'DAILY',
  EVERY_15_MINUTES = 'EVERY_15_MINUTES',
  EVERY_5_MINUTES = 'EVERY_5_MINUTES',
  EVERY_HOUR = 'EVERY_HOUR',
  EVERY_MINUTE = 'EVERY_MINUTE',
  HOURLY = 'HOURLY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY'
}

export enum SequenceStatus {
  ENABLE = 'ENABLE',
  DISABLE = 'DISABLE',
  DISABLE_WITH_LOGS = 'DISABLE_WITH_LOGS',
  ENABLE_WITH_LOGS = 'ENABLE_WITH_LOGS',
  DRY_RUN = 'DRY_RUN'
}

export enum BlockLogo {
  IMAGE = 'assets/images/75f-block.png',
  WIDTH = 18,
  HEIGHT = 18,
  ALT_TEXT = '75F block'
}

export enum DeliveryLogsImage {
  IMAGE = 'assets/images/info-icon.png',
  WIDTH = 18,
  HEIGHT = 18,
  ALT_TEXT = 'Delivery Logs button'
}

export enum BuilderMode {
  SEQUENCE_BUILDER = 'sequence_builder',
  LIBRARY_BUILDER = 'library_builder'
}

export enum DefaultTabName {
  SITE_SEQUENCES = 'Site Sequences',
  LIBRARY_BUILDER = 'New Sequence',
}

export enum CustomCategory {
  PREDEFINED = 'Custom Primitives',
  PREDEFINED_DEVICE = 'Conn Mod',
  FUNCTIONS = 'Functions'
}

export enum scope {
  DEFAULT_SCOPE = 'DEFAULT_SCOPE',
  CUSTOM_SCOPE = 'CUSTOM_SCOPE'
}

export enum Category {
  CLOUD = 'CLOUD',
  SYSTEM = 'CCU',
  DEVICE = 'DEVICE'
}

export enum DefaultCategoryTabName {
  CLOUD = 'Site Sequences',
  CCU = 'System Sequences',
  DEVICE = 'Device Sequences'
}

export enum componentType {
  CLOUD = 'SITE_SEQUENCER',
  SYSTEM = 'SITE_SEQUENCER_CCU',
  DEVICE = 'SITE_SEQUENCER_DEVICE'
}

export enum DeviceRunBlockType {
  RUN_BLOCK_STARTUP = 'sequence_run_startup',
  RUN_BLOCK_CONTINUOUS = 'sequence_run_continuous'
}