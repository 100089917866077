import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from '../../services/configuration.service';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class SiteService {
  url: string;
  caretakerUrl = '';
  constructor(private http: HttpClient,
    private configService: ConfigurationService
    ) { 
      this.url = this.configService.getConfig('haystackUrl');
    }

  getSites(): Observable<any> {
    const data = `ver:"2.0"\nfilter\n"site"`;
    return this.http.post(`${this.url}v1/read/`, data).pipe(catchError(this.handleError));
  }

  /**
 * Get the logged in user object
 * @returns Logged in user info
 */
 
  getUser() {
    const url = `${this.configService.getConfig('auth').caretakerUrl}/token/userinfo`;
    return this.http.get(url).pipe(catchError(this.handleError));;
  }

  private handleError(error: any): Promise<any> {
    return Promise.reject(error);
  }

}
