<div class="table-wrapper">
    <div class="main-header" *ngIf="originalTableData.length && !hideTableHeader">
        <div class="search-container scope-list-search">
            <mat-form-field class="search-input">
                <input matInput placeholder="Search" #searchTextField (keyup)="search(searchTextField?.value)"
                    autocomplete="off" />
                <i class="fas fa-search" matPrefix aria-hidden="true"></i>
            </mat-form-field>
        </div>
        <div class="header-icons-container">
            <app-error-type-legends></app-error-type-legends>
            <div class="close-button-container" *ngIf="showCloseButton">
                <button mat-icon-button mat-dialog-close class="close-button">
                    <i class="fas fa-times"></i>
                </button>
            </div>
        </div>
    </div>

    <div class="table-container">
        <table mat-table [dataSource]="dataSource" [fixedLayout]="true" matSort style="width: 100%; min-height: 100%">
            <!-- columns -->
            <ng-container *ngFor="let column of headerColumns" [matColumnDef]="column" sticky>
                <th mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]=" (column === 'type' || column === 'result') ? 'width-5' : (column === 'message' ? 'width-30' : 'width-20')">{{ column }}</th>
                <td mat-cell *matCellDef="let row" [ngClass]=" (column === 'type' || column === 'result') ? 'width-5' : (column === 'message' ? 'width-30' : 'width-20')">
                    <p class="data-value icon-cell" *ngIf="column === 'type'">
                        <i class="fas fa-info log-level-indicator" [ngClass]="{
                              'error' : row[column] === 'ERROR', 
                              'info': (row[column] === 'INFO' || row[column] === 'DEBUG'),
                              'trace': row[column] === 'TRACE',
                              'warn': row[column] === 'WARN'
                            }" matTooltip="" matTooltipPosition='above' showTooltipOnOverflow></i>
                    </p>
                    <p class="data-value icon-cell" *ngIf="column === 'result'">
                        <i class="fas fa-cog result-toggle-button" *ngIf="row[column] && row.isResultJson"
                            (click)="showResult(row[column])"></i>
                        <span class="row-result" *ngIf="row[column] !== undefined && !(row.isResultJson)"  matTooltip="" matTooltipPosition='above' showTooltipOnOverflow>{{ row[column] }}</span>
                    </p>
                    <p class="data-value expiresAt" *ngIf="column === 'timestamp' || column === 'expiresAt'"
                        matTooltip="" matTooltipPosition='above' showTooltipOnOverflow>
                        {{ row[column] | date: 'MMMM d, y, h:mm a, zzzz' }}
                    </p>
                    <p class="data-value" [ngClass]="{'message': column === 'message'}"
                        *ngIf="(column !== 'type' && column !== 'result' && column !== 'timestamp' && column !== 'expiresAt')"
                        matTooltip="" matTooltipPosition='above' showTooltipOnOverflow>
                        {{ row[column] }}
                    </p>
                </td>
            </ng-container>

            <!-- Header and Row definitions -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <div class="placeholder-text" *ngIf="originalTableData.length <= 0">
            <div class="message-text">No logs found for the sequence</div>
        </div>
    </div>
</div>