import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SiteSequencerComponent } from './components/site-sequencer/site-sequencer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from './shared/shared.module';
import { ConfigurationService } from './services/configuration.service';
import { HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { JwtInterceptor } from './services/jwt.interceptor.service';
import { HttpCancelInterceptor } from './services/cancel.interceptor.service';
import { FormsModule } from '@angular/forms';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';

const appInitializerFn = (appConfig: ConfigurationService) => {
  return () => {
      return appConfig.setConfig(environment.config);
  };
};

@NgModule({
  declarations: [
    AppComponent,
    SiteSequencerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule,
    FormsModule,
    CodemirrorModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      deps: [ConfigurationService],
      multi: true
    },
    { provide: 'LOCALSTORAGE', useFactory: getLocalStorage },
    { provide: 'WINDOW', useFactory: getWindow },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpCancelInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function getLocalStorage() {
  return (typeof window !== "undefined") ? window.sessionStorage : null;
}

export function getWindow(): any {
  return (typeof window !== "undefined") ? window : {};
}