import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  SimpleChange,
  Output,
  EventEmitter,
} from '@angular/core';



@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent {
  @Input() blockTitle!: string;
  @Input() isOpen!: boolean;
  @Input() titleStyle?: any;
  @Input() isActive: boolean = false;
  @Input() showActive: boolean = false;
  @Input() showButton: boolean = false;
  @Input() fromBulkEditPopup: boolean = false;
  @Input() showSelectBox: boolean = false;
  @Input() selectCheckbox: boolean = false;
  @Input() remoteAccess: boolean = false
  @Input() disableAccess: boolean = false
  @Output() AddNew: any = new EventEmitter()
  @Output() accordianState: any = new EventEmitter()
  @Output() startRemoteAccess: any = new EventEmitter()
  @Output() stopRemoteAccess: any = new EventEmitter()
  @Output() onSelectCheckBoxChange: any = new EventEmitter();

  selectAllText = 'Select All';
  sessionButton: boolean = true
  remoteEvent: boolean = false;
  remoteOption = {
    startSession: 'Start Remote Session',
    stopSession: 'Stop Remote Session',
  };

  ngOnChanges(changes: SimpleChanges) {
    const isOpen: SimpleChange = changes['isOpen'];
    const isActive: SimpleChange = changes['isActive'];
    if (isOpen) {
      this.isOpen = (isOpen.currentValue) ? isOpen.currentValue : false;
    }
    if (isActive) {
      this.isActive = (isActive.currentValue) ? isActive.currentValue : false;
    }
    if (changes['selectCheckbox'] && changes['selectCheckbox'].currentValue) {
      this.selectCheckbox = changes['selectCheckbox'].currentValue;
    }
  }

  activateDropdown() {
    if (!this.remoteEvent) {
      this.blockTitle.includes('Default System Profile') ? this.isOpen = false : this.isOpen = !this.isOpen;
      this.accordianState.emit(this.isOpen)
    }
  }


  addFilter() {
    this.AddNew.emit(false);
  }

  onSelectChange(event: any) {
    this.onSelectCheckBoxChange.emit(event.checked);
  }

  onRmtClick(event: any) {
    this.sessionButton = event ? false : true;
    !this.sessionButton ? this.startRemoteAccess.emit(this.sessionButton) : this.stopRemoteAccess.emit(this.sessionButton);
    this.remoteEvent = true;
  }

}
