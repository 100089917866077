<ng-container>
    <div class="header" *ngIf="showHeader">
        <div class="header-content-container">
            <div class="header-main-content">
                <div class="left-container">
                    <h1 mat-dialog-title class="text-capitalize sg-modal-title" [ngClass]="subTitle ? 'm-b-10' : 'm-b-2'" *ngIf="title">{{ title }}</h1>
                    <div class="search-container scope-list-search">
                        <mat-form-field class="search-input">
                            <input matInput placeholder="Search" #searchTextField (keyup)="applyFilter(searchTextField?.value)"
                                autocomplete="off" />
                            <i class="fas fa-search" matPrefix aria-hidden="true"></i>
                        </mat-form-field>
                    </div>
                </div>
                <div class="spacer"></div>
                <div class="close-button-container" *ngIf="type == 'json_viewer'">
                  <button mat-icon-button mat-dialog-close class="close-button">
                    <i class="fas fa-times"></i>
                  </button>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<div mat-dialog-content [ngClass]="{'no-header':!showHeader}">
    <div class="row align-items-center">
        <div class="p-l-0 pre-wrap">
            <div class="scrollable-content" *ngIf="type === 'json_viewer'">
                <ngx-json-viewer [json]="filteredJsonContent ? filteredJsonContent : htmlContent"></ngx-json-viewer>    
            </div>
            <div class="scrollable-content sequence-logs-table-container" [ngClass]="{'isFixedHeight': isFixedHeight}">
                <app-site-sequencer-table [table]="htmlContent.sequenceLogstableData" [hideTableHeader]="false"
                    [headerColumns]="htmlContent.sequenceLogsHeaderColumns" [showCloseButton]="true" *ngIf="type === 'sequence_logs'">
                </app-site-sequencer-table>

                <app-delivery-logs-table [table]="htmlContent.sequenceLogstableData" [showCloseButton]="true"
                    [headerColumns]="htmlContent.sequenceLogsHeaderColumns" *ngIf="type === 'delivery_logs'">
                </app-delivery-logs-table>
            </div>
        </div>
    </div>
</div>