import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-sequence-validation-popup',
  templateUrl: './sequence-validation-popup.component.html',
  styleUrls: ['./sequence-validation-popup.component.scss']
})
export class SequenceValidationPopupComponent {
  constructor(
    public dialogRef: MatDialogRef<SequenceValidationPopupComponent>
  ) { }
  htmlContent: any;
  title: any;
  subTitle: any;
  type: 'confirm' | 'dialog' | 'json_viewer' | 'table_viewer' | 'formPendingConfirm' | 'forceTrim' = 'confirm';
  confirmBtnText = 'Confirm';
  showConfirmIcon = false;
  isFixedHeight!: boolean;

  onNoClick(): void {
    this.dialogRef.close();
  }

}
